import { render, staticRenderFns } from "./ActivityTransferRights.vue?vue&type=template&id=836d052c&scoped=true"
import script from "./ActivityTransferRights.vue?vue&type=script&lang=js"
export * from "./ActivityTransferRights.vue?vue&type=script&lang=js"
import style0 from "./ActivityTransferRights.vue?vue&type=style&index=0&id=836d052c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "836d052c",
  null
  
)

export default component.exports
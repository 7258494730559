<template>
    <b-col cols="12" md="4" xl="3" v-if="!isMobile">
        <div class="card">
            <router-link
                class="door-image-link"
                :to="{
                    name: 'door',
                    params: {
                        locationId: device.Device.LocationId,
                        doorId: device.Device.Id,
                        companyId: device.Device.CompanyId,
                    },
                }"
            >
                <img
                    :src="
                        device.Device.MediumImageUrl
                            ? device.Device.MediumImageUrl
                            : '/images/zesec-placeholder.png'
                    "
                    class="card-img-top"
                />
                <!-- <img
                    class="provider"
                    :src="
                        '/images/door_provider_' +
                        device.Device.ProviderType +
                        '.png'
                    "
                /> -->
            </router-link>
            <div class="card-body">
                <div class="row align-items-center">
                    <div class="col">
                        <!-- Title -->
                        <h4 class="mb-2 name text-truncate">
                            <router-link
                                :to="{
                                    name: 'door',
                                    params: {
                                        locationId: device.Device.LocationId,
                                        doorId: device.Device.Id,
                                        companyId: device.Device.CompanyId,
                                    },
                                }"
                                >{{ deviceName(device.Device.Name) }}</router-link
                            >
                        </h4>

                        <!-- Subtitle -->
                        <p class="card-text small text-muted">
                            {{
                                getDeviceProvider(device.Device.ProviderType)
                                    .key
                            }}
                        </p>
                        <!-- <p>
                            {{
                                $t("common.count_keys", {
                                    count: device.Keys
                                        ? device.Keys.length
                                        : device.KeysCount,
                                })
                            }}
                        </p>  -->
                    </div>
                    <div class="col-auto">
                        <!-- Dropdown -->
                        <div class="row align-items-center no-gutters">
                            <b-dropdown
                            variant="outline"
                            toggle-class="dropdown-ellipses dropdown-toggle"
                            no-caret
                            right
                            toggle-tag="a"
                            >
                            <template v-slot:button-content>
                                <img :src="'/images/settings_grey_32x32dp.png'" style="width: 19px; height: 19px;"/>
                            </template>
                            <b-dropdown-item
                                class="text-left text-truncate"
                                @click="editDevice(device)"
                                >{{ $t("buttons.edit") }}</b-dropdown-item
                            >
                            <b-dropdown-item
                                class="text-left text-truncate"
                                @click="deleteDevice(device)"
                                >{{ $t("buttons.delete") }}</b-dropdown-item
                            >
                            <b-dropdown-item
                                class="text-left text-truncate"
                                @click="deviceImage(device)"
                                >{{ $t("doors.image") }}</b-dropdown-item
                            >
                            <b-dropdown-item
                                class="text-left text-truncate"
                                @click="deviceQR(device)"
                                v-if="device.Device.ProviderType == 3 || device.Device.ProviderType == 5 || device.Device.ProviderType == 6"
                            >{{$t("doors.generate_qr")}}</b-dropdown-item>
                        </b-dropdown>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer card-footer-boxed">
                <div class="row align-items-center">
                    <div class="col">
                        <div class="row align-items-center no-gutters">
                            <p>
                            {{
                                $t("common.count_keys", {
                                    count: device.Keys
                                        ? device.Keys.length
                                        : device.KeysCount,
                                })
                            }}
                        </p>
                        </div>
                    </div>

                </div>
                <!-- / .row -->
            </div>
        </div>
        <!-- <zs-device-delete-modal :locationId="device.Device.LocationId"/> -->
       <!--  <zs-image-upload-modal @imageUploaded="updateDoorImage" /> -->
    </b-col>

    <b-col cols="12" md="4" xl="3" v-else>
        <div class="card">
            <router-link
                class="door-image-link"
                :to="{
                    name: 'door',
                    params: {
                        locationId: device.Device.LocationId,
                        doorId: device.Device.Id,
                        companyId: device.Device.CompanyId,
                    },
                }"
            >
                <img
                    :src="
                        device.Device.MediumImageUrl
                            ? device.Device.MediumImageUrl
                            : '/images/zesec-placeholder.png'
                    "
                    class="card-img-top"
                />
                <!-- <img
                    class="provider"
                    :src="
                        '/images/door_provider_' +
                        device.Device.ProviderType +
                        '.png'
                    "
                /> -->
            </router-link>
            <div class="card-body">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <!-- Title -->
                        <h4 class="mb-2 name text-truncate">
                            <router-link
                                :to="{
                                    name: 'door',
                                    params: {
                                        locationId: device.Device.LocationId,
                                        doorId: device.Device.Id,
                                        companyId: device.Device.CompanyId,
                                    },
                                }"
                                >{{ deviceName(device.Device.Name) }}</router-link
                            >
                        </h4>

                        <!-- Subtitle -->
                        <p class="card-text small text-muted">
                            {{
                                getDeviceProvider(device.Device.ProviderType)
                                    .key
                            }}
                        </p>
                        <!-- <p>
                            {{
                                $t("common.count_keys", {
                                    count: device.Keys
                                        ? device.Keys.length
                                        : device.KeysCount,
                                })
                            }}
                        </p>  -->
                    </div>
                    <div class="col-auto">
                        <!-- Dropdown -->
                        <div class="row align-items-center no-gutters">
                            <b-dropdown
                            variant="outline"
                            toggle-class="dropdown-ellipses dropdown-toggle"
                            no-caret
                            right
                            toggle-tag="a"
                            >
                            <template v-slot:button-content>
                                <img :src="'/images/settings_grey_32x32dp.png'" style="width: 19px; height: 19px;"/>
                            </template>
                            <b-dropdown-item
                                class="text-left text-truncate"
                                @click="editDevice(device)"
                                >{{ $t("buttons.edit") }}</b-dropdown-item
                            >
                            <b-dropdown-item
                                class="text-left text-truncate"
                                @click="deleteDevice(device)"
                                >{{ $t("buttons.delete") }}</b-dropdown-item
                            >
                            <b-dropdown-item
                                class="text-left text-truncate"
                                @click="deviceImage(device)"
                                >{{ $t("doors.image") }}</b-dropdown-item
                            >
                            <b-dropdown-item
                                class="text-left text-truncate"
                                @click="deviceQR(device)"
                                v-if="device.Device.ProviderType == 3 || device.Device.ProviderType == 5 || device.Device.ProviderType == 6"
                            >{{$t("doors.generate_qr")}}</b-dropdown-item>
                        </b-dropdown>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer card-footer-boxed">
                <div class="row align-items-center">
                    <div class="col">
                        <div class="row align-items-center no-gutters">
                            <p>
                            {{
                                $t("common.count_keys", {
                                    count: device.Keys
                                        ? device.Keys.length
                                        : device.KeysCount,
                                })
                            }}
                        </p>
                        </div>
                    </div>

                </div>
                <!-- / .row -->
            </div>
        </div>
        <!-- <zs-device-delete-modal :locationId="device.Device.LocationId"/> -->
       <!--  <zs-image-upload-modal @imageUploaded="updateDoorImage" /> -->
    </b-col>
</template>
<script>
import { getDeviceProvider } from "@/handlers/enums";
import DeviceQRModal from "@/components/modals/DeviceQR";
import { M_DEVICE_ADMIN_FORM, M_DEVICE_ADMIN_DELETE, M_IMAGE_UPLOAD, M_DEVICE_QR } from "@/components/modals/types";
import { IMAGE_TYPE_DOOR } from "@/handlers/const";
import { DEVICE_PATCH, DEVICE_GET, DEVICE_CODE_GET } from "@/store/types/devices";
import DeleteDeviceModal from "@/components/modals/DeleteDevice";
import ImageUploadModal from "@/components/modals/ImageUpload";
import { SET_MODAL_DATA } from "@/store/types/global";
import modalMixin from "@/mixins/modalMixin";
import {
    ADMIN_DEVICE_PATCH,
    ADMIN_DEVICES_GET,
} from "@/store/types/admin";

export default {
    mixins: [modalMixin],
    name: "door-large-card-item-admin",
    data(){
        return{
            isMobile: false,
        };
    },
    props: ["device"],
    created() {
        this.isMobile = window.addEventListener("resize", this.onResize);
        this.isMobile = window.addEventListener("onload", this.onLoad);
    },
    destroyed() {
        window.removeEventListener("resize", this.onResize);
        window.removeEventListener("onload", this.onLoad);
        this.isMobile = false;
    },
    methods: {
        deviceQR(device) {
            this.showModal(
                { device: device.Device },
                M_DEVICE_QR
            );
        },
        deviceName(name){
            let newName;
            if(name.length > 9){
                newName = name.slice(0, 7).concat('...');
            }else{
                newName = name;
            }
            return newName;
        },
        getDeviceProvider(id) {
            return getDeviceProvider(id);
        },
        editDevice(device) {
            this.showModal(
                { device: device.Device },
                M_DEVICE_ADMIN_FORM
            );
        },
        deleteDevice(device) {
            if(device.Device.Name){
                this.showModal(
                    { device: device.Device },
                    M_DEVICE_ADMIN_DELETE
                );
            }
        },
        deviceImage(device) {
            this.device = device
            this.$session.set("deviceId", this.device.Device.Id);
            this.$session.set("device",this.device.Device)
            this.showModal(
                {
                    image: {
                        url: this.device.Device.MediumImageUrl || "/images/zesec-placeholder.png",
                        type: IMAGE_TYPE_DOOR,
                    },
                },
                M_IMAGE_UPLOAD
            );
        },
        updateDoorImage(response) {
            this.device = this.$session.get("device");
            this.$store
                .dispatch(ADMIN_DEVICE_PATCH, {
                    ...this.device,
                    ...{ ImageId: response.Id },
                })
                .then(() => {
                    this.$store.dispatch(ADMIN_DEVICES_GET);
                })
                .catch((error) => {});
        },
        onResize(e) {
            this.width = window.innerWidth;
            this.height = window.innerHeight;
            // console.log("this.width:"+this.width+" and this.height:"+this.height)
            if(this.width < 1200 || this.height < 600){
                this.isMobile = true
            }else{
                this.isMobile = false
            }
            return this.isMobile;
        },
        onLoad(evt){
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                this.isMobile = true
            } else if(typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1){
                this.isMobile = true
            } else {
                this.isMobile = false
            }

            this.width = window.innerWidth;
            this.height = window.innerHeight;
            // console.log("this.width:"+this.width+" and this.height:"+this.height)
            if(this.width < 1200 || this.height < 600){
                this.isMobile = true
            }else{
                this.isMobile = false
            }

            return this.isMobile;
        },
    },
    components: {
        // "zs-device-delete-modal": DeleteDeviceModal,
       // "zs-image-upload-modal": ImageUploadModal,
    },
};
</script>
<!-- scoped -->
<style scoped>
img.provider {
    position: absolute;
    left: 5px;
    bottom: 10px;
    width: 50%;
}
.door-image-link {
    position: relative;
}
.btn:focus{
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-list-group-item',[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-auto"},[((_vm.employee.Location) && (_vm.employee.Company != null))?_c('router-link',{staticClass:"avatar avatar-lg",attrs:{"to":{
                        name: 'profile',
                        params: {
                            locationId: _vm.employee.Location.Id,
                            companyId: _vm.employee.Company.Id,
                            userId: _vm.employee.User.Id,
                        },
                    }}},[(_vm.employee.User.SmallImageUrl)?_c('img',{staticClass:"avatar-img rounded-circle",attrs:{"src":_vm.employee.User.SmallImageUrl,"alt":"..."}}):_c('span',{staticClass:"avatar-title rounded-circle"},[_c('img',{staticStyle:{"width":"67px","height":"67px"},attrs:{"src":'/images/person-big_MyZesec_140dp.png'}})])]):((_vm.employee.Location == null) && (_vm.employee.Company != null))?_c('router-link',{staticClass:"avatar avatar-lg",attrs:{"to":{
                        name: 'profile-owner',
                        params: {
                            // locationId: null,
                            companyId: _vm.employee.Company.Id,
                            userId: _vm.employee.User.Id,
                        },
                    }}},[(_vm.employee.User.SmallImageUrl)?_c('img',{staticClass:"avatar-img rounded-circle",attrs:{"src":_vm.employee.User.SmallImageUrl,"alt":"..."}}):_c('span',{staticClass:"avatar-title rounded-circle"},[_c('img',{staticStyle:{"width":"67px","height":"67px"},attrs:{"src":'/images/person-big_MyZesec_140dp.png'}})])]):(_vm.employee.Company == null && _vm.employee.Location)?_c('router-link',{staticClass:"avatar avatar-lg",attrs:{"to":{
                        name: 'profile-owner-new',
                        params: {
                            locationId: _vm.employee.Location.Id,
                            // companyId: null,
                            userId: _vm.employee.User.Id,
                        },
                    }}},[(_vm.employee.User.SmallImageUrl)?_c('img',{staticClass:"avatar-img rounded-circle",attrs:{"src":_vm.employee.User.SmallImageUrl,"alt":"..."}}):_c('span',{staticClass:"avatar-title rounded-circle"},[_c('img',{staticStyle:{"width":"67px","height":"67px"},attrs:{"src":'/images/person-big_MyZesec_140dp.png'}})])]):_vm._e()],1),_c('div',{staticClass:"col ml-n2"},[_c('h4',{staticClass:"mb-1"},[((_vm.employee.Location) && (_vm.employee.Company != null))?_c('router-link',{attrs:{"to":{
                            name: 'profile',
                            params: {
                                locationId: _vm.employee.Location.Id,
                                companyId: _vm.employee.Company.Id,
                                userId: _vm.employee.User.Id,
                            },
                        }}},[_vm._v(_vm._s(_vm.userFName(_vm.employee.User.FirstName))+" "+_vm._s(_vm.userLName(_vm.employee.User.LastName)))]):((_vm.employee.Location == null) && (_vm.employee.Company != null))?_c('router-link',{attrs:{"to":{
                            name: 'profile-owner',
                            params: {
                                // locationId: null,
                                companyId: _vm.employee.Company.Id,
                                userId: _vm.employee.User.Id,
                            },
                        }}},[_vm._v(" "+_vm._s(_vm.userFName(_vm.employee.User.FirstName))+" "+_vm._s(_vm.userLName(_vm.employee.User.LastName))+" ")]):(_vm.employee.Company == null && _vm.employee.Location)?_c('router-link',{attrs:{"to":{
                            name: 'profile-owner-new',
                            params: {
                                locationId: _vm.employee.Location.Id,
                                // companyId: null,
                                userId: _vm.employee.User.Id,
                            },
                        }}},[_vm._v(" "+_vm._s(_vm.userFName(_vm.employee.User.FirstName))+" "+_vm._s(_vm.userLName(_vm.employee.User.LastName))+" ")]):_vm._e()],1),(_vm.employee.Company != null)?_c('p',{staticClass:"card-text small text-muted mb-1"},[_vm._v(" "+_vm._s(_vm.employee.Company.Name)+" ")]):_vm._e(),(_vm.employee.Location != null)?_c('p',{staticClass:"card-text small text-muted mb-1"},[_vm._v(" "+_vm._s(_vm.employee.Location.Name)+" ")]):_vm._e(),(_vm.employee.Company != null || _vm.employee.Location != null)?_c('p',{staticClass:"card-text small text-muted mb-1"},[_vm._v(" "+_vm._s(_vm.roleName(_vm.employee.Role.Name))+" ")]):_vm._e()]),(_vm.employee.Company || _vm.employee.Location)?_c('div',{staticClass:"col-auto"},[_c('b-dropdown',{attrs:{"variant":"outline","toggle-class":"dropdown-ellipses dropdown-toggle","no-caret":"","right":"","toggle-tag":"a"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('img',{staticStyle:{"width":"19px","height":"19px"},attrs:{"src":'/images/settings_grey_32x32dp.png'}})]},proxy:true}],null,false,2784308510)},[(_vm.employee.Role.Name != 'CompanyOwner')?_c('b-dropdown-item',{staticClass:"text-left text-truncate",on:{"click":function($event){return _vm.deleteUser(_vm.employee)}}},[_vm._v(_vm._s(_vm.$t("buttons.remove_staff")))]):_vm._e(),(_vm.employee.Role.Name != 'CompanyOwner')?_c('b-dropdown-item',{staticClass:"text-left text-truncate",on:{"click":function($event){return _vm.changeRole(_vm.employee)}}},[_vm._v(_vm._s(_vm.$t("staff.change_role")))]):_vm._e(),(_vm.employee.Role.Name == 'CompanyOwner')?_c('b-dropdown-item',{staticClass:"text-left text-truncate",on:{"click":function($event){return _vm.transferRights(_vm.employee)}}},[_vm._v(_vm._s(_vm.$t("staff.transfer_rights")))]):_vm._e()],1)],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <b-modal
        :id="type"
        :title="$t('modals.title_door_qr')"
        :ok-title="$t('buttons.download')"
        :cancel-title="$t('buttons.cancel')"
        modal-class="fixed-right"
        dialog-class="modal-dialog-vertical"
        @ok="downloadQR"
        @show="setData"
        @hide="onClose"
    >
        <b-container fluid v-if="type == getModalType">
            <b-row>
                <b-col cols="12">
                    <vue-qr
                        :text="link"
                        :size="600"
                        :logoMargin="5"
                        :logoSrc="require('@/assets/images/qr-logo.png')"
                        :callback="setImage"
                        class="w-100"
                    ></vue-qr>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { M_DEVICE_QR } from "@/components/modals/types";
import { CLEAR_MODAL_DATA } from "@/store/types/global";
import VueQr from "vue-qr";
// import QRLogo from require("@/assets/images/qr-logo.png");

export default {
    data() {
        return {
            type: M_DEVICE_QR,
            link: "",
            dataUrl: "",
            device: {},
        };
    },
    computed: {
        ...mapGetters(["getModalData", "getModalType"]),
    },

    methods: {
        setData() {
            this.device = { ...this.getModalData.device };
            const params = {
                locationId: this.device.LocationId,
                deviceId: this.device.Id,
            };
            this.link =
                process.env.VUE_APP_INTERCOM_URL +
                "/?call=" +
                btoa(JSON.stringify(params))
                    .replace(/\+/g, "-")
                    .replace(/\//g, "_")
                    .replace(/=+$/, "");
        },
        setImage(dataUrl) {
            this.dataUrl = dataUrl;
        },
        downloadQR() {
            const link = document.createElement("a");
            link.download = "QR_door_" + this.device.Name + ".png";
            link.href = this.dataUrl;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        onClose() {
            this.device = null;
            this.$store.commit(CLEAR_MODAL_DATA);
        },
    },
    components: { VueQr },
};
</script>

<template>
    <b-modal
        :id="type"
        :title="$t('keys.send_invite')"
        size="lg"
        hide-footer
        modal-class="fixed-right"
        dialog-class="modal-dialog-vertical"
        @show="onShow"
        @hide="onClose"
    >
        <b-container fluid>
            <div class="accordion" role="tablist">
                <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" role="tab" class="p-1">
                        <b-button
                            block
                            v-b-toggle.accordion-c
                            variant="primary"
                            ><b-row class="px-2"
                                ><i class="fi flaticon-business-1 mr-2"></i
                                >{{ $t("companies.company") }}:</b-row
                            >
                            <b-row class="px-2">
                                {{ $t("keys.selected_company") }}:
                                <strong>{{ selectedCompany ? selectedCompany.Name : "" }}</strong></b-row
                            ></b-button
                        >
                    </b-card-header>
                    <b-collapse
                        id="accordion-c"
                        accordion="my-accordion"
                        role="tabpanel"
                        visible
                    >
                        <b-card-body>
                            <b-form-group>
                               <!--  <b-form-select
                                    v-model="$v.invite.CompanyId.$model"
                                    :state="validateState('CompanyId')"
                                    id="CompanyId"
                                    v-if="companiesList.length > 15"
                                    :options="companiesList"
                                    @change="initCompany"
                                    onfocus='this.size=10, this.height=2'
                                    onchange='this.size=10, this.height=2, this.blur();'
                                    onblur='this.size=10, this.height=2' 
                                    onfocusout='this.size=null;'
                                ></b-form-select> -->
                                <b-form-select
                                    v-model="$v.invite.CompanyId.$model"
                                    :state="validateState('CompanyId')"
                                    id="CompanyId"
                                    :options="companiesList"
                                    @change="initCompany"
                                ></b-form-select>
                                <b-form-invalid-feedback
                                    id="CompanyId-feedback"
                                    >
                                    <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{
                                        $t("staff.company_select")
                                    }}
                                </b-form-invalid-feedback
                                >
                            </b-form-group>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" role="tab" class="p-1">
                        <!--  :disabled="!locationManagerSelected || !keyManagerSelected" -->
                        <b-button
                            block
                            v-b-toggle.accordion-l
                            variant="primary"
                            :disabled="!invite.CompanyId"
                            ><b-row class="px-2"
                                ><i class="fi flaticon-business mr-2"></i
                                >{{ $t("locations.location") }}:</b-row
                            >
                            <b-row class="px-2">
                                {{ $t("keys.selected_location") }}:
                                <strong>{{ selectedLocation ? selectedLocation.Location.Name : "" }}</strong></b-row
                            ></b-button
                        >
                    </b-card-header>
                    <b-collapse
                        id="accordion-l"
                        accordion="my-accordion"
                        role="tabpanel"
                    >
                        <b-card-body>
                            <b-form-group>
                                <!-- <b-form-select
                                    v-model="$v.invite.LocationId.$model"
                                    :state="validateState('LocationId')"
                                    id="LocationId"
                                    v-if="locationsListOptions.length > 15"
                                    :options="locationsListOptions"
                                    @change="initLocation"
                                    onfocus='this.size=10, this.height=2'
                                    onchange='this.size=10, this.height=2, this.blur();'
                                    onblur='this.size=10, this.height=2' 
                                    onfocusout='this.size=null;'
                                ></b-form-select> -->
                                <b-form-select
                                    v-model="$v.invite.LocationId.$model"
                                    :state="validateState('LocationId')"
                                    id="LocationId"
                                    :options="locationsListOptions"
                                    @change="initLocation"
                                ></b-form-select>
                                <b-form-invalid-feedback
                                    id="LocationId-feedback"
                                    >
                                    <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{
                                        $t("staff.location_select")
                                    }}
                                </b-form-invalid-feedback
                                >
                            </b-form-group>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" role="tab" class="p-1">
                        <!-- :disabled="disableKeySelect" -->
                        <b-button
                            v-b-toggle.accordion-1
                            variant="primary"
                            :disabled="!invite.LocationId"
                            ><b-row class="px-2"
                                >
                               <!-- <img class="mr-2" :src="'/images/Key_MyZesec_64dp.png'" style="width: 32px; height: 32px;"/> -->
                               <i class="fi flaticon-key mr-2"></i>{{ $t("keys.key") }}:</b-row
                            >
                            <b-row class="px-2">
                                {{ $t("keys.selected") }}:
                                <strong>{{
                                    selectedKey ? selectedKey.Key.Name : ""
                                }}</strong></b-row
                            >
                        </b-button>
                    </b-card-header>
                    <b-collapse
                        id="accordion-1"
                        accordion="my-accordion"
                        role="tabpanel"
                        :visible="!disableKeySelect"
                    >
                        <b-card-body>
                            <b-form-group>
                                <!-- <b-form-select
                                    v-model="$v.invite.KeyId.$model"
                                    :state="validateState('KeyId')"
                                    id="KeyId"
                                    v-if="locationKeysOptions.length > 15"
                                    :options="locationKeysOptions"
                                    @change="keySelected"
                                    :disabled="disableKeySelect"
                                    onfocus='this.size=10, this.height=2'
                                    onchange='this.size=10, this.height=2, this.blur();'
                                    onblur='this.size=10, this.height=2' 
                                    onfocusout='this.size=null;'
                                ></b-form-select> -->
                                <b-form-select
                                    v-model="$v.invite.KeyId.$model"
                                    :state="validateState('KeyId')"
                                    id="KeyId"
                                    :options="locationKeysOptions"
                                    @change="keySelected"
                                    :disabled="disableKeySelect"
                                ></b-form-select>                               
                                <b-form-invalid-feedback id="KeyId-feedback">
                                    <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{ $t("keys.select_share") }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-card-body>
                    </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" role="tab" class="p-1">
                        <b-button
                            block
                            v-b-toggle.accordion-2
                            variant="primary"
                            :disabled="!invite.KeyId"
                            ><b-row class="px-2"
                                >
                               <i class="fi flaticon-user mr-2"></i>{{ $t("keys.share_to") }}:</b-row
                            >
                            <b-row class="px-2">
                                {{ $t("keys.selected_peoples") }}:
                                <strong>{{ listUsers.length }}</strong></b-row
                            ></b-button
                        >
                    </b-card-header>
                    <b-collapse
                        id="accordion-2"
                        accordion="my-accordion"
                        role="tabpanel"
                        :visible="disableKeySelect"
                    >
                        <b-card-body>
                            <b-form-group>
                                <b-form-radio-group
                                    id="inviteMode"
                                    class="w-100"
                                    v-model="inviteMode"
                                    size="sm"
                                    :options="inviteModesOptions"
                                    buttons
                                    button-variant="outline-primary"
                                    name="keyType-default"
                                ></b-form-radio-group>
                            </b-form-group>

                            <b-form-group v-if="inviteMode == 0">
                                <b-form-group>
                                    <vue-phone-number-input
                                        v-model="$v.invite.PhoneNumber.$model"
                                        id="userPhone"
                                        :error="!validPhone && userChecked"
                                        :translations="$t('phoneInput')"
                                        valid-color="#28a745"
                                        error-color="#dc3545"
                                        @update="validatePhone"
                                        required
                                        fetch-country
                                        type="text"
                                    />
                                    <span id="userPhone-feedback"  v-if="!validPhone && userChecked"  style="color: #e63857;  font-size: 0.8125rem">
                                        <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                        {{
                                            $t("errors.validation_phone_number")
                                        }}
                                    </span>
                                </b-form-group>
                                <b-form-group>
                                    <b-input-group>
                                        <b-form-input
                                            v-model="$v.invite.Name.$model"
                                            :state="validateState('Name')"
                                            id="memberName"
                                            :placeholder="$t('users.name')"
                                        />
                                        <!-- <b-input-group-append> -->
                                            <b-button
                                                style="margin-left: -1px; border-radius: 0px !important;"
                                                variant="primary"
                                                @click="addNewUser"
                                                >{{ $t("keys.add_user") }}
                                            </b-button>
                                       <!--  </b-input-group-append> -->
                                        <b-form-invalid-feedback
                                        id="memberName-feedback"
                                    >
                                    <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                        {{
                                            $t(
                                                "errors.validation_name_range",
                                                {
                                                    range: "3-50",
                                                }
                                            )
                                        }}
                                    </b-form-invalid-feedback>
                                    </b-input-group>
                                   <!-- {{
                                            $t(
                                                "errors.validation_length_range",
                                                {
                                                    range: "3-40",
                                                }
                                            )
                                        }} -->
                                </b-form-group>
                                <b-form-group>
                                    <Select2
                                        :options="locationMembersListClear"
                                        v-model="selectedMembersIds"
                                        @select="addMember"
                                        @unselect="subMember"
                                        :settings="{
                                            placeholder: $t(
                                                'keys.search_name_or_phone'
                                            ),
                                            multiple: true,
                                            allowClear: true,
                                            minimumResultsForSearch: 1,
                                            closeOnSelect: false,
                                            containerCssClass: 'custom-select',
                                            dropdownCssClass:
                                                'dropdown-menu dropdown-menu-sm',
                                        }"
                                    />
                                </b-form-group>
                            </b-form-group>

                            <b-form-group v-if="inviteMode == 2">
                                <b-row>
                                    <b-input-group>
                                        <b-form-file
                                            accept=".csv"
                                            :placeholder="
                                                $t('keys.csv_placeholder')
                                            "
                                            :drop-placeholder="
                                                $t('keys.drop_placeholder')
                                            "
                                            :browse-text="$t('keys.csv_browse')"
                                            @input="csvParse"
                                        ></b-form-file>
                                        <b-button
                                            variant="outline-secondary"
                                            id="popover-csv"
                                        >
                                            <b-icon
                                                icon="question-circle-fill"
                                                aria-label="Help"
                                            ></b-icon>
                                        </b-button>
                                        <b-popover
                                            target="popover-csv"
                                            triggers="click"
                                            placement="auto"
                                            container="my-container"
                                            ref="popover"
                                        >
                                            <template v-slot:title>
                                                <b-button
                                                    @click="
                                                        $refs.popover.$emit(
                                                            'close'
                                                        )
                                                    "
                                                    class="close"
                                                >
                                                    <span
                                                        class="d-inline-block"
                                                        aria-hidden="true"
                                                        >&times;</span
                                                    >
                                                </b-button>
                                                {{ $t("keys.csv_format") }}
                                            </template>

                                            <div>
                                                {{ $t("keys.csv_example") }}
                                                <strong>
                                                    <a
                                                        href="#"
                                                        @click.prevent.stop="
                                                            csvDownload()
                                                        "
                                                        >example.csv</a
                                                    >
                                                </strong>
                                            </div>
                                        </b-popover>
                                    </b-input-group>
                                </b-row>
                            </b-form-group>
                        </b-card-body>
                    </b-collapse>
                </b-card>

                <b-card no-body class="mb-1" v-if="!listUsers.length" style="overflow: hidden !important;">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button
                            block
                            v-b-toggle.accordion-3
                            variant="primary"
                            :disabled="!listUsers.length"
                            ><b-row class="px-2"
                                ><i class="fe fe-settings mr-2"></i
                                >{{ $t("keys.sharing_options") }}:</b-row
                            >
                            <b-row class="px-2"
                                ><strong>
                                    {{
                                        shareOptions.mayShare
                                            ? $t("keys.share_allowed")
                                            : $t("keys.share_disallowed")
                                    }},
                                    {{
                                        shareOptions.keyCalendarType == 6
                                            ? $t("keys.type_permanent")
                                            : $t("keys.type_temporary")
                                    }}</strong
                                ></b-row
                            >
                        </b-button>
                    </b-card-header>
                    <b-collapse
                        id="accordion-3"
                        accordion="my-accordion"
                        role="tabpanel"
                    >
                        <b-card-body class="px-2">
                            <b-form>
                                <share-options
                                    v-if="!hidden"
                                    v-model="shareOptions"
                                    ref="shareOptionsWidget"
                                />
                            </b-form>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <b-card no-body class="mb-1" v-else style="overflow-y: auto !important;">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button
                            block
                            v-b-toggle.accordion-3
                            variant="primary"
                            :disabled="!listUsers.length"
                            ><b-row class="px-2"
                                ><i class="fe fe-settings mr-2"></i
                                >{{ $t("keys.sharing_options") }}:</b-row
                            >
                            <b-row class="px-2"
                                ><strong>
                                    {{
                                        shareOptions.mayShare
                                            ? $t("keys.share_allowed")
                                            : $t("keys.share_disallowed")
                                    }},
                                    {{
                                        shareOptions.keyCalendarType == 6
                                            ? $t("keys.type_permanent")
                                            : $t("keys.type_temporary")
                                    }}</strong
                                ></b-row
                            >
                        </b-button>
                    </b-card-header>
                    <b-collapse
                        id="accordion-3"
                        accordion="my-accordion"
                        role="tabpanel"
                    >
                        <b-card-body class="px-2">
                            <b-form>
                                <share-options
                                    v-if="!hidden"
                                    v-model="shareOptions"
                                    ref="shareOptionsWidget"
                                />
                            </b-form>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>
            <b-row>
               <!--  @click="inviteMember(this.invite.KeyId)" -->
                <b-col class="mt-2">
                    <b-button
                        class="input-field"
                        v-if="!saving"
                        :disabled="listUsers.length == 0"
                        @click.prevent.stop="inviteMember"
                        >{{ $t("keys.send_invite") }}</b-button
                    >
                    <b-button class="input-field" disabled v-else>
                        <b-spinner small></b-spinner>
                    </b-button>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import {
    MEMBER_TO_KEY_INVITE,
    MEMBER_LIST_TO_KEY_INVITE,
} from "@/store/types/members";
import {
    definedRoles,
    ROLE_KEY_PERMANENT,
    ROLE_KEY_TEMPORARY,
} from "@/acl/roles";

import { KEY_USERS_GET, KEY_GET } from "@/store/types/keys";
import {
  ADMIN_KEYS_GET,
  ADMIN_USERS_GET,
  ADMIN_MEMBER_LIST_TO_KEY_INVITE
} from "@/store/types/admin";
import {
    LOCATION_MEMBERS_GET,
    LOCATION_INVITES_GET
} from "@/store/types/members";

import { getWeekDays } from "@/handlers/enums";

import { M_MEMBER_INVITE_ADMIN_FORM } from "@/components/modals/types";
import { CLEAR_MODAL_DATA } from "@/store/types/global";
import { validationMixin } from "vuelidate";
import {
    required,
    minLength,
    maxLength,
    requiredIf,
    numeric,
} from "vuelidate/lib/validators";
import calendarController from "@/api/calendar";
import VuePhoneNumberInput from "vue-phone-number-input";
import Select2 from "@/components/common/Select2";
import {
    parsePhoneNumberFromString,
    findPhoneNumbersInText,
} from "libphonenumber-js";

import ShareOptions from "@/components/key/share/ShareOptions";

export default {
    mixins: [validationMixin],
    data() {
        return {
            type: M_MEMBER_INVITE_ADMIN_FORM,
            inviteMode: 0,
            validPhone: false,
            fullPhone: null,
            disableKeySelect: false,
            userChecked: false,
            invite: {},
            shareOptions: {},
            listUsers: [],
            locationMembersList: [],
            keyMembersList: [],
            userSearch: "",
            saving: false,
            selectedMembersIds: [],
            hidden: true,
            filter: "",
            filterCompany: "",
        };
    },
    validations: {
        invite: {
            CompanyId: {
                required,
                numeric,
            },
            LocationId: {
                required,
                numeric,
            },
            Name: {
                required: requiredIf(function () {
                    return this.inviteMode == 0;
                }),
                minLength: minLength(3),
                maxLength: maxLength(50),
            },
            PhoneNumber: {
                required: requiredIf(function () {
                    return this.inviteMode == 0;
                }),
                phone: function () {
                    return this.validPhone || this.inviteMode != 0;
                },
            },
            KeyId: {
                required,
                numeric,
            },
        },
    },
    computed: {
        ...mapGetters([
            "getModalData",
            "getModalType",
            "getAllAdminCompanies",
            "getAllLocations",
            "getAllKeys",
            "getAllUsers",
        ]),
        inviteTemplate() {
            return {
                CompanyId: null,
                LocationId: null,
                Name: "",
                PhoneNumber: "",
                RoleType: definedRoles[ROLE_KEY_PERMANENT].Id,
                KeyId: null,
            };
        },
        shareOptionsTemplate() {
            return {
                keyCalendarType: definedRoles[ROLE_KEY_PERMANENT].Id,
                dateFrom: new Date(),
                timeFrom: "00:00:00",
                dateTo: new Date(),
                // dateTo: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
                timeTo: "00:00:00",
                weekDays: getWeekDays(),
                mayShare: false,
                recurrent: false,
            };
        },
        companiesList() {
            return [
                {
                    value: null,
                    text: this.$t("locations.company_select"),
                    disabled: true,
                },
            ...this.getAllAdminCompanies
            .filter(
                (item) =>
                    item.Name.toLowerCase().includes(this.filter.toLowerCase())
                )
                .sort((i1, i2) => {
                    return i1.Name.toUpperCase() > i2.Name.toUpperCase() ? 1 : -1;
                })
                .map((item) => {
                    return { value: item.Id, text: item.Name };
                }),
            ];
        },
        locationsListOptions() {
            return [
                {
                    value: null,
                    text: this.$t("staff.location_select"),
                    disabled: true,
                },
                ...this.getAllLocations
                    .filter((item) => item.Location.CompanyId == this.invite.CompanyId)
                    .sort((i1, i2) => {
                        return i1.Location.Name.toUpperCase() > i2.Location.Name.toUpperCase() ? 1 : -1;
                    })
                    .map((item) => {
                        return { value: item.Location.Id, text: item.Location.Name };
                    }),
            ];
        },
        locationKeysOptions() {            
            return [
                {
                    value: null,
                    text: this.$t("keys.select_share"),
                    disabled: true,
                },
                ...this.getAllKeys
                .filter((item) => item.Key.LocationId == this.invite.LocationId)
                .sort((i1, i2) => {
                    if(i1.Key.Name.includes("Key of: ")){
                        i1.Key.Name = i1.Key.Name.replace("Key of: ","");
                    }
                    if(i2.Key.Name.includes("Key of: ")){
                        i2.Key.Name = i2.Key.Name.replace("Key of: ","");
                    }
                    return i1.Key.Name.toUpperCase() > i2.Key.Name.toUpperCase() ? 1 : -1;
                })
                .map((item) => {
                    if(item.Key.Name.includes("Key of: ")){
                        item.Key.Name = item.Key.Name.replace("Key of: ","");
                    }
                    if(item.Key.Name.length > 20){
                        item.Key.Name = item.Key.Name.slice(0, 16).concat('...');
                    }
                    return { value: item.Key.Id, text: item.Key.Name };
                }),
            ];
        },
        inviteModesOptions() {
            return [
                { text: this.$t("keys.select_peoples"), value: 0 },
                { text: this.$t("keys.invite_csv"), value: 2 },
            ];
        },
        selectedCompany() {
            return this.getAllAdminCompanies.find(
                (item) => item.Id == this.invite.CompanyId
            );
        },
        selectedLocation() {
            return this.getAllLocations.find(
                (item) => item.Location.Id == this.invite.LocationId
            );
        },
        selectedKey() {
            return this.getAllKeys.find(
                (item) => item.Key.Id == this.invite.KeyId
            );
        },
        locationMembersListClear() {
            return this.locationMembersList.filter(
                (item) => !this.keyMembersList.includes(item.id)
            );
        },
    },
    created() {
        this.$store.dispatch(ADMIN_KEYS_GET);
    },
    methods: {
        initCompany(){
            this.invite.LocationId = null;
            this.invite.KeyId = null;
            this.$root.$emit("bv::toggle::collapse", "accordion-l");
        },
        initLocation(){
            this.invite.KeyId = null;
            this.$root.$emit("bv::toggle::collapse", "accordion-1");
        },
        companyName() {            
                return this.companiesList.find(
                    (item) => item.value == this.invite.CompanyId
                );
        },
        locationName() {           
                return this.locationsListOptions.find(
                    (item) => item.value == this.invite.LocationId
                );
        },
        initMembers() {
            this.$store
                .dispatch(ADMIN_USERS_GET)
                .then((response) => {
                    this.locationMembersList = [];
                    const map = new Map();
                    for (const item of response) {
                        if (!map.has(item.Id)) {
                            map.set(item.Id, true);
                            if(item.LastName == null || item.LastName == "null" || item.LastName == ""){
                                item.LastName = ""
                            }
                            this.locationMembersList.push({
                                text: `${item.FirstName} ${
                                    item.LastName || ""
                                } - ${item.PhoneNumber}`,
                                id: item.PhoneNumber,
                            });
                        }
                    }
                });
        },
        filterMembers(searchQuery, id) {
            this.userSearch = searchQuery;
        },
        validateState(name) {
            const { $dirty, $error } = this.$v.invite[name];
            return $dirty && this.userChecked ? !$error : null;
        },
        keySelected(keyId) {
            this.$root.$emit("bv::toggle::collapse", "accordion-2");
            this.loadKeyUsers(keyId);
        },
        loadKeyUsers(keyId) {
            this.$store.dispatch(ADMIN_USERS_GET).then((response) => {
                this.keyMembersList = response.map((item) => item.Id);
            });
        },
        addNewUser() {
            this.$v.invite.$touch();
            this.userChecked = true;
            if (this.$v.invite.$anyError) {
                document.getElementsByClassName('invalid-feedback').style.display = 'block';
                return;
            }
           
            this.locationMembersList.push({
                text: `${this.invite.Name} - ${this.fullPhone.formattedNumber}`,
                id: this.fullPhone.formattedNumber,
            });

            this.listUsers.push({
                Id: this.fullPhone.formattedNumber,
                Name: this.invite.Name,
                Phone: this.fullPhone.formattedNumber,
            });

            this.selectedMembersIds.push(this.fullPhone.formattedNumber);
            this.invite.Name = "";
            this.invite.PhoneNumber = "";
            this.userChecked = false;
        },
        validatePhone(phone) {
            this.validPhone = phone.isValid;
            this.fullPhone = phone;
        },
        inviteMember() {            
            if (
                this.shareOptions.keyCalendarType ==
                definedRoles[ROLE_KEY_PERMANENT].Id
            ) {
                this.invite["CalendarId"] = null;
                this.sendInvite();
            } else {
                calendarController
                    .postCalendar(
                        this.$refs.shareOptionsWidget.buildCalendarPayload()
                    )
                    .then((response) => {
                        this.invite["CalendarId"] = response.data.Id;
                        this.sendInvite();
                    });
            }
        },

        sendInvite() {
            this.saving = true;
            const keyId = this.invite.KeyId;
            let invitesList = this.listUsers.map((user) => {
                return {
                    ...this.invite,
                    ...{
                        MayShare: this.shareOptions.mayShare,
                        PhoneNumber: user.Phone,
                        Name: user.Name,
                        LocationId: this.invite.LocationId,
                        RoleType: this.shareOptions.keyCalendarType,
                    },
                };
            });

            this.$store
                .dispatch(ADMIN_MEMBER_LIST_TO_KEY_INVITE, {
                    invitesList,
                    locationId: this.LocationId,
                })
                .then(() => {                    
                    this.$bvModal.hide(this.type);
                    this.saving = false;
                })
                .catch((error) => {
                    this.saving = false;
                });
        },

        setData() {
            this.shareOptions = { ...this.shareOptionsTemplate };
            this.shareOptions.weekDays = getWeekDays();
            this.initMembers();
            if (this.getModalData !== null) {
                this.invite = { ...this.getModalData.invite };
                this.invite.KeyId = this.getModalData.keyId;
                this.disableKeySelect = true;
            } else {
                this.invite = { ...this.inviteTemplate };
                this.disableKeySelect = false;
            }
            this.$v.invite.$reset();
            this.hidden = false;
        },
        onShow() {
            this.setData();
        },
        onClose() {
            this.userChecked = false;
            this.invite = { ...this.inviteTemplate };
            this.inviteMode = 0;
            this.listUsers = [];
            this.keyMembersList = [];
            this.selectedMembersIds = [];
            this.shareOptions.weekDays = getWeekDays();
            this.hidden = true;
            this.$store.commit(CLEAR_MODAL_DATA);
        },
        csvParse(file) {
            let row = 0;
            this.listUsers = [];
            this.selectedMembersIds = [];
            this.$papa.parse(file, {
                worker: true,
                step: (results, parser) => {
                     if (row > 0) {
                        if (results.data.length == 2) {
                            let phoneParsed = this.sanitizeNumber(
                                results.data[1]
                            );
                            if (phoneParsed) {
                                this.listUsers.unshift({
                                    Name: results.data[0],
                                    Phone: phoneParsed.format("E.164"),
                                });
                            }
                        }
                    }
                    row++;
                },
                complete: function() {
                }                
            });
        },
        sanitizeNumber(phoneNumber) {
            return parsePhoneNumberFromString(
                ("+" + phoneNumber.split(" ").join("")).replace("++", "+")
            );
        },
        csvDownload() {
            let example = this.$papa.unparse([
                ["Name", "Phone"],
                ["Erik Andersson", "+46707000000"],
                ["Maria Magnusson", "+46707010101"],
            ]);
            this.$papa.download(example, "example");
        },
        addMember(member) {
            if (this.listUsers.find((user) => user.PhoneNumber == member.id)) {
                return;
            }
            let user = this.getAllUsers.find(
                (item) => item.PhoneNumber == member.id
            );
            if (user) {
                this.listUsers.unshift({
                    Id: member.id,
                    Name: `${user.FirstName} ${user.LastName || ""}`,
                    Phone: user.PhoneNumber,
                });
            } else {
                this.listUsers.unshift({
                    Id: member.id,
                    Name: member.text,
                    Phone: member.id,
                });
            }
        },
        subMember(member) {
            let index = this.listUsers.findIndex(
                (item) => item.Id == member.id
            );
            this.removeFromList(index);
        },
        removeFromList(index) {
            this.listUsers.splice(index, 1);
        },
    },
    components: {
        "vue-phone-number-input": VuePhoneNumberInput,
        ShareOptions,
        Select2,
    },
};
</script>
<style>
.select2-search__field {
    width: 100% !important;
    padding: 0.125rem 2.5rem !important;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35' height='16' opacity='0.5' class='bi bi-search' style='margin-left: 2px' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat left;
}
li.select2-search--inline {
    width: 100% !important;
}
</style>

<template>
    <b-modal
        :id="type"
        :title="$t('doors.add')"
        :ok-title="$t('salto.create_saltoInstallation_new')"
        :cancel-title="$t('buttons.cancel')"
        ok-variant="outline-danger"
        modal-class="fixed-right"
        dialog-class="modal-dialog-vertical"        
        @ok="addDeviceList"
        @show="setData"
        @hide="onClose"      
        :ok-disabled="addDevice.length == 0"  
    >
     <!-- :hide-footer="this.devList.length > 0" && (this.devList.length > 0)-->
        <b-container fluid v-if="(type == getModalType)">
            <b-row v-if="this.devList.length > 0">
                <b-col cols="12">
                    <strong>{{ $t("doors.doors_connected") }}</strong>                   
                </b-col>
            </b-row>
            <b-row v-if="this.devList.length > 0">
                <b-col cols="12">
                    <div 
                        v-for="dev in devList"
                        v-bind:key="dev.Id"
                        :device="dev"
                        style="display: list-item;"
                    >
                        {{ dev.text }} 
                    </div>                                
                </b-col>
            </b-row>
            <br><br>
            <b-row>
                <b-col cols="12">
                    <strong>{{ $t("doors.doors_not_connected") }}</strong>                   
                </b-col>
            <!-- </b-row>
            <b-row> -->
                <b-col cols="12">                       
                    <b-form-checkbox
                        v-model="addDevice"
                        v-for="dev in addList"
                        :key="dev.id"
                        :value="dev.id"
                        @change="addDoor"
                    >
                        {{ dev.text }}
                    </b-form-checkbox>   
                                              
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { M_ADD_DEVICE } from "@/components/modals/types";
import { CLEAR_MODAL_DATA } from "@/store/types/global";
import { ADMIN_DISCONNECTED_DEVICES, ADMIN_CONNECT_MULTIPLE_DEVICES, ADMIN_KEYS_GET } from "@/store/types/admin";

export default {
    data() {
        return {
            type: M_ADD_DEVICE,
            devList: [],
            addList: [],
            addDevice: [],
            locationId: this.$session.get("locationId"),
            keyId: null,
            key: {},
            filter: "",
        };
    },
    computed: {
        ...mapGetters(["getModalData", "getModalType", "getLocationDevices", "getDisconnectedDoors"]),
    },
    created(){
    },
    methods: {            
        setData() {    
            this.key = this.getModalData.key; 
            this.devList = [];
            this.addList = [];
            this.addDevice = [];
            this.locationId = this.getModalData.key.Key.LocationId;
            this.keyId = this.getModalData.key.Key.Id;
            if(this.getModalData.key.Devices.length > 0){
                for(let i=0;i<this.getModalData.key.Devices.length;i++){
                    this.devList.push({
                        text: this.getModalData.key.Devices[i].Device.Name,
                        id: this.getModalData.key.Devices[i].Device.Id,
                    })                                   
                }
            }
            this.$store.dispatch(ADMIN_DISCONNECTED_DEVICES, {
                    locationId: this.locationId, 
                    keyId: this.keyId
                })
                .then((response)=>{
                    for(let i=0;i<response.length;i++){
                        this.addList.push({
                            text: response[i].Name,
                            id: response[i].Id,
                        })
                    }
                })                        
                .catch((error) => {
                    
                });                          
        },
        addDoor() {
          return this.addDevice
        },
        addDeviceList(){
            if(this.addDevice.length != 0){                
                this.$store
                .dispatch(ADMIN_CONNECT_MULTIPLE_DEVICES, {
                    keyId: this.keyId,
                    patch: this.addDevice,
                })
                .then(() => {
                    this.$bvModal.hide(this.type);
                    this.$store.dispatch(ADMIN_KEYS_GET);
                })
                .catch((error) => {});
            }
        },
        onClose() {
            this.salto = null;
            this.$store.commit(CLEAR_MODAL_DATA);
        },
    },
};
</script>
<style scoped>
.btn.disabled, .btn:disabled{
    color:  #6e84a3;
    border-color: #6e84a3;
} 
</style>

<template>
    <b-modal
        :id="type"
        :title="$t('modals.title_delete_salto_installation')"
        :ok-title="$t('buttons.delete')"
        :cancel-title="$t('buttons.cancel')"
        ok-variant="outline-danger"
        modal-class="fixed-right"
        dialog-class="modal-dialog-vertical"        
        @ok="deleteSalto"
        @show="setData"
        @hide="onClose"
        :ok-disabled="this.devList.length > 0"
    >
     <!-- :hide-footer="this.devList.length > 0" -->
        <b-container fluid v-if="type == getModalType">
            <b-row v-if="this.devList.length == 0">
                <b-col cols="12">
                    {{
                        $t("modals.text_delete_salto_installation", {
                            name: getModalData.salto.Name,
                        })
                    }}
                    <!-- <span class="bold-font-style">{{
                        $t("modals.are_you_sure")
                    }}</span> -->
                </b-col>
            </b-row>
            <b-row v-if="this.devList.length > 0">
                <b-col cols="12">
                <strong>{{ $t("modals.device_list") }}</strong>
                    <div 
                                v-for="dev in this.devList"
                                v-bind:key="dev.Id"
                                :device="dev"
                                style="display: list-item;"
                            >
                                {{ dev.text }} 
                            </div>                                
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import {
    SALTO_INSTALLATION_DELETE,
    SALTO_INSTALLATION_GET
} from "@/store/types/salto";
import { DEVICE_CONFIGURED } from "@/store/types/devices";
import { M_SALTO_INSTALLATION_DELETE } from "@/components/modals/types";
import { CLEAR_MODAL_DATA } from "@/store/types/global";

export default {
    data() {
        return {
            type: M_SALTO_INSTALLATION_DELETE,
            salto: [],
            devList: [],
        };
    },
    computed: {
        ...mapGetters(["getModalData", "getModalType", "getActiveLocation", "getAllDevices", "getConfiguredDevices", "getSaltoOnlineDevices"]),
    },
    methods: {        
        setData() {
            this.salto = { ...this.getModalData.salto };
            this.devList = [];
            this.getAllDevices
                .filter((item) =>(
                    item.Device.SaltoInstallationId == this.salto.Id
                ))
                .map((item) => {
                    this.devList.push({
                            text: item.Device.Name,
                            id: item.Device.Id,
                        }) 
                })
            /* if(this.devList.length == 0){
                
            } */
        },
        deleteSalto() {
            let saltoInstallationId = this.salto.SaltoInstallationId;
            this.$store
                .dispatch(SALTO_INSTALLATION_DELETE, saltoInstallationId)
                .then(() => {
                    this.$store.dispatch(SALTO_INSTALLATION_GET);
                })
                .catch((error) => {});
        },
        onClose() {
            this.salto = null;
            this.$store.commit(CLEAR_MODAL_DATA);
        },
    },
};
</script>
<style scoped>
.btn.disabled, .btn:disabled{
    color:  #6e84a3;
    border-color: #6e84a3;
} 
</style>

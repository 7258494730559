<template>
    <b-modal
        :id="type"
        :title="$t('doors.door')"
        size="lg"
        hide-footer
        modal-class="fixed-right"
        dialog-class="modal-dialog-vertical"
        @show="setData"
        @hide="onClose"
    >
        <b-container fluid>
            <b-row>
                <b-col cols="12" style="padding-left: 54px !important;">
                    <b-form>
                        <!-- label-cols-sm="2"
                            label-align-sm="right"
                            label-size="sm" -->
                        <b-form-group
                            :label="$t('doors.name')"
                        >
                            <b-form-input
                                v-model="$v.device.Name.$model"
                                :state="validateState('Name')"
                                id="deviceName"
                                :placeholder="$t('doors.name')"
                            />
                            <b-form-invalid-feedback id="deviceName-feedback">
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                {{
                                    $t("errors.validation_length_range", {
                                        range: "3-30",
                                    })
                                }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group
                            :label="$t('companies.company')"
                        >
                            <b-input-group>
                                <!-- <b-form-select
                                    v-model="$v.device.CompanyId.$model"
                                    :state="validateState('CompanyId')"
                                    id="companyType"
                                    v-if="getCompaniesOptions.length > 15"
                                    :options="getCompaniesOptions"
                                    onfocus='this.size=10, this.height=2'
                                    onchange='this.size=10, this.height=2, this.blur();'
                                    onblur='this.size=10, this.height=2'
                                    onfocusout='this.size=null;'
                                ></b-form-select> -->
                                <b-form-select
                                    v-model="$v.device.CompanyId.$model"
                                    :state="validateState('CompanyId')"
                                    id="companyType"
                                    :options="getCompaniesOptions"
                                ></b-form-select>
                                <b-form-invalid-feedback
                                    id="companyType-feedback"
                                >
                                    <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{ $t("locations.company_select") }}
                                </b-form-invalid-feedback>
                            </b-input-group>
                        </b-form-group>
                        <b-form-group
                            :label="$t('locations.location')"
                        >
                            <b-input-group>
                                <!-- <b-form-select
                                    v-model="$v.device.LocationId.$model"
                                    :state="validateState('LocationId')"
                                    id="locationType"
                                    v-if="getLocationsOptions.length > 15"
                                    :options="getLocationsOptions"
                                    onfocus='this.size=10, this.height=2'
                                    onchange='this.size=10, this.height=2, this.blur();'
                                    onblur='this.size=10, this.height=2'
                                    onfocusout='this.size=null;'
                                ></b-form-select> -->
                                <b-form-select
                                    v-model="$v.device.LocationId.$model"
                                    :state="validateState('LocationId')"
                                    id="locationType"
                                    :options="getLocationsOptions"
                                ></b-form-select>
                                <b-form-invalid-feedback
                                    id="locationType-feedback"
                                >
                                    <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{ $t("menu.select_location") }}
                                </b-form-invalid-feedback>
                            </b-input-group>
                        </b-form-group>
                        <b-form-group
                            :label="$t('doors.type')"
                        >
                            <b-input-group>
                                <b-form-select
                                    v-model="$v.device.DoorType.$model"
                                    :state="validateState('DoorType')"
                                    id="deviceType"
                                    :options="deviceTypesOptions"
                                    :disabled="!createMode"
                                ></b-form-select>
                                <b-form-invalid-feedback
                                    id="deviceType-feedback"
                                >
                                    <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{ $t("doors.type_select") }}
                                </b-form-invalid-feedback>
                            </b-input-group>
                        </b-form-group>
                        <b-form-group
                            :label="$t('doors.provider')"
                        >
                            <b-input-group>
                                <b-form-select
                                    v-model="$v.device.ProviderType.$model"
                                    :state="validateState('ProviderType')"
                                    id="deviceProvider"
                                    :options="deviceProvidersOptions"
                                    :disabled="!createMode"
                                ></b-form-select>
                                <b-form-invalid-feedback
                                    id="deviceProvider-feedback"
                                >
                                    <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{
                                        $t("doors.provider_select")
                                    }}</b-form-invalid-feedback
                                >
                            </b-input-group>
                        </b-form-group>

                        <b-form-group  v-if="device.ProviderType == 1"
                                       :label="$t('doors.code_type')"
                        >
                            <b-input-group>
                                <b-form-select
                                    v-model="$v.device.DoorCodeType.$model"
                                    :state="validateState('DoorCodeType')"
                                    id="DoorCodeType"
                                    :options="doorpadCodeTypesOptions"
                                ></b-form-select>
                                <!--  $t("doors.code_type_select") -->
                                <b-form-invalid-feedback
                                    id="DoorCodeType-feedback"
                                >
                                    <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{
                                        $t("doors.code_type_select")
                                    }}</b-form-invalid-feedback
                                >
                            </b-input-group>
                        </b-form-group>
                        <b-form-group  v-if="device.ProviderType == 5"
                                       :label="$t('doors.saltoInstallation')"
                        >
                            <b-input-group>
                                <!-- :disabled="!createMode"  -->
                                <!-- <b-form-select
                                    v-model="$v.device.SaltoInstallationId.$model"
                                    :state="validateState('SaltoInstallationId')"
                                    id="saltoInstallation"
                                    v-if="saltoInstallationOptions.length > 15"
                                    :options="saltoInstallationOptions"
                                    onfocus='this.size=10, this.height=2'
                                    onchange='this.size=10, this.height=2, this.blur();'
                                    onblur='this.size=10, this.height=2'
                                    onfocusout='this.size=null;'
                                ></b-form-select> -->
                                <b-form-select
                                    v-model="$v.device.SaltoInstallationId.$model"
                                    :state="validateState('SaltoInstallationId')"
                                    id="saltoInstallation"
                                    :options="saltoInstallationOptions"
                                ></b-form-select>
                                <b-form-invalid-feedback
                                    id="saltoInstallation-feedback"
                                >
                                    <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{
                                        $t("doors.saltoInstallation_select")
                                    }}</b-form-invalid-feedback
                                >
                            </b-input-group>
                        </b-form-group>
                        <b-form-group  v-if="device.ProviderType == 5"
                                       :label="$t('doors.availableDevices')"
                        >
                            <b-input-group>
                                <!-- :disabled="!createMode" -->
                                <!-- <b-form-select
                                    v-model="$v.device.SaltoOnlineDeviceId.$model"
                                    :state="validateState('SaltoOnlineDeviceId')"
                                    id="availableDevices"
                                    v-if="availableDevicesOptions.length > 15"
                                    :options="availableDevicesOptions"
                                    onfocus='this.size=10, this.height=2'
                                    onchange='this.size=10, this.height=2, this.blur();'
                                    onblur='this.size=10, this.height=2'
                                    onfocusout='this.size=null;'
                                ></b-form-select> -->
                                <b-form-select
                                    v-model="$v.device.SaltoOnlineDeviceId.$model"
                                    :state="validateState('SaltoOnlineDeviceId')"
                                    id="availableDevices"
                                    :options="availableDevicesOptions"
                                ></b-form-select>
                                <b-form-invalid-feedback
                                    id="availableDevices-feedback"
                                >
                                    <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{
                                        $t("doors.availableDevices_select")
                                    }}</b-form-invalid-feedback
                                >
                            </b-input-group>
                        </b-form-group>
                        <b-form-group
                            v-if="device.ProviderType == 1 || device.ProviderType == 3 || device.ProviderType == 5 || device.ProviderType == 6"
                            :label="$t('doors.code')"
                        >
                            <b-form-input
                                v-model="$v.device.Code.$model"
                                :state="validateState('Code')"
                                id="Code"
                                :placeholder="$t('doors.placeholder')"
                            />
                            <!--  range: "2-10", -->
                            <b-form-invalid-feedback id="Code-feedback">
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                {{
                                    $t("errors.validation_length_number_range", {
                                        range: "4",
                                    })
                                }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group
                            v-if="device.ProviderType == 2"
                            :label="$t('doors.yale_sid')"
                        >
                            <b-input-group>
                                <b-form-input
                                    v-model="$v.device.YaleSid.$model"
                                    :state="validateState('YaleSid')"
                                    id="deviceYaleSid"
                                    :placeholder="$t('doors.yale_sid')"
                                />
                                <b-form-invalid-feedback id="deviceYaleSid-feedback">
                                    <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{ $t("errors.validation_required") }}
                                </b-form-invalid-feedback>
                            </b-input-group>
                        </b-form-group>
                        <b-form-group
                            v-if="device.ProviderType == 3"
                            :label="$t('doors.honest_box_lock_id')"
                        >
                            <!-- :disabled="!createMode" -->
                            <b-input-group>
                                <b-form-input
                                    v-model="$v.device.HonestBoxLockId.$model"
                                    :state="validateState('HonestBoxLockId')"
                                    id="deviceHonestBoxLockId"
                                    :placeholder="
                                        $t('doors.honest_box_lock_id')
                                    "
                                />
                                <b-form-invalid-feedback id="deviceHonestBoxLockId-feedback">
                                    <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{
                                        $t("errors.validation_length_number_range", {
                                            range: "1-10",
                                        })
                                    }}
                                </b-form-invalid-feedback>
                            </b-input-group>
                        </b-form-group>
                        <b-form-group
                            v-if="device.ProviderType == 6"
                            :label="$t('doors.inlet_online_lock_id')"
                        >
                            <!-- :disabled="!createMode" -->
                            <b-input-group>
                                <b-form-input
                                    v-model.number="$v.device.InletOnlineLockId.$model"
                                    :state="validateState('InletOnlineLockId')"
                                    id="deviceInletOnlineLockId"
                                    :placeholder="
                                        $t('doors.inlet_online_lock_id')
                                    "
                                />
                                <b-form-invalid-feedback id="deviceInletOnlineLockId-feedback">
                                    <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{
                                        $t("errors.validation_length_number_range", {
                                            range: "1-10",
                                        })
                                    }}
                                </b-form-invalid-feedback>
                            </b-input-group>
                        </b-form-group>
                        <b-form-group
                            v-if="device.ProviderType == 4"
                        >
                        </b-form-group>

                        <b-form-group
                            v-if="device.ProviderType == 1"
                            :label="$t('doors.code_instruction')"
                        >
                            <b-form-input
                                v-model="$v.device.DoorCodeDescription.$model"
                                :state="validateState('DoorCodeDescription')"
                                id="DoorCodeDescription"
                                :placeholder="$t('doors.code_instruction')"
                            />
                            <b-form-invalid-feedback id="DoorCodeDescription-feedback">
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                {{
                                    $t("errors.validation_max_length", {
                                        length: "30",
                                    })
                                }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group
                            v-if="
                                !ownerDanalockConnected &&
                                device.ProviderType == 0
                            "
                        ><b-alert show variant="warning">{{
                                $t("providers.danalock_owner_warning")
                            }}</b-alert></b-form-group
                        >

                        <!-- @click.prevent.stop="postDevice" -->
                        <b-button
                            class="input-field"
                            @click.prevent.stop="postDevice"
                            v-if="createMode"
                        >{{ $t("doors.create") }}</b-button
                        >
                        <b-button
                            class="input-field"
                            @click.prevent.stop="editDevice"
                            v-else
                        >{{ $t("doors.update") }}</b-button
                        >
                    </b-form>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { deviceProviders, doorTypes, doorpadCodeTypes} from "@/handlers/enums";
import { DEVICE_CREATE, DEVICE_PATCH, DEVICE_CODE_PATCH, DEVICE_GET, DEVICE_CODE_GET} from "@/store/types/devices";
import Select2 from "@/components/common/Select2";
import {
    SALTO_INSTALLATION_GET,
    SALTO_ONLINE_DEVICE_GET,
    SALTO_INSTALLATION_BY_ID_GET,
    SALTO_ONLINE_DEVICE_BY_ID_GET,
    SALTO_ONLINE_DEVICE_CREATE,
    SALTO_ERROR } from "@/store/types/salto";
import {
    ADMIN_DEVICE_CREATE,
    ADMIN_DEVICE_PATCH,
    ADMIN_DEVICE_CODE_PATCH,
    ADMIN_DEVICES_GET,
} from "@/store/types/admin";
import { M_DEVICE_ADMIN_FORM } from "@/components/modals/types";
import { CLEAR_MODAL_DATA } from "@/store/types/global";
import { CHECK_DANALOCK } from "@/store/types/providers";
import { validationMixin } from "vuelidate";
import {
    required,
    minLength,
    maxLength,
    requiredIf,
    numeric,
} from "vuelidate/lib/validators";

export default {
    mixins: [validationMixin],
    data() {
        return {
            type: M_DEVICE_ADMIN_FORM,
            createMode: true,
            device: {},
            submitted: false,
            ownerDanalockConnected: false,
            salto: [],
            filter: "",
            filterLocation: "",
        };
    },
    validations: {
        device: {
            Name: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(30),
            },
            CompanyId: {
                required,
            },
            LocationId: {
                required,
            },
            DoorType: {
                required,
            },
            ProviderType: {
                required,
            },
            SaltoInstallationId: {
                required: requiredIf(function () {
                    return this.device.ProviderType == 5;
                }),
            },
            SaltoOnlineDeviceId: {
                required: requiredIf(function () {
                    return this.device.ProviderType == 5;
                }),
            },
            DoorCodeType: {
                required: requiredIf(function () {
                    return ( this.device.ProviderType == 1);
                }),
            },
            YaleSid: {
                required: requiredIf(function () {
                    return this.device.ProviderType == 2;
                }),
            },
            HonestBoxLockId: {
                required: requiredIf(function () {
                    return this.device.ProviderType == 3;
                }),
                numeric,
                minLength: minLength(1),
                maxLength: maxLength(10),
            },
            InletOnlineLockId: {
                required: requiredIf(function () {
                    return this.device.ProviderType == 6;
                }),
                numeric,
                minLength: minLength(1),
                maxLength: maxLength(10),
            },
            Code: {
                required: requiredIf(function () {
                    return this.device.ProviderType == 1 || this.device.ProviderType == 3 || this.device.ProviderType == 5 || this.device.ProviderType == 6;
                }),
                numeric,
                minLength: minLength(4),
                maxLength: maxLength(4),
            },
            DoorCodeDescription: {
                maxLength: maxLength(30),
            },
        },
    },
    computed: {
        ...mapGetters(["getModalData", "getModalType", "getAllAdminCompanies", "getAllLocations", "getCompanyLocations", "getActiveCompany", "getSaltoInstallation", "getSaltoOnlineDevices"]),
        deviceTemplate() {
            return {
                Name: "",
                DoorType: null,
                ProviderType: null,
                CompanyId: null,
                LocationId: null,
                DoorCodeType: null,
                Code: null,
                DoorCodeDescription: null,
                YaleSid: null,
                HonestBoxLockId: null,
                InletOnlineLockId: null,
                SaltoInstallationId: null,
                SaltoOnlineDeviceId: null,
            };
        },
        deviceProvidersOptions() {
            return [
                {
                    value: null,
                    text: this.$t("doors.provider_select"),
                    disabled: true,
                },
                ...deviceProviders
                    .filter((item) => item.enabled == 1)
                    .map((item) => {
                        return { value: item.value, text: item.key };
                    }),
            ];
        },
        getCompaniesOptions() {
            return [
                {
                    value: null,
                    text: this.$t("locations.company_select"),
                    disabled: true,
                },
                ...this.getAllAdminCompanies
                    .filter((item) =>
                        item.Name
                            .toLowerCase()
                            .includes(this.filter.toLowerCase())
                    )
                    .sort((i1, i2) => {
                        return i1.Name.toUpperCase() > i2.Name.toUpperCase() ? 1 : -1;
                    })
                    .map((item) => {
                        return {
                            value: item.Id,
                            text: item.Name,
                        };
                    }),
            ];
        },
        getLocationsOptions(){
            return [
                {
                    value: null,
                    text: this.$t("menu.select_location"),
                    disabled: true,
                },
                ...this.getAllLocations
                    .filter((item) =>{
                        let filterLoc = true;
                        if(this.device.CompanyId != null){
                            filterLoc = item.Location.CompanyId == this.device.CompanyId
                        }else{
                            filterLoc = item.Location.Name.toLowerCase().includes(this.filterLocation.toLowerCase()) ||
                                item.Location.Address.toLowerCase().includes(this.filterLocation.toLowerCase())
                        }
                        return filterLoc;
                    })
                    .sort((i1, i2) => {
                        return i1.Location.Name.toUpperCase() > i2.Location.Name.toUpperCase() ? 1 : -1;
                    })
                    .map((item) => {
                        return { value: item.Location.Id, text: item.Location.Name };
                    }),
            ];
        },
        saltoInstallationOptions(){
            return [
                {
                    value: null,
                    text: this.$t("doors.saltoInstallation_select"),
                    disabled: true,
                },
                ...this.getSaltoInstallation
                    // .filter((item) => (item.CompanyId == this.device.CompanyId) && (item.LocationId == this.device.LocationId))
                    .filter((item)=>{
                        let filterSaltoDevice = true;
                        if(this.device.SaltoInstallationId != null){
                            filterSaltoDevice = item.Id == this.device.SaltoInstallationId
                        }else{
                            filterSaltoDevice = (item.CompanyId == this.device.CompanyId) && (item.LocationId == this.device.LocationId)
                        }
                        return filterSaltoDevice;
                    })
                    .sort((i1, i2) => {
                        return i1.Name.toUpperCase() > i2.Name.toUpperCase() ? 1 : -1;
                    })
                    .map((item) => {
                        return { value: item.Id, text: item.Name };
                    }),
            ];
        },
        availableDevicesOptions(){
            return [
                {
                    value: null,
                    text: this.$t("doors.availableDevices_select"),
                    disabled: true,
                },
                ...this.getSaltoOnlineDevices
                    .filter((item)=>{
                        let filterOnlineDevice = true;
                        if(this.device.SaltoInstallationId != null){
                            filterOnlineDevice = item.SaltoInstallationId == this.device.SaltoInstallationId
                        }else{
                            return
                            // filterOnlineDevice = item.Name.toLowerCase().includes(this.filter.toLowerCase())
                        }
                        return filterOnlineDevice;
                    })
                    .sort((i1, i2) => {
                        return i1.Name.toUpperCase() > i2.Name.toUpperCase() ? 1 : -1;
                    })
                    .map((item) => {
                        return {
                            value: item.Id,
                            text: item.Name,
                        };
                    }),
            ];
        },
        deviceTypesOptions() {
            return [
                {
                    value: null,
                    text: this.$t("doors.type_select"),
                    disabled: true,
                },
                ...doorTypes.map((item) => {
                    return { value: item.value, text: this.$t(item.key) };
                }),
            ];
        },
        doorpadCodeTypesOptions() {
            return [
                {
                    value: null,
                    text: this.$t("doors.code_type_select"),
                    disabled: true,
                },
                ...doorpadCodeTypes.map((item) => {
                    return { value: item.key, text: this.$t(item.key) };
                }),
            ];
        },
    },
    created() {
        let saltoList = this.$store.dispatch(SALTO_INSTALLATION_GET);
        let saltoOnlineDevice = this.$store.dispatch(SALTO_ONLINE_DEVICE_GET);
    },
    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.device[name];
            return $dirty && this.submitted ? !$error : null;
        },
        initCompany(company){
            this.device["CompanyId"] = company.id;
        },
        initLocation(location){
            this.device["LocationId"] = location.id;
        },
        postDevice: function () {
            this.submitted = true;
            this.$v.device.$touch();

            if (this.$v.device.$anyError) {
                return;
            }

            let device = { ...this.device };
            if (device.ProviderType != 1 ) {
                delete device.DoorCodeType;
            }
            if (device.ProviderType != 2) {
                delete device.YaleSid;
            }
            if (device.ProviderType != 3) {
                delete device.HonestBoxLockId;
            }
            if (device.ProviderType != 6) {
                delete device.InletOnlineLockId;
            }
            if (device.ProviderType == 2) {
                device["YaleZone"] = 0;
                device["YaleArea"] = 1;
                device["YaleType"] = "device_type.door_lock";
            }
            if(device.ProviderType != 5){
                delete device.SaltoInstallationId;
                delete device.SaltoOnlineDeviceId;
            }
            if(device.ProviderType == 5){
                //device.SaltoInstallationId = this.device.SaltoInstallationId;
            }

            const doorCodeVal = device.Code;
            this.$store
                .dispatch(ADMIN_DEVICE_CREATE, device)
                .then((response) => {
                    if (device.ProviderType == 1 || device.ProviderType == 3 || device.ProviderType == 5 || device.ProviderType == 6) {
                        this.$store
                            .dispatch(DEVICE_CODE_PATCH, {
                                deviceId: response.Id,
                                doorcode: doorCodeVal,
                            });
                    }
                    this.$bvModal.hide(this.type);
                })
                .catch((error) => {});
        },

        editDevice(response) {
            this.submitted = true;
            this.$v.device.$touch();
            if (this.$v.device.$anyError) {
                return;
            }

            let device = { ...this.getModalData.device };
            if (device.ProviderType == 0 ) {
                delete device.DoorCodeType;
            }
            if (device.ProviderType != 2) {
                delete device.YaleSid;
            }
            if (device.ProviderType == 3) {
                delete device.HonestBoxLockId;
            }
            if (device.ProviderType == 6) {
                delete device.InletOnlineLockId;
            }
            if (device.ProviderType == 4) {
                delete device.DoorCodeType;
            }
            if(device.ProviderType != 5){
                delete device.SaltoInstallationId;
                delete device.SaltoOnlineDeviceId;
            }

            if(device.ProviderType == 5){
                //this.device.SaltoInstallationId = this.device.SaltoInstallationId;
            }

            this.$store
                .dispatch(ADMIN_DEVICE_PATCH, {...this.device,
                    ...{ ImageId: response.Id },})
                .then(() => {
                    if (device.ProviderType == 1 || device.ProviderType == 3 || device.ProviderType == 5 || device.ProviderType == 6) {
                        let doorCodeVal = document.getElementById('Code').value;
                        this.$store
                            .dispatch(DEVICE_CODE_PATCH, {
                                deviceId: this.device.Id,
                                doorcode: doorCodeVal,
                            });
                    }
                    this.$store.dispatch(ADMIN_DEVICES_GET, this.device.Id);
                    this.$bvModal.hide(this.type);
                })
                .catch((error) => {});
        },
        setData() {
            if (this.getModalData !== null) {
                this.device = { ...this.getModalData.device };
                let respDoorCode;
                if(this.device.ProviderType == 1 || this.device.ProviderType == 3 || this.device.ProviderType == 5 || this.device.ProviderType == 6){
                    this.$store.dispatch(DEVICE_CODE_GET, this.device.Id).then((response) => {
                        respDoorCode = response.doorcode;
                        if(respDoorCode === "undefined" || respDoorCode === null){
                            respDoorCode = "";
                        }
                        document.getElementById('Code').value = respDoorCode;
                        this.device["Code"] = respDoorCode;
                    })
                        .catch((error) => {});
                }
                if(this.device.ProviderType == 5){
                    this.getSaltoInstallation
                        .filter((item) => item.Id == this.device.SaltoInstallationId)
                        .sort((i1, i2) => {
                            return i1.Name.toUpperCase() > i2.Name.toUpperCase() ? 1 : -1;
                        })
                        .map((item) => {
                            this.device.SaltoInstallationId =  item.Id;
                        })
                }
                this.createMode = false;
            } else {
                this.device = { ...this.deviceTemplate };
                this.createMode = true;
            }
            if (this.getActiveCompany) {
                this.$store
                    .dispatch(CHECK_DANALOCK, this.getActiveCompany.OwnerId)
                    .then((response) => {
                        this.ownerDanalockConnected = response;
                    });
            }

            this.$v.device.$reset();
        },
        onShow() {
            this.setData();
        },
        onClose() {
            this.submitted = false;
            this.$v.device.$reset();
            this.createMode = true;
            this.companyIdVal = null;
            this.locationIdVal = null;
            this.device = { ...this.deviceTemplate };
            this.$store.commit(CLEAR_MODAL_DATA);
        },
    },
    components: {
    },
};
</script>
<style scoped>
.alert-warning {
    color: #856404 !important;
    background-color: #fff3cd !important;
    border-color: #ffeeba !important;
}
input:disabled {
    color: #95aac9;
    background-color: #edf2f9;
}
.select2-search__field {
    width: 100% !important;
    padding: 0.125rem 2.5rem !important;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35' height='16' opacity='0.5' class='bi bi-search' style='margin-left: 2px' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat left;
}
li.select2-search--inline {
    width: 100% !important;
}
</style>

<template>
    <b-list-group-item>
        <b-row class="align-items-center" style="height: 100px;">
            <b-col class="col-auto">
                <!-- Avatar -->
                <router-link
                    :to="{
                        name: 'location',
                        params: {
                            locationId: location.Location.Id,
                            companyId: location.Location.CompanyId,
                        },
                    }"
                    class="avatar avatar-lg"
                >
                    <img
                        :src="
                            location.Location.SmallImageUrl ||
                            '/images/zesec-placeholder.png'
                        "
                        :alt="location.Location.Name"
                        class="avatar-img rounded"
                    />
                </router-link>
            </b-col>
            <div class="col ml-n2">
                <!-- Title -->
                <h4 class="mb-1 name">
                    <router-link
                        :to="{
                            name: 'location',
                            params: {
                                locationId: location.Location.Id,
                                companyId: location.Location.CompanyId,
                            },
                        }"
                        >{{ location.Location.Name }}</router-link
                    >
                </h4>

                <!-- Text -->
                <p class="card-text small text-muted mb-1">
                    {{
                        $t("common.count_doors", {
                            count: location.Location.DevicesCount,
                        })
                    }}
                </p>
                <p class="card-text small text-muted mb-1" style="line-height: 0.5;">
                    {{
                        $t("common.count_users", {
                            count: location.Location.UsersCount,
                        })
                    }}
                </p>
                <!-- Time -->
                <p class="card-text small text-muted">
                    {{ location.Location.Address }}
                </p>
            </div>

            <div class="col-auto">
                <!-- Dropdown -->
                <b-dropdown
                    variant="outline"
                    toggle-class="dropdown-ellipses dropdown-toggle"
                    no-caret
                    right
                    toggle-tag="a"
                >
                    <template v-slot:button-content>
                        <!-- <i class="fe fe-more-vertical"></i> -->
                        <img :src="'/images/settings_grey_32x32dp.png'" style="width: 19px; height: 19px;"/>
                    </template>
                    <b-dropdown-item
                        class="text-left text-truncate"
                        @click="editLocation(location)"
                    >
                        {{ $t("buttons.edit") }}
                    </b-dropdown-item>
                    <b-dropdown-item
                        class="text-left text-truncate"
                        @click="deleteLocation(location)"
                    >
                        {{ $t("buttons.delete") }}
                    </b-dropdown-item>
                    <b-dropdown-item
                        class="text-left text-truncate"
                        @click="locationImage(location)"
                    >
                        {{ $t("locations.image") }}
                    </b-dropdown-item>
                </b-dropdown>
            </div>
        </b-row>
    </b-list-group-item>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
import { M_LOCATION_FORM_ADMIN, M_LOCATION_ADMIN_DELETE, M_IMAGE_UPLOAD } from "@/components/modals/types";
import { IMAGE_TYPE_LOCATION } from "@/handlers/const";
import { mapGetters } from "vuex";
import {
    ADMIN_LOCATION_PATCH,
    ADMIN_LOCATIONS_GET,
} from "@/store/types/admin";

export default {
    name: "location-list-item-admin",
    mixins: [modalMixin],
    props: ["location"],
    computed: {
        ...mapGetters([            
            "getActiveLocation",
        ]),
    },
    methods: {
        editLocation(location) {
            this.showModal({ location: location.Location }, M_LOCATION_FORM_ADMIN);
        },        
        deleteLocation(location) {
            if(location.Location.Name){
                this.showModal({ location: location.Location }, M_LOCATION_ADMIN_DELETE);
            }
        },
        locationImage(location) {
           // if(this.location.Location.Id == locID){
            this.$session.set("location",this.location)
                this.showModal(
                    {
                        image: {
                            url:
                                this.location.Location.MediumImageUrl ||
                                "/images/zesec-placeholder.png",
                            type: IMAGE_TYPE_LOCATION,
                        },
                    },
                    M_IMAGE_UPLOAD
                ); 
            // }
        },
        updateLocationImage(response) {
            this.location = this.$session.get("location"); 
            this.$store
                .dispatch(ADMIN_LOCATION_PATCH, {
                    ...this.location,
                    ...{ ImageId: response.Id },
                })
                .then(() => {
                    this.$store.dispatch(ADMIN_LOCATIONS_GET); 
                })
                .catch((error) => {});
        },
    },
};
</script>
<style scoped>
.mw-48 {
    min-width: 48px;
}

.list-group-item:last-child{
  overflow-y: auto !important;
}
.btn:focus{
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
}
</style>

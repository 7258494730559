<template>
    <b-modal
        :id="type"
        :title="$t('modals.title_delete_door')"
        :ok-title="$t('buttons.delete')"
        :cancel-title="$t('buttons.cancel')"
        ok-variant="outline-danger"
        modal-class="fixed-right"
        dialog-class="modal-dialog-vertical"
        @ok="deleteDevice"
        @show="setData"
        @hide="onClose"
    >
        <b-container fluid v-if="type == getModalType">
            <b-row>
                <b-col cols="12">
                    {{
                        $t("modals.text_delete_door", {
                            doorName: getModalData.device.Name,
                        })
                    }}
                    <span class="bold-font-style">{{
                        $t("modals.are_you_sure")
                    }}</span>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import {
    ADMIN_DEVICE_DELETE,
    ADMIN_DEVICES_GET
} from "@/store/types/admin";
import { M_DEVICE_ADMIN_DELETE } from "@/components/modals/types";
import { CLEAR_MODAL_DATA } from "@/store/types/global";

export default {
    data() {
        return {
            type: M_DEVICE_ADMIN_DELETE,
            device: {},
        };
    },
    computed: {
        ...mapGetters(["getModalData", "getModalType", "getActiveLocation"]),
    },

    methods: {
        setData() {
            this.device = { ...this.getModalData.device };
        },
        deleteDevice() {
            let locationId = this.device.LocationId;
            this.$store
                .dispatch(ADMIN_DEVICE_DELETE, this.device)
                .then(() => {                    
                    this.$store.dispatch(ADMIN_DEVICES_GET);
                })
                .catch((error) => {});
        },
        onClose() {
            this.device = null;
            this.$store.commit(CLEAR_MODAL_DATA);
        },
    },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isMobile)?_c('b-col',{attrs:{"cols":"12","md":"4","xl":"3"}},[_c('div',{staticClass:"card"},[_c('router-link',{staticClass:"door-image-link",attrs:{"to":{
                name: 'door',
                params: {
                    locationId: _vm.device.Device.LocationId,
                    doorId: _vm.device.Device.Id,
                    companyId: _vm.device.Device.CompanyId,
                },
            }}},[_c('img',{staticClass:"card-img-top",attrs:{"src":_vm.device.Device.MediumImageUrl
                        ? _vm.device.Device.MediumImageUrl
                        : '/images/zesec-placeholder.png'}})]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col"},[_c('h4',{staticClass:"mb-2 name text-truncate"},[_c('router-link',{attrs:{"to":{
                                name: 'door',
                                params: {
                                    locationId: _vm.device.Device.LocationId,
                                    doorId: _vm.device.Device.Id,
                                    companyId: _vm.device.Device.CompanyId,
                                },
                            }}},[_vm._v(_vm._s(_vm.deviceName(_vm.device.Device.Name)))])],1),_c('p',{staticClass:"card-text small text-muted"},[_vm._v(" "+_vm._s(_vm.getDeviceProvider(_vm.device.Device.ProviderType) .key)+" ")])]),_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"row align-items-center no-gutters"},[_c('b-dropdown',{attrs:{"variant":"outline","toggle-class":"dropdown-ellipses dropdown-toggle","no-caret":"","right":"","toggle-tag":"a"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('img',{staticStyle:{"width":"19px","height":"19px"},attrs:{"src":'/images/settings_grey_32x32dp.png'}})]},proxy:true}],null,false,2784308510)},[_c('b-dropdown-item',{staticClass:"text-left text-truncate",on:{"click":function($event){return _vm.editDevice(_vm.device)}}},[_vm._v(_vm._s(_vm.$t("buttons.edit")))]),_c('b-dropdown-item',{staticClass:"text-left text-truncate",on:{"click":function($event){return _vm.deleteDevice(_vm.device)}}},[_vm._v(_vm._s(_vm.$t("buttons.delete")))]),_c('b-dropdown-item',{staticClass:"text-left text-truncate",on:{"click":function($event){return _vm.deviceImage(_vm.device)}}},[_vm._v(_vm._s(_vm.$t("doors.image")))]),(_vm.device.Device.ProviderType == 3 || _vm.device.Device.ProviderType == 5 || _vm.device.Device.ProviderType == 6)?_c('b-dropdown-item',{staticClass:"text-left text-truncate",on:{"click":function($event){return _vm.deviceQR(_vm.device)}}},[_vm._v(_vm._s(_vm.$t("doors.generate_qr")))]):_vm._e()],1)],1)])])]),_c('div',{staticClass:"card-footer card-footer-boxed"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"row align-items-center no-gutters"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("common.count_keys", { count: _vm.device.Keys ? _vm.device.Keys.length : _vm.device.KeysCount, }))+" ")])])])])])],1)]):_c('b-col',{attrs:{"cols":"12","md":"4","xl":"3"}},[_c('div',{staticClass:"card"},[_c('router-link',{staticClass:"door-image-link",attrs:{"to":{
                name: 'door',
                params: {
                    locationId: _vm.device.Device.LocationId,
                    doorId: _vm.device.Device.Id,
                    companyId: _vm.device.Device.CompanyId,
                },
            }}},[_c('img',{staticClass:"card-img-top",attrs:{"src":_vm.device.Device.MediumImageUrl
                        ? _vm.device.Device.MediumImageUrl
                        : '/images/zesec-placeholder.png'}})]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-lg-6"},[_c('h4',{staticClass:"mb-2 name text-truncate"},[_c('router-link',{attrs:{"to":{
                                name: 'door',
                                params: {
                                    locationId: _vm.device.Device.LocationId,
                                    doorId: _vm.device.Device.Id,
                                    companyId: _vm.device.Device.CompanyId,
                                },
                            }}},[_vm._v(_vm._s(_vm.deviceName(_vm.device.Device.Name)))])],1),_c('p',{staticClass:"card-text small text-muted"},[_vm._v(" "+_vm._s(_vm.getDeviceProvider(_vm.device.Device.ProviderType) .key)+" ")])]),_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"row align-items-center no-gutters"},[_c('b-dropdown',{attrs:{"variant":"outline","toggle-class":"dropdown-ellipses dropdown-toggle","no-caret":"","right":"","toggle-tag":"a"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('img',{staticStyle:{"width":"19px","height":"19px"},attrs:{"src":'/images/settings_grey_32x32dp.png'}})]},proxy:true}])},[_c('b-dropdown-item',{staticClass:"text-left text-truncate",on:{"click":function($event){return _vm.editDevice(_vm.device)}}},[_vm._v(_vm._s(_vm.$t("buttons.edit")))]),_c('b-dropdown-item',{staticClass:"text-left text-truncate",on:{"click":function($event){return _vm.deleteDevice(_vm.device)}}},[_vm._v(_vm._s(_vm.$t("buttons.delete")))]),_c('b-dropdown-item',{staticClass:"text-left text-truncate",on:{"click":function($event){return _vm.deviceImage(_vm.device)}}},[_vm._v(_vm._s(_vm.$t("doors.image")))]),(_vm.device.Device.ProviderType == 3 || _vm.device.Device.ProviderType == 5 || _vm.device.Device.ProviderType == 6)?_c('b-dropdown-item',{staticClass:"text-left text-truncate",on:{"click":function($event){return _vm.deviceQR(_vm.device)}}},[_vm._v(_vm._s(_vm.$t("doors.generate_qr")))]):_vm._e()],1)],1)])])]),_c('div',{staticClass:"card-footer card-footer-boxed"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"row align-items-center no-gutters"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("common.count_keys", { count: _vm.device.Keys ? _vm.device.Keys.length : _vm.device.KeysCount, }))+" ")])])])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
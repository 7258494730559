<template>
    <b-modal
        :id="type"
        :title="$t('staff.transfer_rights')"
        :ok-title="$t('staff.transfer_rights')"
        :cancel-title="$t('buttons.cancel')"
        modal-class="fixed-right"
        dialog-class="modal-dialog-vertical"
        @ok="transferRights"
        @show="setData"
        @hide="onClose"    
        :ok-disabled="!validPhone"    
    >
    <!-- <b-modal
        :id="type"
        :title="$t('staff.transfer_rights')"
        modal-class="fixed-right"
        dialog-class="modal-dialog-vertical"
        @show="setData"
        @hide="onClose"   
        hide-footer
    > -->
        <b-container fluid v-if="type == getModalType">
            <b-row>
                <b-col id="company" cols="12" style="padding-left: 54px !important;">
                    <b-form>
                       <!--  label-cols-sm="2"
                            label-align-sm="right"
                            label-size="sm" -->
                        <b-form-group :label="$t('companies.company')">
                            <b-form-input
                                v-model="$v.company.Name.$model"
                                :state="validateState('Name')"
                                id="companyName"
                                :disabled="disabled"
                                :placeholder="this.getModalData.user.Company.Name"
                                style="color: #95aac9; background-color: #edf2f9;"
                            />
                        </b-form-group>
                                             
                        <b-form-group :label="$t('staff.role_invite')">
                            <b-form-input
                                id="Role"
                                :placeholder="$t('permissions.role_owner')"
                                :disabled="disabled"
                                style="color: #95aac9; background-color: #edf2f9;"
                            />                            
                        </b-form-group>
                        <b-form-group :label="$t('staff.current_owner')">                            
                            <b-form-input
                                v-model="$v.company.OwnerOldName.$model"
                                :state="validateState('OwnerOldName')"
                                id="ownerName"
                                :disabled="disabled"
                                style="color: #95aac9; background-color: #edf2f9;"
                                :placeholder="this.getModalData.user.User.Name"
                            />                            
                        </b-form-group>
                        <b-form-group :label="$t('companies.owner_phone_number')">
                            <vue-phone-number-input
                                v-model="$v.company.OwnerPhoneNumber.$model"             
                                :translations="$t('phoneInput')"
                                id="ownerPhone"                                
                                :disabled="disabled"
                                :default-country-code="company.countryCode"
                                type="text"
                            />
                        </b-form-group>
                        <hr>
                        <b-form-group :label="$t('staff.role_invite')">
                            <b-form-input
                                id="roleNew"
                                :placeholder="$t('permissions.role_owner')"
                                :disabled="disabled"
                                style="color: #95aac9; background-color: #edf2f9;"
                            />                            
                        </b-form-group>
                        <b-form-group :label="$t('staff.new_current_owner')">                            
                            <b-form-input
                                v-model="$v.company.OwnerName.$model"
                                :state="validateState('OwnerName')"
                                id="newName"
                                :placeholder="$t('staff.new_current_owner')"
                            />
                            <b-form-invalid-feedback id="newName-feedback">
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                {{
                                    $t("errors.validation_length_range", {
                                        range: "3-50",
                                    })
                                }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group :label="$t('companies.owner_phone_number')">
                            <vue-phone-number-input
                                v-model="$v.company.PhoneNumber.$model"
                                :translations="$t('phoneInput')"
                                id="newOwnerPhone"
                                :error="!validPhone && submitted"
                                @update="validatePhone"
                                required
                                fetch-country
                                type="text"
                            />
                            <span id="newOwnerPhone-feedback" v-if="!validPhone && submitted"  style="color: #e63857;  font-size: 0.8125rem">
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                {{ $t("errors.validation_phone_number") }}
                            </span>
                        </b-form-group>
                        <b-form-group>                            
                            <b-alert show variant="warning">
                                <table style="border-collapse: separate; border-spacing: 0 0">
                                    <tr>
                                        <td style="padding-right: 14px; vertical-align: top">
                                            <img :src="'/images/Group_5.png'" style="width: 19px; height: 19px;"/>
                                        </td>
                                        <td style="text-align: left !important; padding-right: 30px;">
                                            {{ $t("alerts.owner_rights") }}
                                        </td>
                                    </tr>
                                </table>
                            </b-alert>
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <!-- <b-row>                
                            <b-col cols="12" style="padding-left: 54px !important;">
                            <b-button
                                class="input-field"
                                @click.prevent.stop="cancelMethod"
                                >{{ $t("buttons.cancel") }}</b-button
                            >
                            <b-button
                                class="input-field"
                                @click.prevent.stop="transferRights"
                                style="margin-left: 7px;"
                                :disabled="!validPhone"
                                >{{ $t('staff.transfer_rights') }}</b-button
                            >
                            </b-col>                
            </b-row> -->
        </b-container>
    </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { CLEAR_MODAL_DATA } from "@/store/types/global";
import { M_CHANGE_OWNER } from "@/components/modals/types";
import { validationMixin } from "vuelidate";
import VuePhoneNumberInput from "vue-phone-number-input";
import {
    required,
    minLength,
    maxLength,
} from "vuelidate/lib/validators";
import { ADMIN_TRANSFER_RIGHTS,ADMIN_STAFF_GET } from "@/store/types/admin";

import { parsePhoneNumberFromString } from "libphonenumber-js";

export default {
    mixins: [validationMixin],
    data() {
        return {
            type: M_CHANGE_OWNER,
            validNewPhone: false,
            fullNewPhone: null,
            validPhone: false,
            fullPhone: null,
            // createMode: true,
            company: {},
            submitted: false,
            disabled: true,
        };
    },
    validations: {
        company: {
            Name: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(50),
            },
            OwnerOldName: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(50),
            },
            OwnerName: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(50),
            },
            OwnerPhoneNumber: {
                required,
                newPhone: function () {
                    return this.validNewPhone;
                },
            },
            PhoneNumber: {
                required,
                phone: function () {
                    return this.validPhone;
                },
            },
        },
    },
    computed: {
        ...mapGetters(["getModalData", "getModalType"]),
        companyTemplate() {
            return {  
                Name: "",   
                OwnerOldName: "",           
                OwnerName: "",
                OwnerPhoneNumber: "",
                PhoneNumber: "",
            };
        },       
    },
    created() {
        this.setData();
    },
    methods: {        
        validateState(name) {        
            const { $dirty, $error } = this.$v.company[name];            
            return $dirty && this.submitted ? !$error : null;
        },        
        validatePhone(phone) {
            this.validPhone = phone.isValid;
            this.fullPhone = phone;
        }, 
        validateNewPhone(newPhone) {
            this.validNewPhone = newPhone.isValid;
            this.fullNewPhone = newPhone;
        },    
        /* cancelMethod: function (){
            this.$bvModal.hide(this.type);
        }, */
        transferRights() {      
            this.submitted = true;
            this.$v.company.$touch();
         /*    if (this.$v.company.$anyError) {
                return;
            }    */         
            
            let payload = {
                ...this.company,
                ...{ OwnerPhoneNumber: this.getModalData.user.User.PhoneNumber },
                ...{ PhoneNumber: this.fullPhone.formattedNumber },  
                ...{ companyId: this.getModalData.user.Company.Id },           
            };
            
            this.$store
                .dispatch(ADMIN_TRANSFER_RIGHTS, payload)
                .then(() => {
                    this.$bvModal.hide(this.type);
                    this.$store.dispatch(ADMIN_STAFF_GET);
                })
                .catch((error) => {});
        },        
        setData() { 
            if (this.getModalData !== null) {
                this.company = {
                    Name: this.getModalData.user.Company.Name,   
                    OwnerOldName: this.getModalData.user.User.Name,           
                    OwnerName: "",
                    OwnerPhoneNumber: this.getModalData.user.User.PhoneNumber,
                    PhoneNumber: "",
                }
                this.company.countryCode = parsePhoneNumberFromString(
                    this.getModalData.user.User.PhoneNumber
                ).country;                
                this.company.OwnerPhoneNumber = parsePhoneNumberFromString(
                    this.getModalData.user.User.PhoneNumber
                ).nationalNumber;
            }
          
            this.$v.company.$reset();
        },
        onClose() {
            this.$v.company.$reset();
            // this.createMode = true;
            this.company = { ...this.companyTemplate };
            this.$store.commit(CLEAR_MODAL_DATA);
            this.submitted = false;
        },
    },
    components: {
        "vue-phone-number-input": VuePhoneNumberInput,
    },
};
</script>

<style>
#card-title {
    font-size: 1em;
}
input:disabled {
    color: #95aac9;
    background-color: #edf2f9;
}
.select2-search__field {
    width: 100% !important;
    padding: 0.125rem 2.5rem !important;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35' height='16' opacity='0.5' class='bi bi-search' style='margin-left: 2px' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat left;
}
li.select2-search--inline {
    width: 100% !important;
}
.alert-warning {
    color: #856404 !important;
    background-color: #fff3cd !important;
    border-color: #ffeeba !important;
}
/* .select2-results__option:first-child{
    background-color: #e3ebf6;
    width: auto;
} */
</style>

<template>
    <b-list-group-item>
        <div class="row align-items-center">
            <div class="col-auto">
                <!-- Avatar -->
                <router-link
                    v-if="(employee.Location) && (employee.Company != null)"
                    class="avatar avatar-lg"
                    :to="{
                        name: 'profile',
                        params: {
                            locationId: employee.Location.Id,
                            companyId: employee.Company.Id,
                            userId: employee.User.Id,
                        },
                    }"
                >
                    <img
                        v-if="employee.User.SmallImageUrl"
                        :src="employee.User.SmallImageUrl"
                        alt="..."
                        class="avatar-img rounded-circle"
                    />
                    <span class="avatar-title rounded-circle" v-else>
                        <!-- <i class="fi flaticon-user" style="margin-left: 2px !important;"></i> style="width: 32px; height: 32px; margin-left: 2px !important;" -->
                        <img :src="'/images/person-big_MyZesec_140dp.png'" style="width: 67px; height: 67px;"/>
                    </span>
                </router-link>
                <!-- <div class="avatar avatar-lg" v-else> -->
                <router-link
                    class="avatar avatar-lg"
                    v-else-if="(employee.Location == null) && (employee.Company != null)"
                    :to="{
                        name: 'profile-owner',
                        params: {
                            // locationId: null,
                            companyId: employee.Company.Id,
                            userId: employee.User.Id,
                        },
                    }"
                >
                    <img
                        v-if="employee.User.SmallImageUrl"
                        :src="employee.User.SmallImageUrl"
                        alt="..."
                        class="avatar-img rounded-circle"
                    />
                    <span class="avatar-title rounded-circle" v-else>
                        <!-- <i class="fi flaticon-user" style="margin-left: 3px !important;"></i> style="width: 32px; height: 32px; margin-left: 3px !important;"-->
                        <img :src="'/images/person-big_MyZesec_140dp.png'" style="width: 67px; height: 67px;"/>
                    </span>
                    </router-link>
                   <!--  v-else-if="employee.Company == null  && employee.Location" -->
                <router-link
                    class="avatar avatar-lg"
                    v-else-if="employee.Company == null && employee.Location"
                    :to="{
                        name: 'profile-owner-new',
                        params: {
                            locationId: employee.Location.Id,
                            // companyId: null,
                            userId: employee.User.Id,
                        },
                    }"
                >
                    <img
                        v-if="employee.User.SmallImageUrl"
                        :src="employee.User.SmallImageUrl"
                        alt="..."
                        class="avatar-img rounded-circle"
                    />
                    <span class="avatar-title rounded-circle" v-else>
                        <!-- <i class="fi flaticon-user" style="margin-left: 3px !important;"></i> style="width: 32px; height: 32px; margin-left: 3px !important;"-->
                        <img :src="'/images/person-big_MyZesec_140dp.png'" style="width: 67px; height: 67px;"/>
                    </span>
                    </router-link>                   
                <!-- </div> -->
            </div>
            <div class="col ml-n2">
                <!-- Title -->
                <h4 class="mb-1">
                    <router-link
                        v-if="(employee.Location) && (employee.Company != null)"
                        :to="{
                            name: 'profile',
                            params: {
                                locationId: employee.Location.Id,
                                companyId: employee.Company.Id,
                                userId: employee.User.Id,
                            },
                        }"
                        >{{ userFName(employee.User.FirstName) }}
                        {{ userLName(employee.User.LastName) }}</router-link
                    >
                <!-- </h4>
                <h4 class="mb-1" v-else> -->
                    <router-link
                        v-else-if="(employee.Location == null) && (employee.Company != null)"
                        :to="{
                            name: 'profile-owner',
                            params: {
                                // locationId: null,
                                companyId: employee.Company.Id,
                                userId: employee.User.Id,
                            },
                        }"
                        >
                    {{ userFName(employee.User.FirstName) }} {{ userLName(employee.User.LastName) }}
                    </router-link>
                    <router-link
                        v-else-if="employee.Company == null && employee.Location"
                        :to="{
                            name: 'profile-owner-new',
                            params: {
                                locationId: employee.Location.Id,
                                // companyId: null,
                                userId: employee.User.Id,
                            },
                        }"
                        >
                    {{ userFName(employee.User.FirstName) }} {{ userLName(employee.User.LastName) }}
                    </router-link>                    
                </h4>
                <!-- Text -->
                <p class="card-text small text-muted mb-1" v-if="employee.Company != null">
                    {{ employee.Company.Name }}
                </p>
                <p class="card-text small text-muted mb-1" v-if="employee.Location != null">
                    {{ employee.Location.Name }}
                </p>
                <p class="card-text small text-muted mb-1" v-if="employee.Company != null || employee.Location != null">
                    {{ roleName(employee.Role.Name) }}
                </p>
            </div>
<!--  v-if="((employee.Location) && (employee.Company != null) || ((employee.Location == null) && (employee.Company != null) || (employee.Company == null && employee.Location)))" -->
            <div class="col-auto"   v-if="employee.Company || employee.Location">
                <!-- Dropdown -->
                <b-dropdown
                    variant="outline"
                    toggle-class="dropdown-ellipses dropdown-toggle"
                    no-caret
                    right
                    toggle-tag="a"
                >
                    <template v-slot:button-content>
                        <!-- <i class="fe fe-more-vertical"></i> -->
                        <img :src="'/images/settings_grey_32x32dp.png'" style="width: 19px; height: 19px;"/>
                    </template>
                    <b-dropdown-item
                        class="text-left text-truncate"
                        v-if="employee.Role.Name != 'CompanyOwner'"
                        @click="deleteUser(employee)"
                        >{{ $t("buttons.remove_staff") }}</b-dropdown-item
                    >
                    <!-- v-if="employee.User.Type != 'Light'" -->
                    <b-dropdown-item
                        class="text-left text-truncate"
                        v-if="employee.Role.Name != 'CompanyOwner'"
                        @click="changeRole(employee)"
                        >{{ $t("staff.change_role") }}</b-dropdown-item
                    >
                    <b-dropdown-item
                        class="text-left text-truncate"
                        v-if="employee.Role.Name == 'CompanyOwner'"
                        @click="transferRights(employee)"
                        >{{ $t("staff.transfer_rights") }}</b-dropdown-item
                    >
                </b-dropdown>
            </div>
        </div>
    </b-list-group-item>
</template>
<script>
import {
   M_ADMIN_COMPANY_USER_DELETE,
   M_ADMIN_LOCATION_USER_DELETE,
   M_COMPANY_CHANGE_ROLE_ADMIN_FORM,
   M_CHANGE_OWNER,
} from "@/components/modals/types";
import { definedRoles, ROLE_COMPANY_MANAGER } from "@/acl/roles";

import modalMixin from "@/mixins/modalMixin";

export default {
    name: "employee-list-item-admin",
    mixins: [modalMixin],
    data() {
        return {
            keyRouteParams: false,
        };
    },
    computed: {},
    props: ["employee", "company"],
    methods: {
        deleteUser(user) {
            user.User["CompanyId"] = this.$route.params.companyId;
            if (user.Role.Name === "CompanyOwner" || user.Role.Name === "CompanyManager") {
                this.showModal({ user: user }, M_ADMIN_COMPANY_USER_DELETE);
            } else {
                this.showModal({ user: user }, M_ADMIN_LOCATION_USER_DELETE);
            }
        },
        changeRole(user) {
            this.showModal({ user: this.employee }, M_COMPANY_CHANGE_ROLE_ADMIN_FORM);
        },
        transferRights(user){
            this.showModal({ user: this.employee }, M_CHANGE_OWNER);   
        },
        getRoleObject(roleName) {
            return definedRoles[roleName];
        },
        userFName(FirstName){
            FirstName = this.employee.User.FirstName;
            if(FirstName === null || FirstName === "null" || FirstName === ""){
                FirstName = '';
            }
            return FirstName;
        },
        userLName(LastName){
            LastName = this.employee.User.LastName;
            if(LastName === null  || LastName === "null" || LastName === ""){
                LastName = '';
            }
            return LastName;
        },
        roleName(name){
            if(name == "CompanyOwner"){                
                name = this.$t("permissions.role_owner");
            }else if(name == "CompanyManager"){
                name = this.$t("permissions.role_cm");
            }else if(name == "LocationManager"){
                name = this.$t("permissions.role_lm");
            }else if(name == "KeyManager"){
                name = this.$t("permissions.role_km");
            }else if(name == "Installer"){
                name = this.$t("permissions.role_installer");
            }
            return name;
        }
    },
};
</script>
<style scoped>
.list-group-item:last-child{
  overflow-y: auto !important;
}
.btn:focus{
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
}
</style>

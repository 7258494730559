<template>
<!-- :title="$t('doors.new_saltoInstallation')" -->
    <b-modal
        :id="type"
        :title="titleText"
        size="lg"
        hide-footer
        modal-class="fixed-right"
        dialog-class="modal-dialog-vertical"
        @show="onShow"
        @hide="onClose"
    >
        <b-container fluid>
            <b-row>
                <b-col cols="12" style="padding-left: 54px !important;">
                    <b-form>                        
                        <b-form-group
                            :label="$t('doors.name')"
                        >
                            <b-form-input
                                v-model="$v.salto.Name.$model"
                                :state="validateState('Name')"
                                id="saltoName"
                                :placeholder="$t('doors.name')"
                            />
                            <b-form-invalid-feedback id="saltoName-feedback">
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                {{
                                    $t("errors.validation_length_range", {
                                        range: "3-30",
                                    })
                                }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group
                            :label="$t('salto.short_name')"
                        >
                            <b-form-input
                                v-model="$v.salto.ShortName.$model"
                                :state="validateState('ShortName')"
                                id="saltoShortName"
                                :placeholder="$t('salto.short_name')"
                            />
                            <b-form-invalid-feedback id="saltoShortName-feedback">
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                {{
                                    $t("errors.validation_length_range", {
                                        range: "3-30",
                                    })
                                }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group
                            :label="$t('salto.saltoInstallation_id')"
                        >
                            <b-form-input
                                v-model="$v.salto.SaltoInstallationId.$model"
                                :state="validateState('SaltoInstallationId')"
                                id="saltosaltoInstallation_id"
                                :placeholder="$t('salto.saltoInstallation_id')"
                                :disabled="!createMode"
                            />
                            <b-form-invalid-feedback id="saltoSaltoInstallationId-feedback">
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                {{
                                    $t("errors.salto_installation_error")
                                }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group
                            :label="$t('salto.company')"
                        >
                        <!-- :disabled="!createMode" -->
                            <b-input-group>
                                <!-- <b-form-select
                                    v-model="$v.salto.CompanyId.$model"
                                    :state="validateState('CompanyId')"
                                    id="saltoCompanyId"
                                    v-if="getCompaniesOptions.length > 15"
                                    :options="getCompaniesOptions"
                                    onfocus='this.size=10, this.height=2'
                                    onchange='this.size=10, this.height=2, this.blur();'
                                    onblur='this.size=10, this.height=2' 
                                    onfocusout='this.size=null;'
                                ></b-form-select> -->
                                <b-form-select
                                    v-model="$v.salto.CompanyId.$model"
                                    :state="validateState('CompanyId')"
                                    id="saltoCompanyId"
                                    :options="getCompaniesOptions"
                                ></b-form-select>
                                <b-form-invalid-feedback
                                    id="saltoCompanyId-feedback"
                                >
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{ $t("locations.company_select") }}
                                </b-form-invalid-feedback>
                            </b-input-group>                           
                        </b-form-group>                            
                        <b-form-group
                            :label="$t('salto.location')"
                        >
                       <!--  :disabled="!createMode" -->
                           <b-input-group>
                                <!-- <b-form-select
                                    v-model="$v.salto.LocationId.$model"
                                    :state="validateState('LocationId')"
                                    id="saltoLocationId"
                                    v-if="getLocationsOptions.length > 15"
                                    :options="getLocationsOptions"  
                                    onfocus='this.size=10, this.height=2'
                                    onchange='this.size=10, this.height=2, this.blur();'
                                    onblur='this.size=10, this.height=2' 
                                    onfocusout='this.size=null;'                                                                      
                                ></b-form-select> -->
                                <b-form-select
                                    v-model="$v.salto.LocationId.$model"
                                    :state="validateState('LocationId')"
                                    id="saltoLocationId"
                                    :options="getLocationsOptions"                                                                     
                                ></b-form-select>
                                <b-form-invalid-feedback
                                    id="saltoLocationId-feedback"
                                >
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{ $t("menu.select_location") }}
                                </b-form-invalid-feedback>
                            </b-input-group>                           
                        </b-form-group>                           
                        <b-form-group
                            :label="$t('salto.IP_DNS')"
                        >
                            <b-form-input
                                v-model="$v.salto.IPDNSNamePort.$model"
                                :state="validateState('IPDNSNamePort')"
                                id="saltoIP_DNS"
                                :placeholder="$t('salto.IP_DNS')"
                            />
                            <b-form-invalid-feedback id="saltoIPDNSNamePort-feedback">
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                {{
                                    $t("errors.validation_length_range", {
                                        range: "3-30",
                                    })
                                }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group
                            :label="$t('salto.VPN_user')"
                        >
                        <!--  :state="validateState('VPNUserPwd')" -->
                            <b-form-input
                                v-model="$v.salto.VPNUserPwd.$model"                               
                                id="saltoVPN_user"
                                :placeholder="$t('salto.VPN_user')"
                            />
                            <!-- <b-form-invalid-feedback id="saltoVPNUserPwd-feedback">
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                {{
                                    $t("errors.validation_length_range", {
                                        range: "3-30",
                                    })
                                }}
                            </b-form-invalid-feedback> -->
                        </b-form-group>
                        <b-button
                            class="input-field"
                            @click.prevent.stop="postSaltoInstallation"
                            v-if="createMode"                            
                            >{{ $t("buttons.create_salto_installation") }}</b-button
                        >
                        <b-button
                            class="input-field"
                            @click.prevent.stop="editSaltoInstallation"
                            v-else
                            >{{ $t("buttons.update_salto_installation") }}</b-button
                        >
                    </b-form>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import {
    SALTO_INSTALLATION_GET,
    SALTO_INSTALLATION_CREATE,
    SALTO_INSTALLATION_PATCH } from "@/store/types/salto";
import { M_SALTO_FORM } from "@/components/modals/types";
import { CLEAR_MODAL_DATA } from "@/store/types/global";
import { validationMixin } from "vuelidate";
import Select2 from "@/components/common/Select2";
import {
    required,
    minLength,
    maxLength,
    numeric,
} from "vuelidate/lib/validators";

export default {
    mixins: [validationMixin],
    data() {
        return {
            type: M_SALTO_FORM,
            createMode: true,
            salto: [],
            submitted: false,
            device: {},
            filter: "",
            filterLocation: "",
            titleText: "",
        };
    },
    validations: {
        salto: {
            Name: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(30),
            },
            ShortName: {
                minLength: minLength(3),
                maxLength: maxLength(30),
            },
            SaltoInstallationId: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(30),
            },
            CompanyId: {
                required,
                numeric,
            },
            LocationId: {
                required,
                numeric,
            },
            IPDNSNamePort: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(30),
            },
            VPNUserPwd: {
                // required,
                minLength: minLength(3),
                maxLength: maxLength(30),
            },
        },
    },
    props: ["locationId"],
    computed: {
        ...mapGetters(["getModalData", "getModalType", "getActiveCompany", "getAllAdminCompanies", "getAllLocations"]),
        saltoInstallationTemplate() {
            return {
                Name: "",
                ShortName: "",
                SaltoInstallationId: "",
                CompanyId: null,
                LocationId: null,
                IPDNSNamePort: null,
                VPNUserPwd: "",          
            };
        },
        getCompaniesOptions() {
            return [
                {
                    value: null,
                    text: this.$t("locations.company_select"),
                    disabled: true,
                },
                ...this.getAllAdminCompanies
                .filter((item) =>
                    item.Name
                    .toLowerCase()
                    .includes(this.filter.toLowerCase())
                )
                .sort((i1, i2) => {
                    return i1.Name.toUpperCase() > i2.Name.toUpperCase() ? 1 : -1;
                })
                .map((item) => {
                    return {
                        value: item.Id,
                        text: item.Name,
                    };
                }),
            ];
        },
        getLocationsOptions(){
                return [
                    {
                        value: null,
                        text: this.$t("menu.select_location"),
                        disabled: true,
                    },
                    ...this.getAllLocations                        
                        .filter((item) =>{
                            let filterLoc = true;                           
                            if(this.salto.CompanyId != null){
                                filterLoc = item.Location.CompanyId == this.salto.CompanyId
                            }else{
                                filterLoc = item.Location.Name.toLowerCase().includes(this.filterLocation.toLowerCase()) ||
                                item.Location.Address.toLowerCase().includes(this.filterLocation.toLowerCase())
                            }
                            return filterLoc;
                        })
                        .sort((i1, i2) => {
                            return i1.Location.Name.toUpperCase() > i2.Location.Name.toUpperCase() ? 1 : -1;
                        })
                        .map((item) => {                        
                            return { value: item.Location.Id, text: item.Location.Name }; 
                        }), 
                ];
        },
    },
    created() {
        // this.setData();
    },
    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.salto[name];  
            return $dirty && this.submitted ? !$error : null;
        },
        postSaltoInstallation: function () {
            this.submitted = true;
            this.$v.salto.$touch();

            if (this.$v.salto.$anyError) {
                return;
            }

            let salto = { ...this.salto };
            
            this.$store
                .dispatch(SALTO_INSTALLATION_CREATE, salto)
                .then((response) => {
                    this.$bvModal.hide(this.type);
                })                
                .catch((error) => {});
        },

        editSaltoInstallation(response) {
            this.submitted = true;
            this.$v.salto.$touch();
            if (this.$v.salto.$anyError) {
                return;
            }
            
            let salto = { ...this.getModalData.salto };            
            this.$store
                .dispatch(SALTO_INSTALLATION_PATCH, {...this.salto,
                 ...{ ImageId: response.Id },})
                .then(() => {
                    this.$bvModal.hide(this.type);
                })
                .catch((error) => {});             
        },
        setData() {          
           if (this.getModalData !== null) {
                this.titleText = this.$t('doors.update_saltoInstallation');
                this.salto = { ...this.getModalData.salto };                           
                this.createMode = false;
            } else {
                this.titleText = this.$t('doors.new_saltoInstallation');
                this.salto = { ...this.saltoInstallationTemplate };
                this.createMode = true;
            }
           
            this.$v.salto.$reset();
        },
        onShow() {
            this.setData();
        },
        onClose() {
            this.submitted = false;
            this.$v.salto.$reset();
            this.createMode = true;
            this.salto = { ...this.saltoInstallationTemplate };
            this.$store.commit(CLEAR_MODAL_DATA);
        },
    },
    components: {
    },
};
</script>
<style scoped>
.alert-warning {
    color: #856404 !important;
    background-color: #fff3cd !important;
    border-color: #ffeeba !important;
}
input:disabled {
    color: #95aac9;
    background-color: #edf2f9;
}
.select2-search__field {
    width: 100% !important;
    padding: 0.125rem 2.5rem !important;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35' height='16' opacity='0.5' class='bi bi-search' style='margin-left: 2px' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat left;
}
li.select2-search--inline {
    width: 100% !important;
}
</style>

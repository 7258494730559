<template>
        <b-col cols="12">
        <div class="card mb-4">
            <div class="card-body">
                <div class="row align-items-center">                  
                    <div class="col ml-n2">
                        <!-- Title -->
                        <table style="text-align: left !important; border-collapse: separate !important; border-spacing: 28px 0px !important;">
                            <tr>
                                <td><h4 class="mb-1">
                                    {{ $t('doors.name') }} {{ ":" }}
                                </h4></td>
                                <td>{{ salto.Name }}</td>
                            </tr>
                            <tr>
                                <td><h4 class="mb-1">
                                    {{ $t('salto.short_name') }} {{ ":" }}
                                </h4></td>
                                <td>{{ salto.ShortName }}</td>
                            </tr>
                            <tr>
                                <td><h4 class="mb-1">
                                    {{ $t('salto.saltoInstallation_id') }} {{ ":" }}
                                </h4></td>
                                <td>{{ salto.SaltoInstallationId }}</td>
                            </tr>
                            <tr>
                                <td><h4 class="mb-1">
                                    {{ $t('salto.company') }} {{ ":" }}
                                </h4></td>
                                <td>{{ companyName(salto.CompanyId) }}</td>
                            </tr>
                            <tr>
                                <td><h4 class="mb-1">
                                    {{ $t('salto.location') }} {{ ":" }}
                                </h4></td>
                                <td>{{ locationName(salto.LocationId) }}</td>
                            </tr>
                            <tr>
                                <td><h4 class="mb-1">
                                    {{ $t('salto.IP_DNS') }} {{ ":" }}
                                </h4></td>
                                <td>{{ salto.IPDNSNamePort }}</td>
                            </tr>
                            <tr>
                                <td><h4 class="mb-1">
                                    {{ $t('salto.VPN_user') }} {{ ":" }}
                                </h4></td>
                                <td>{{ salto.VPNUserPwd }}</td>
                            </tr>
                        </table>
                    </div>
                    <div class="col-auto">
                        <b-dropdown
                    variant="outline"
                    toggle-class="dropdown-ellipses dropdown-toggle"
                    no-caret
                    right
                    toggle-tag="a"                                        
                >
                    <template v-slot:button-content>                        
                        <img :src="'/images/settings_grey_32x32dp.png'" style="width: 19px; height: 19px;"/>
                    </template>
                    <b-dropdown-item
                        class="text-left text-truncate"
                        @click="editSaltoInstallation(salto)"
                        >{{ $t("buttons.edit") }}</b-dropdown-item
                    >
                    <b-dropdown-item
                        class="text-left text-truncate"
                        @click="deleteSaltoInstallation(salto)"
                        >{{ $t("buttons.delete") }}</b-dropdown-item
                    >                    
                </b-dropdown>
                    </div>
                </div>
            </div>
        </div>
    </b-col>
</template>
<script>
import { getDeviceProvider } from "@/handlers/enums";
import { SALTO_INSTALLATION_GET, SALTO_ONLINE_DEVICE_GET, SALTO_ONLINE_DEVICE_CREATE } from "@/store/types/salto";
import {
    M_SALTO_FORM,
    M_SALTO_INSTALLATION_DELETE,
} from "@/components/modals/types";
import { mapGetters } from "vuex";

import modalMixin from "@/mixins/modalMixin";

export default {
    mixins: [modalMixin],
    name: "salto-list-item",
    props: ["salto"],
    computed: {
        ...mapGetters(["getSaltoInstallation", "getAllAdminCompanies", "getAllLocations" ]),
    },
    created() {
    },
    methods: {
        getDeviceProvider(id) {
            return getDeviceProvider(id);
        },
        deleteSaltoInstallation(salto){
            this.showModal(
                { salto: this.salto },
                M_SALTO_INSTALLATION_DELETE
            );
        },
        editSaltoInstallation(salto){
            this.showModal(
                { salto: this.salto },
                M_SALTO_FORM
            );
        },
        companyName(companyId){            
            if(companyId != null){
                /* let companyName = this.getAllAdminCompanies.find(
                    (item) => item.Id == companyId
                ).Name;  */ 
                let companyName = '';
                this.getAllAdminCompanies.filter(
                    (item) => item.Id == companyId
                )
                .map((item) => {   
                    if(item.Name == undefined){return}             
                    companyName = item.Name
                });  
                return companyName;
            } 
        },
        locationName(locationId){ 
            if(locationId != null){
                let locationName = '';
                this.getAllLocations.filter(
                    (item) => item.Location.Id == locationId
                )
                .map((item) => {   
                    if(item.Location.Name == undefined){return}             
                    locationName = item.Location.Name
                });        
                return locationName; 
            }           
        }
    },
};
</script>
<style scoped>
img.provider {
    position: absolute;
    left: 5px;
    bottom: 10px;
    width: 50%;
}
.door-image-link {
    position: relative;
}
.card {
    /* box-shadow: 0 11px 10px #4444; */
    box-shadow: 0px 3px 20px rgb(68 68 68 / 27%);
    border-radius: 1.5rem;
}
.btn:focus{
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
}
</style>
<template>
    <b-modal
        id="deleteCompanyModal"
        :title="$t('modals.title_delete_company')"
        :ok-title="$t('buttons.delete')"
        :cancel-title="$t('buttons.cancel')"
        ok-variant="outline-danger"
        modal-class="fixed-right"
        dialog-class="modal-dialog-vertical"
        @ok="deleteCompany"
        @hide="onClose"
    >
        <b-container fluid v-if="type == getModalType">
            <b-row>
                <b-col cols="12">
                    {{
                        $t("modals.text_delete_company", {
                            companyName: getModalData.company.Name,
                        })
                    }}
                    <span class="bold-font-style">{{
                        $t("modals.are_you_sure")
                    }}</span>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>

<script>
import { mapGetters } from "vuex";

import { COMPANY_DELETE } from "@/store/types/companies";
import { M_COMPANY_DELETE } from "@/components/modals/types";
import { CLEAR_MODAL_DATA } from "@/store/types/global";

export default {
    data() {
        return {
            type: M_COMPANY_DELETE,
        };
    },
    computed: {
        ...mapGetters(["getModalData", "getModalType"]),
    },
    methods: {
        deleteCompany() {
            this.$store
                .dispatch(COMPANY_DELETE, this.getModalData.company.Id)
                .then(() => {
                    if (this.$route.params.companyId) {
                        this.$router.push({
                            name: "dashboard",
                        });
                    }
                });
        },
        onClose() {
            this.$store.commit(CLEAR_MODAL_DATA);
        },
    },
};
</script>

<style>
#card-title {
    font-size: 1em;
}
</style>

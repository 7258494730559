<template>
    <b-modal
        :id="type"
        :title="$t('companies.company')"
        size="lg"
        hide-footer
        modal-class="fixed-right"
        dialog-class="modal-dialog-vertical"
        @show="onShow"
        @hide="onClose"
    >
        <b-container fluid v-if="type == getModalType">
            <b-row>
                <b-col id="company" cols="12" style="padding-left: 54px !important;">
                    <b-form>
                       <!--  label-cols-sm="2"
                            label-align-sm="right"
                            label-size="sm" -->
                        <b-form-group :label="$t('companies.name')">
                            <b-form-input
                                v-model="$v.company.Name.$model"
                                :state="validateState('Name')"
                                id="companyName"
                                :placeholder="$t('companies.name')"
                            />
                            <b-form-invalid-feedback id="companyName-feedback">
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                {{
                                    $t("errors.validation_length_range", {
                                        range: "3-50",
                                    })
                                }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group :label="$t('companies.number')">
                            <b-form-input
                                v-model="$v.company.OrganizationNumber.$model"
                                id="companyOrg"
                                :state="validateState('OrganizationNumber')"
                                :placeholder="$t('companies.number')"
                            />
                            <b-form-invalid-feedback id="companyOrg-feedback">
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                {{
                                    $t("errors.validation_number")
                                }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group :label="$t('staff.role_invite')">
                            <b-form-input
                                id="Role"
                                :placeholder="$t('permissions.role_owner')"
                                :disabled="disabled"
                                style="color: #95aac9; background-color: #edf2f9;"
                            />
                        </b-form-group>
                        <b-form-group :label="$t('companies.owner_name')">
                            <b-form-input
                                v-model="$v.company.OwnerName.$model"
                                :state="validateState('OwnerName')"
                                id="ownerName"
                                :disabled="!createMode"
                                :placeholder="$t('companies.owner_name')"
                            />
                            <b-form-invalid-feedback id="companyOwnerName-feedback">
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                {{
                                    $t("errors.validation_length_range", {
                                        range: "3-50",
                                    })
                                }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group :label="$t('companies.owner_phone_number')">
                            <vue-phone-number-input
                                v-model="$v.company.OwnerPhoneNumber.$model"
                                :translations="$t('phoneInput')"
                                id="ownerPhone"
                                :error="!validPhone && submitted"
                                @update="validatePhone"
                                :disabled="!createMode"
                                required
                                :default-country-code="company.countryCode"
                                :fetch-country="createMode"
                                type="text"
                            />
                            <span id="ownerPhone-feedback" v-if="!validPhone && submitted"  style="color: #e63857;  font-size: 0.8125rem">
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                {{ $t("errors.validation_phone_number") }}
                            </span>
                        </b-form-group>
                        <b-form-group :label="$t('companies.company_phone')">
                            <vue-phone-number-input
                                v-model="$v.company.PhoneNumber.$model"
                                :translations="$t('phoneInput')"
                                id="companyPhone"
                                :error="!validCompanyPhone && submitted"
                                @update="validateCompanyPhone"
                                required
                                :default-country-code="company.companyCountryCode"
                                :fetch-country="createMode"
                                type="text"
                            />
                            <span id="companyPhone-feedback" v-if="!validCompanyPhone && submitted"  style="color: #e63857;  font-size: 0.8125rem">
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                {{ $t("errors.validation_phone_number") }}
                            </span>
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col id="address" cols="12" style="padding-left: 54px !important;">
                    <b-form>
                        <p :label="$t('companies.address')"
                            label-align-sm="left"
                            style="margin-left: -45px !important;">{{ $t('companies.address') }}</p>
                        <b-form-group>
                            <b-form-group :label="$t('companies.street')">
                                <b-form-input
                                    v-model="$v.company.Street.$model"
                                    id="companyStreet"
                                    :state="validateState('Street')"
                                    :placeholder="$t('companies.street')"
                                    type="text"
                                />
                                <b-form-invalid-feedback
                                    id="companyStreet-feedback"
                                >
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{
                                        $t("errors.validation_length_range", {
                                            range: "2-30",
                                        })
                                    }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                            <b-form-group :label="$t('companies.zip')">
                                <b-form-input
                                    v-model="$v.company.PostalCode.$model"
                                    id="companyPostal"
                                    :state="validateState('PostalCode')"
                                    :placeholder="$t('companies.zip')"
                                    type="text"
                                />
                                <b-form-invalid-feedback id="companyPostal-feedback">
                                    <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{
                                        $t("errors.validation_zip")
                                    }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                            <b-form-group :label="$t('companies.town')">
                                <b-form-input
                                    v-model="$v.company.Town.$model"
                                    id="companyTown"
                                    :state="validateState('Town')"
                                    :placeholder="$t('companies.town')"
                                />
                                <b-form-invalid-feedback id="companyTown-feedback">
                                    <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{
                                        $t("errors.validation_length_range", {
                                            range: "2-30",
                                        })
                                    }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                            <b-form-group :label="$t('companies.country')">
                                <b-form-input
                                    v-model="$v.company.Country.$model"
                                    id="companyCountry"
                                    :state="validateState('Country')"
                                    :placeholder="$t('companies.country')"
                                    type="text"
                                />
                                <b-form-invalid-feedback id="companyCountry-feedback">
                                    <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{
                                        $t("errors.validation_length_range", {
                                            range: "2-30",
                                        })
                                    }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-form-group>
                    </b-form>
                </b-col>

                <b-col id="type" cols="12" style="padding-left: 54px !important;">
                    <b-form>
                        <p :label="$t('companies.type')"
                            label-align-sm="left"
                            style="margin-left: -45px !important;">{{ $t('companies.type') }}</p>
                        <b-form-group>
                            <b-form-group :label="$t('companies.type_select')">
                                <b-input-group>
                                    <b-form-select
                                        v-model="$v.company.Type.$model"
                                        id="companyType"
                                        :state="validateState('Type')"
                                        :options="companyTypesOptions"
                                        :disabled="!createMode"
                                    ></b-form-select>
                                </b-input-group>
                                <b-form-invalid-feedback
                                    id="companyType-feedback"
                                    >
                                    <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{
                                        $t("companies.type_select_default")
                                    }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-form-group>
                    </b-form>
                </b-col>

                <b-col id="type" cols="12" style="padding-left: 54px !important;">
                    <b-form>
                        <p :label="$t('companies.api_keys')"
                           label-align-sm="left"
                           style="margin-left: -45px !important;">{{ $t('companies.api_keys') }}</p>
                        <b-form-group>
                            <b-form-group :label="$t('companies.inlet_online_api_key')">
                                <b-form-input
                                    v-model="$v.company.InletOnlineApiKey.$model"
                                    id="companyInletOnlineApiKey"
                                    :state="validateState('InletOnlineApiKey')"
                                    :placeholder="$t('companies.inlet_online_api_key')"
                                    type="text"
                                />
                                <b-form-invalid-feedback id="companyInletOnlineApiKey-feedback">
                                    <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{
                                        $t("errors.validation_no_req_length_range", {
                                            range: "0-40",
                                        })
                                    }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-form-group>
                    </b-form>
                </b-col>

                <b-col style="padding-left: 54px !important;">
                    <b-button
                        class="input-field"
                        @click.prevent.stop="postCompany"
                        v-if="createMode"
                        >{{ $t("companies.create") }}</b-button
                    >
                    <b-button class="input-field" @click.prevent.stop="editCompany" v-else>{{
                        $t("companies.update")
                    }}</b-button>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import Select2 from "@/components/common/Select2";
import { companyTypes } from "@/handlers/enums";
import {
    COMPANY_CREATE,
    COMPANY_PATCH,
    COMPANIES_GET,
} from "@/store/types/companies";
import {
    definedRoles,
    definedRolesArray,
    definedKeyRolesIdsArray,
    ROLE_COMPANY_OWNER,
    ROLE_COMPANY_MANAGER,
    ROLE_LOCATION_MANAGER,
    ROLE_DEVICE_INSTALLER,
    ROLE_KEY_PERMANENT,
    ROLE_KEY_TEMPORARY,
    ROLE_KEY_MANAGER,
} from "@/acl/roles";
import {
    ADMIN_COMPANY_CREATE,
    ADMIN_COMPANY_PATCH,
    ADMIN_COMPANIES_GET,
    REGISTERED_USERS_GET,
} from "@/store/types/admin";
import { CLEAR_MODAL_DATA } from "@/store/types/global";
import { M_COMPANY_ADMIN_FORM } from "@/components/modals/types";
import { validationMixin } from "vuelidate";
import VuePhoneNumberInput from "vue-phone-number-input";
import {
    required,
    minLength,
    numeric,
    maxLength,
} from "vuelidate/lib/validators";

import { parsePhoneNumberFromString } from "libphonenumber-js";

export default {
    mixins: [validationMixin],
    data() {
        return {
            type: M_COMPANY_ADMIN_FORM,
            validPhone: false,
            fullPhone: null,
            validCompanyPhone: false,
            fullCompanyPhone: null,
            createMode: true,
            company: {},
            submitted: false,
            filter: "",
            disabled: true,
        };
    },
    validations: {
        company: {
            Name: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(50),
            },
            OrganizationNumber: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(20),
            },
            OwnerPhoneNumber: {
                required,
                phone: function () {
                    return this.validPhone;
                },
            },
            PhoneNumber: {
                required,
                companyPhone: function () {
                    return this.validCompanyPhone;
                },
            },
            Street: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(30),
            },
            PostalCode: {
                required,
                numeric,
                minLength: minLength(4),
                maxLength: maxLength(5),
            },
            Town: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(30),
            },
            Country: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(30),
            },
            InletOnlineApiKey: {
                required: false,
                minLength: minLength(0),
                maxLength: maxLength(40),
            },
            Type: {
                required,
            },
            /* RoleType: {
                // required,
                numeric,
                // minLength: minLength(3),
                // maxLength: maxLength(50),
            }, */
            OwnerName: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(50),
            }
        },
    },
    computed: {
        ...mapGetters(["getModalData", "getModalType", "getAllUsers", "getAllRegisteredUsers"]),
        companyTemplate() {
            return {
                OrganizationNumber: "",
                // RoleType: null,
                Name: "",
                Street: "",
                Town: "",
                PostalCode: "",
                OwnerPhoneNumber: "",
                PhoneNumber: "",
                Country: "",
                InletOnlineApiKey: "",
                Type: null,
                OwnerName: "",
            };
        },
        companyTypesOptions() {
            return [
                { value: null, text: this.$t("companies.type_select_default") },
                ...companyTypes
                    .map((item) => {
                        return {
                            value: item.value,
                            text: this.$t(item.key),
                            disabled: item.disabled,
                        };
                    })
                    .filter((item) => !item.disabled),
            ];
        },
        userOptions(){
            return [
                {
                    value: null,
                    text: this.$t("staff.user_select"),
                    disabled: true,
                },
                ...this.getAllUsers
                .filter((item) =>
                    item.User.Name
                    .toLowerCase()
                    .includes(this.filter.toLowerCase())
                )
                .sort((i1, i2) => {
                    return i1.User.Name.toUpperCase() > i2.User.Name.toUpperCase() ? 1 : -1;
                })
                .map((item) => {
                   if(item.User.LastName === null){
                        item.User.LastName = ''
                        return {
                            text: item.User.FirstName + " " + item.User.LastName + " - " + item.User.PhoneNumber,
                            id: item.User.Id,
                        };
                    }else{
                        return {
                            text: item.User.FirstName + " " + item.User.LastName + " - " + item.User.PhoneNumber,
                            id: item.User.Id,
                        };
                    }
                }),
            ];
        },
        rolesOptions() {
            return [
                {
                    value: null,
                    text: this.$t("staff.role_select"),
                    disabled: true,
                },
                ...definedRolesArray().filter((item) => {
                    if(item.value == ROLE_COMPANY_OWNER){
                        item.text = this.$t("permissions.role_owner");
                    }else if(item.value == ROLE_COMPANY_MANAGER){
                        item.text = this.$t("permissions.role_cm");
                    }else if(item.value == ROLE_LOCATION_MANAGER){
                        item.text = this.$t("permissions.role_lm");
                    }else if(item.value == ROLE_KEY_MANAGER){
                        item.text = this.$t("permissions.role_km");
                    }else if(item.value == ROLE_DEVICE_INSTALLER){
                        item.text = this.$t("permissions.role_installer");
                    }

                        return (
                            this.$can("invite", item) &&
                            [
                                ROLE_COMPANY_OWNER,
                                ROLE_COMPANY_MANAGER,
                                ROLE_KEY_MANAGER,
                                ROLE_DEVICE_INSTALLER,
                                ROLE_LOCATION_MANAGER,
                            ].includes(item.value)
                        );
                }),
            ];
        },
    },
    created() {
        this.setData();
    },
    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.company[name];
            return $dirty && this.submitted ? !$error : null;
        },
        userName(id){
            let ownerFirstName = ''
            this.getAllRegisteredUsers.filter(
              (item) => item.Id == id
            ).map((item)=>{
                if(item.FirstName === undefined) {return}
                ownerFirstName = item.FirstName
                return ownerFirstName ;
            })
            return ownerFirstName;
        },
        validatePhone(phone) {
            this.validPhone = phone.isValid;
            this.fullPhone = phone;
        },
        validateCompanyPhone(companyPhone) {
            this.validCompanyPhone = companyPhone.isValid;
            this.fullCompanyPhone = companyPhone;
        },
        roleSelected(roleId) {
            if (
                [
                    definedRoles[ROLE_LOCATION_MANAGER].Id,
                    definedRoles[ROLE_DEVICE_INSTALLER].Id,
                    definedRoles[ROLE_KEY_MANAGER].Id,
                ].includes(roleId)
            ) {
                this.$root.$emit("bv::toggle::collapse", "accordion-l");
            }
        },
        postCompany: function () {
            this.submitted = true;
            this.$v.company.$touch();
            if (this.$v.company.$anyError) {
                return;
            }

            let payload = {
                ...this.company,
                ...{ OwnerPhoneNumber: this.fullPhone.formattedNumber },
                ...{ PhoneNumber: this.fullCompanyPhone.formattedNumber },
            };

            this.$store
                .dispatch(ADMIN_COMPANY_CREATE, payload)
                .then(() => {
                    this.$bvModal.hide(this.type);
                })
                .catch((error) => {});
        },
        editCompany() {
            this.submitted = true;
            this.$v.company.$touch();
            if (this.$v.company.$anyError) {
                return;
            }

            let payload = {
                ...this.company,
                ...{ OwnerPhoneNumber: this.fullPhone.formattedNumber },
                ...{ PhoneNumber: this.fullCompanyPhone.formattedNumber },
            };

            this.$store
                .dispatch(ADMIN_COMPANY_PATCH, payload)
                .then(() => {
                    // this.$store.dispatch(REGISTERED_USERS_GET);
                    this.$bvModal.hide(this.type);
                })
                .catch((error) => {});
        },
        setData() {
            if (this.getModalData !== null) {
                this.company = { ...this.getModalData.company };
                this.createMode = false;

                // let ownerName = this.userName(this.company.OwnerId)
                if(this.company.Owner.FirstName == null || this.company.Owner.FirstName == "null" || this.company.Owner.FirstName == "" || this.company.Owner.FirstName == undefined){
                    this.company.Owner.FirstName = '';
                }
                if(this.company.Owner.LastName == null || this.company.Owner.LastName == "null" || this.company.Owner.LastName == "" || this.company.Owner.LastName == undefined){
                    this.company.Owner.LastName = '';
                }
                this.company.OwnerName = this.company.Owner.FirstName +" "+ this.company.Owner.LastName;

                this.company.countryCode = parsePhoneNumberFromString(
                    this.company.Owner.PhoneNumber
                ).country;
                this.company.OwnerPhoneNumber = parsePhoneNumberFromString(
                    this.company.Owner.PhoneNumber
                ).nationalNumber;

                this.company.companyCountryCode = parsePhoneNumberFromString(
                    this.company.PhoneNumber
                ).country;
                this.company.PhoneNumber = parsePhoneNumberFromString(
                    this.company.PhoneNumber
                ).nationalNumber;
            } else {
                this.company = { ...this.companyTemplate };
            }
            this.$v.company.$reset();
        },
        onShow() {
            this.setData();
        },
        onClose() {
            this.$v.company.$reset();
            this.createMode = true;
            this.company = { ...this.companyTemplate };
            this.$store.commit(CLEAR_MODAL_DATA);
            this.submitted = false;
        },
    },
    components: {
        "vue-phone-number-input": VuePhoneNumberInput,
    },
};
</script>

<style>
#card-title {
    font-size: 1em;
}
input:disabled {
    color: #95aac9;
    background-color: #edf2f9;
}
.select2-search__field {
    width: 100% !important;
    padding: 0.125rem 2.5rem !important;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35' height='16' opacity='0.5' class='bi bi-search' style='margin-left: 2px' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat left;
}
li.select2-search--inline {
    width: 100% !important;
}
/* .select2-results__option:first-child{
    background-color: #e3ebf6;
    width: auto;
} */
</style>

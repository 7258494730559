<template>
    <b-modal
        :id="type"
        :title="$t('modals.title_remove_user')"
        :ok-title="$t('buttons.delete')"
        :cancel-title="$t('buttons.cancel')"
        ok-variant="outline-danger"
        modal-class="fixed-right"
        dialog-class="modal-dialog-vertical"        
        @ok="disconnectUser"
        @show="setData"
        @hide="onClose"
        :ok-disabled="!flagVal"
    >
    <!--  :ok-disabled="!compChkbox || !locationChkbox || !deviceChkbox || !keyChkbox || !sysemChkbox" 
    :ok-disabled="(user.CompanyId != null) || (user.LocationId != null) || (user.DeviceId != null) || (user.KeyId != null) || (user.UserId != null)"-->

        <b-container fluid v-if="(type == getModalType)">
            <b-row>
                <b-col cols="12">
                    <b-form>
                        <b-form-checkbox
                            v-model="compChkbox"
                            :key="user.companyIdVal"
                            :value="user.companyIdVal"
                            @change="disconnectCompany"                            
                        >{{$t("modals.delete_company")}}</b-form-checkbox>
                        <b-form-group class="mt-3">
                            <b-input-group>                                
                                <b-form-select
                                :disabled="!compChkbox || locationChkbox || deviceChkbox || keyChkbox || sysemChkbox"
                                v-model="$v.user.CompanyId.$model"
                                id="companyVals"
                                :state="validateState('CompanyId')"
                                :options="getUserCompaniesOptions"
                                :placeholder="$t('locations.company_select')"
                                ></b-form-select>
                                <b-form-invalid-feedback
                                    id="companyVals-feedback"
                                >
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{ $t("locations.company_select") }}
                                </b-form-invalid-feedback>                            
                            </b-input-group>
                        </b-form-group>

                        <b-form-checkbox
                            v-model="locationChkbox"
                            :key="user.locationIdVal"
                            :value="user.locationIdVal"
                            @change="disconnectLocation"
                        >{{$t("modals.delete_location")}}</b-form-checkbox>
                        <b-form-group class="mt-3">
                            <b-input-group>                                
                                <b-form-select
                                :disabled="!locationChkbox || compChkbox || deviceChkbox || keyChkbox || sysemChkbox"
                                v-model="$v.user.LocationId.$model"
                                :state="validateState('LocationId')"
                                id="locationVals"
                                :options="getLocationsOptions"
                                :placeholder="$t('menu.select_location')"
                                ></b-form-select>
                                <b-form-invalid-feedback
                                    id="locationVals-feedback"
                                >
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{ $t("menu.select_location") }}
                                </b-form-invalid-feedback>                            
                            </b-input-group>
                        </b-form-group> 

                        <b-form-checkbox
                            v-model="deviceChkbox"
                            :key="user.deviceIdVal"
                            :value="user.deviceIdVal"
                            @change="disconnectDevice"
                        >{{$t("modals.delete_door")}}</b-form-checkbox>
                        <b-form-group class="mt-3">
                            <b-input-group>                                
                                <b-form-select
                                :disabled="!deviceChkbox || locationChkbox || compChkbox || keyChkbox || sysemChkbox"
                                v-model="$v.user.DeviceId.$model"
                                :state="validateState('DeviceId')"
                                id="deviceVals"
                                :options="getUnassignedDoorsList"
                                :placeholder="$t('keys.pick_doors')"
                                ></b-form-select>
                                <b-form-invalid-feedback
                                    id="deviceVals-feedback"
                                >
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{ $t("keys.pick_doors") }}
                                </b-form-invalid-feedback>                            
                            </b-input-group>
                        </b-form-group> 

                        <b-form-checkbox
                            v-model="keyChkbox"
                            :key="user.keyIdVal"
                            :value="user.keyIdVal"
                            @change="disconnectKey"
                        >{{$t("modals.delete_key")}}</b-form-checkbox>
                        <b-form-group class="mt-3">
                            <b-input-group>                                
                                <b-form-select
                                :disabled="!keyChkbox || locationChkbox || deviceChkbox || compChkbox || sysemChkbox"
                                v-model="$v.user.KeyId.$model"
                                :state="validateState('KeyId')"
                                id="keyVals"
                                :options="locationKeysOptions"
                                :placeholder="$t('keys.select_share_new')"
                                ></b-form-select>
                                <b-form-invalid-feedback
                                    id="keyVals-feedback"
                                >
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{ $t("keys.select_share_new") }}
                                </b-form-invalid-feedback>                            
                            </b-input-group>
                        </b-form-group> 

                        <b-form-checkbox
                            v-model="sysemChkbox"
                            :key="user.systemIdVal"
                            :value="user.systemIdVal"
                            @change="disconnectSystem"
                        >{{$t("modals.delete_system")}}</b-form-checkbox>
                        <b-form-group class="mt-3">
                            <b-form-input
                                :disabled="!sysemChkbox || locationChkbox || deviceChkbox || keyChkbox || compChkbox"
                                v-model="$v.user.UserId.$model"
                                :state="validateState('UserId')"
                                id="userVals"
                                type="text"    
                                :placeholder="this.getModalData.user.User.Name +'  -  '+ this.getModalData.user.User.PhoneNumber"                          
                                style="color: #95aac9; background-color: #edf2f9;">
                            </b-form-input>
                        </b-form-group>

                        <b-form-group v-if="this.getModalData.user.Role == 'CompanyOwner' && this.user.CompanyId != null">                            
                            <b-alert show variant="warning">
                                <table style="border-collapse: separate; border-spacing: 0 0">
                                    <tr>
                                        <td style="padding-right: 14px; vertical-align: top">
                                            <img :src="'/images/Group_5.png'" style="width: 19px; height: 19px;"/>
                                        </td>
                                        <td style="text-align: left !important; padding-right: 30px;">
                                            {{ $t("alerts.owner_delete") }}
                                        </td>
                                    </tr>
                                </table>
                            </b-alert>
                        </b-form-group>

                    </b-form>                 
                </b-col>
            </b-row>
            
        </b-container>
    </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { M_ADMIN_USER_ACCESS_DELETE } from "@/components/modals/types";
import { CLEAR_MODAL_DATA } from "@/store/types/global";
import {
    numeric,
} from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { ADMIN_DISCONNECT_USER }from "@/store/types/admin";

export default {
    mixins: [validationMixin],
    data() {
        return {
            type: M_ADMIN_USER_ACCESS_DELETE,
            devList: [],
            compChkbox: false,
            locationChkbox: false,
            deviceChkbox: false,
            keyChkbox: false,
            sysemChkbox: false,
            flagVal: false,
            companyList: [],
            user: {},
            submitted: false,
            userTemplate: {
                CompanyId: null,
                LocationId: null,
                DeviceId: null,
                KeyId: null,
                UserId: null,
                systemFlag: false,
            },
            filterLocation: "",
            filter: "",
        };
    },
    props: ["companyId"],
    validations: {
        user: {
            CompanyId: {
                numeric,
            },
            LocationId: {
                numeric,
            },
            DeviceId: {
                numeric,
            },
            KeyId: {
                numeric,
            },
            UserId: {
                numeric,
            },
            systemFlag: {},
        },
    },
    computed: {
        ...mapGetters([
            "getModalData", 
            "getModalType", 
            "getActiveLocation", 
            "getLocationDevices", 
            "getAllDevices", 
            "getConfiguredDevices", 
            "getSaltoOnlineDevices", 
            "getDevices", 
            "getAllAdminCompanies",
            "getAllLocations",
            "getAllKeys"
        ]), 
        getUserCompaniesOptions() {
            return [
                {
                    value: null,
                    text: this.$t("locations.company_select"),
                    disabled: true,
                },
                ...this.getAllAdminCompanies
                .filter((item) => item.Id == this.user.CompanyId)
                .sort((i1, i2) => {
                    return i1.Name.toUpperCase() > i2.Name.toUpperCase() ? 1 : -1;
                })
                .map((item) => {
                    return {
                        value: item.Id,
                        text: item.Name,
                    };
                }),
            ];
        },
        getLocationsOptions(){
                return [
                    {
                        value: null,
                        text: this.$t("menu.select_location"),
                        disabled: true,
                    },
                    ...this.getAllLocations                        
                        .filter((item) =>{
                            let filterLoc = true;                           
                            if(this.user.CompanyId != null){                                
                                filterLoc = item.Location.CompanyId == this.user.CompanyId
                            }else{
                                filterLoc = item.Location.Name.toLowerCase().includes(this.filterLocation.toLowerCase()) ||
                                item.Location.Address.toLowerCase().includes(this.filterLocation.toLowerCase())
                            }
                            return filterLoc;
                        })
                        .sort((i1, i2) => {
                            return i1.Location.Name.toUpperCase() > i2.Location.Name.toUpperCase() ? 1 : -1;
                        })
                        .map((item) => {                        
                            return { value: item.Location.Id, text: item.Location.Name }; 
                        }), 
                ];
        },
        getUnassignedDoorsList() {
            return [
                {
                    value: null,
                    text: this.$t("keys.pick_doors"),
                    disabled: true,
                },
                ...this.getAllDevices
                .filter((item) => item.Device.LocationId == this.getModalData.user.Location.Id)
                .sort((i1, i2) => {
                    return i1.Device.Name.toUpperCase() > i2.Device.Name.toUpperCase() ? 1 : -1;
                })
                .map((item) => {
                    return { value: item.Device.Id, text: item.Device.Name };
                }),
            ];
        }, 
        locationKeysOptions() {            
            return [
                {
                    value: null,
                    text: this.$t("keys.select_share_new"),
                    disabled: true,
                },
                ...this.getAllKeys
                .filter((item) => item.Key.LocationId == this.user.LocationId)
                .sort((i1, i2) => {
                    if(i1.Key.Name.includes("Key of: ")){
                        i1.Key.Name = i1.Key.Name.replace("Key of: ","");
                    }
                    if(i2.Key.Name.includes("Key of: ")){
                        i2.Key.Name = i2.Key.Name.replace("Key of: ","");
                    }
                    return i1.Key.Name.toUpperCase() > i2.Key.Name.toUpperCase() ? 1 : -1;
                })
                .map((item) => {
                    if(item.Key.Name.includes("Key of: ")){
                        item.Key.Name = item.Key.Name.replace("Key of: ","");
                    }
                    if(item.Key.Name.length > 20){
                        item.Key.Name = item.Key.Name.slice(0, 16).concat('...');
                    }
                    return { value: item.Key.Id, text: item.Key.Name };
                }),
            ];
        },
    },
    created(){
    },
    methods: {            
        setData() {
            if (this.getModalData !== null) {
                /* if(this.getModalData.user.Role == 'CompanyOwner'){
                    console.log("role:"+this.getModalData.user.UserId)
                } */
                
                this.user = {
                    CompanyId: this.getModalData.user.Location.CompanyId,
                    LocationId: this.getModalData.user.Location.Id,
                    DeviceId: this.user.DeviceId,
                    KeyId: this.user.KeyId,
                    UserId: this.user.UserId,
                    systemFlag: this.sysemChkbox,
                };   
            } 
            this.$v.user.$reset();                                       
        },
        validateState(name) {
            const { $dirty, $error } = this.$v.user[name];
            return $dirty && this.submitted ? !$error : null;
        },
        disconnectCompany() {
            if(this.compChkbox){
                this.flagVal = true
                this.locationChkbox = false
                this.deviceChkbox = false
                this.keyChkbox = false
                this.sysemChkbox = false
            }
            return this.user.CompanyId
        },
        disconnectLocation(){
            if(this.locationChkbox){
                this.flagVal = true
                this.compChkbox = false
                this.deviceChkbox = false
                this.keyChkbox = false
                this.sysemChkbox = false
            }
            return this.user.LocationId
        },
        disconnectDevice(){   
            if(this.deviceChkbox){
                this.flagVal = true
                this.locationChkbox = false
                this.compChkbox = false
                this.keyChkbox = false
                this.sysemChkbox = false
            }         
            return this.user.DeviceId
        },
        disconnectKey(){   
            if(this.keyChkbox){
                this.flagVal = true
                this.locationChkbox = false
                this.deviceChkbox = false
                this.compChkbox = false
                this.sysemChkbox = false
            }         
            return this.user.KeyId
        },
        disconnectSystem(){  
            if(this.sysemChkbox){
                this.flagVal = true
                this.locationChkbox = false
                this.deviceChkbox = false
                this.keyChkbox = false
                this.compChkbox = false
            }            
            return this.user.UserId
        },        
        disconnectUser() {
            // console.log("this.user.UserId 1:"+this.getModalData.user.User.Id)
            if(!this.compChkbox){
                this.user.CompanyId = null;
            }
            if(!this.locationChkbox){
                this.user.LocationId = null;
            }
            if(!this.deviceChkbox){
                this.user.DeviceId = null;
            }
            if(!this.keyChkbox){
                this.user.KeyId = null;
            }
            if(this.sysemChkbox){
                this.user.systemFlag = this.sysemChkbox
            }
          
            /* console.log("this.user.CompanyId:"+this.user.CompanyId)
            console.log("this.user.LocationId:"+this.user.LocationId)
            console.log("this.user.DeviceId:"+this.user.DeviceId)
            console.log("this.user.KeyId:"+this.user.KeyId)
            console.log("this.user.UserId:"+this.getModalData.user.User.Id)
            console.log("this.user.systemFlag:"+this.user.systemFlag) */

            this.user.UserId = this.getModalData.user.User.Id;

            let payload = { ...this.user }

            this.$store
                .dispatch(ADMIN_DISCONNECT_USER, payload)

        },
        onClose() {
            this.user = { ...this.userTemplate };
            this.submitted = false;
            this.compChkbox = false;
            this.locationChkbox = false;
            this.deviceChkbox = false;
            this.keyChkbox = false;
            this.sysemChkbox = false;
            this.flagVal = false;
            this.$v.user.$reset();
            this.$store.commit(CLEAR_MODAL_DATA);
        },
    },
};
</script>
<style scoped>
.btn.disabled, .btn:disabled{
    color:  #6e84a3;
    border-color: #6e84a3;
} 

.alert-warning {
    color: #856404 !important;
    background-color: #fff3cd !important;
    border-color: #ffeeba !important;
}
</style>

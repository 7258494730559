<template>
    <b-modal
        :id="type"
        :title="$t('modals.title_remove_user')"
        :ok-title="$t('buttons.delete')"
        :cancel-title="$t('buttons.cancel')"
        ok-variant="outline-danger"
        modal-class="fixed-right"
        dialog-class="modal-dialog-vertical"
        @ok="deleteUser"
        @show="setData"
        @hide="onClose"
    >
       <b-container fluid v-if="type == getModalType">
            <b-row>
                <b-col cols="12">
                    {{
                        $t("modals.text_delete_multiple_user", {
                            locationName: location
                        })
                    }}
                        <div                             
                            v-for="user in userList"
                            v-bind:key="user.User.Id"
                            :user="user"
                            style="display: list-item;"
                        >
                            {{ user.User.FirstName +' '+ user.User.LastName }} 
                        </div>  
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>

<script>
import {mapGetters} from "vuex";
import {ADMIN_MULTPLE_USER_DELETE, ADMIN_USERS_GET} from "@/store/types/admin";
import {M_ADMIN_LOCATION_MULTIPLE_USER_DELETE} from "@/components/modals/types";
import {CLEAR_MODAL_DATA} from "@/store/types/global";
import { EventBus } from "@/main.js"

export default {
    data() {
        return {
            type: M_ADMIN_LOCATION_MULTIPLE_USER_DELETE,
            userList: [],
            userIds: [],
            location: "",
            locationId: null,
            company: "",
            user: {},
        };
    },
    computed: {
        ...mapGetters(["getModalData", "getModalType", "getAllUsers"]),
    },
    methods: {        
        setData() { 
            this.userList = []
            this.userList = JSON.parse(this.$session.get("userList"))

            if(this.userList.length > 0){
                for(let i=0;i<this.userList.length;i++){
                    this.location = this.userList[0].Location.Name
                    this.locationId = this.userList[0].Location.Id
                    if (this.userList[i].User.FirstName == null || this.userList[i].User.FirstName == "null" || this.userList[i].User.FirstName == "" || this.userList[i].User.FirstName == undefined) {
                        this.userList[i].User.FirstName = '';
                    }
                    if (this.userList[i].User.LastName == null || this.userList[i].User.LastName == "null" || this.userList[i].User.LastName == "" || this.userList[i].User.LastName == undefined) {
                        this.userList[i].User.LastName = '';
                    }
                    this.userIds.push(this.userList[i].User.Id)
                } 
            }         
        },
        deleteUser() {
            this.$store
                .dispatch(ADMIN_MULTPLE_USER_DELETE, {
                    locationId: this.locationId,
                    patch: this.userIds,
                })
                .then(() => {   
                    this.userList = [];
                    this.$session.set("userList", JSON.stringify(this.userList))
                    this.$session.set("userFlag", true)                    
                    this.$store.dispatch(ADMIN_USERS_GET);
                    this.$bvModal.hide(this.type);              
                });                
        },
        onClose() {
            this.user = null;
            this.userList = [];
            this.userIds = [];
            this.location = "";
            this.locationId = null;
            this.company = "";
            this.$store.commit(CLEAR_MODAL_DATA);
        },
    },
};
</script>

<template>
    <b-col cols="12">
        <div class="card mb-1">
            <div class="card-body">
                <div class="row align-items-center">
                    <div class="col-auto">
                        <!-- Avatar -->
                        <router-link
                            v-if="device.Device.ProviderType == 5 && device.OnlineDevice.OnlineState == true"
                            class="avatar avatar-online-new avatar-lg"
                            :to="{
                                name: 'door',
                                params: {
                                    locationId: device.Device.LocationId,
                                    doorId: device.Device.Id,
                                    companyId: device.Device.CompanyId,
                                },
                            }"
                        >
                            <img
                                :src="
                                    device.Device.SmallImageUrl &&
                                    device.Device.SmallImageUrl !=
                                        'https://zesec-prod.s3.eu-central-1.amazonaws.com//_150x150'
                                        ? device.Device.SmallImageUrl
                                        : '/images/zesec-placeholder.png'
                                "
                                alt="..."
                                class="avatar-img rounded"
                            />
                        </router-link>
                        <router-link
                            v-else-if="device.Device.ProviderType == 5 && device.OnlineDevice.OnlineState == false"
                            class="avatar avatar-offline-new avatar-lg"
                            :to="{
                                name: 'door',
                                params: {
                                    locationId: device.Device.LocationId,
                                    doorId: device.Device.Id,
                                    companyId: device.Device.CompanyId,
                                },
                            }"
                        >
                            <img
                                :src="
                                    device.Device.SmallImageUrl &&
                                    device.Device.SmallImageUrl !=
                                        'https://zesec-prod.s3.eu-central-1.amazonaws.com//_150x150'
                                        ? device.Device.SmallImageUrl
                                        : '/images/zesec-placeholder.png'
                                "
                                alt="..."
                                class="avatar-img rounded"
                            />
                        </router-link>
                        <router-link
                            v-else
                            class="avatar avatar-lg"
                            :to="{
                                name: 'door',
                                params: {
                                    locationId: device.Device.LocationId,
                                    doorId: device.Device.Id,
                                    companyId: device.Device.CompanyId,
                                },
                            }"
                        >
                            <img
                                :src="
                                    device.Device.SmallImageUrl &&
                                    device.Device.SmallImageUrl !=
                                        'https://zesec-prod.s3.eu-central-1.amazonaws.com//_150x150'
                                        ? device.Device.SmallImageUrl
                                        : '/images/zesec-placeholder.png'
                                "
                                alt="..."
                                class="avatar-img rounded"
                            />
                        </router-link>
                    </div>
                    <div class="col ml-n2">
                        <!-- Title -->
                        <h4 class="mb-1">
                            <router-link
                                :to="{
                                    name: 'door',
                                    params: {
                                        locationId: device.Device.LocationId,
                                        doorId: device.Device.Id,
                                        companyId: device.Device.CompanyId,
                                    },
                                }"
                                >{{ device.Device.Name }}</router-link
                            >
                        </h4>

                        <!-- Text -->
                        <p class="card-text small text-muted mb-1">
                            {{
                                getDeviceProvider(device.Device.ProviderType)
                                    .key
                            }}
                        </p>
                        <p>
                            <small>
                            {{
                                $t("common.count_keys", {
                                    count: device.Keys
                                        ? device.Keys.length
                                        : device.KeysCount,
                                })
                            }}
                        </small>
                        </p>
                    </div>
                    <div class="col-auto">
                        <b-dropdown
                            variant="outline"
                            toggle-class="dropdown-ellipses dropdown-toggle"
                            no-caret
                            right
                            toggle-tag="a"
                        >
                            <template v-slot:button-content>
                                <img :src="'/images/settings_grey_32x32dp.png'" style="width: 19px; height: 19px;"/>
                            </template>
                            <b-dropdown-item
                                class="text-left text-truncate"
                                @click="editDevice(device)"
                                >{{ $t("buttons.edit") }}</b-dropdown-item
                            >
                            <b-dropdown-item
                                class="text-left text-truncate"
                                @click="deleteDevice(device)"
                                >{{ $t("buttons.delete") }}</b-dropdown-item
                            >
                            <b-dropdown-item
                                class="text-left text-truncate"
                                @click="deviceImage(device)"
                                >{{ $t("doors.image") }}</b-dropdown-item
                            >
                            <b-dropdown-item
                                class="text-left text-truncate"
                                @click="deviceQR(device)"
                                v-if="device.Device.ProviderType == 3 || device.Device.ProviderType == 5 || device.Device.ProviderType == 6"
                            >{{$t("doors.generate_qr")}}</b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
            </div>
        </div>
    </b-col>
</template>
<script>
import { getDeviceProvider } from "@/handlers/enums";
import DeleteDeviceModal from "@/components/modals/DeleteDevice";
import ImageUploadModal from "@/components/modals/ImageUpload";
import { SET_MODAL_DATA } from "@/store/types/global";
import { DEVICE_PATCH, DEVICE_GET, DEVICE_CODE_GET } from "@/store/types/devices";
import DeviceQRModal from "@/components/modals/DeviceQR";
import {
    ADMIN_DEVICE_PATCH,
    ADMIN_DEVICES_GET,
} from "@/store/types/admin";
import {
    M_DEVICE_ADMIN_DELETE,
    M_DEVICE_ADMIN_FORM,
    M_IMAGE_UPLOAD,
    M_DEVICE_QR,
} from "@/components/modals/types";

import { IMAGE_TYPE_DOOR } from "@/handlers/const";
import modalMixin from "@/mixins/modalMixin";
import { mapGetters } from "vuex";

export default {
    mixins: [modalMixin],
    name: "door-card-item-admin",
    data() {
        return {
            imagePath: '',
            deviceSelected: [],
        };
    },
    props: ["device"],
    computed: {
        ...mapGetters([
            "getActiveDevice",
        ]),
    },
    created() {},
    methods: {
        deviceQR(device) {
            this.showModal(
                { device: device.Device },
                M_DEVICE_QR
            );
        },
        getDeviceProvider(id) {
            return getDeviceProvider(id);
        },
        editDevice(device) {
            this.showModal(
                { device: device.Device },
                M_DEVICE_ADMIN_FORM
            );
        },
        deleteDevice(device) {
            if(device.Device.Name){
                this.showModal(
                    { device: device.Device },
                    M_DEVICE_ADMIN_DELETE
                );
            }
        },
        deviceImage(device) {
            this.device = device
            this.$session.set("deviceId", this.device.Device.Id);
            this.$session.set("device",this.device.Device)
            // let id = device.Device.Id;
            // this.deviceSelected = this.$store.dispatch(DEVICE_GET, this.device.Device.Id);
            this.showModal(
                {
                    image: {
                        url: this.device.Device.MediumImageUrl || "/images/zesec-placeholder.png",
                        type: IMAGE_TYPE_DOOR,
                    },
                },
                M_IMAGE_UPLOAD
            );
        },
        updateDoorImage(response) {
            this.device = this.$session.get("device");
            this.$store
                .dispatch(ADMIN_DEVICE_PATCH, {
                    ...this.device,
                    ...{ ImageId: response.Id },
                })
                .then(() => {
                   this.$store.dispatch(ADMIN_DEVICES_GET);
                })
                .catch((error) => {});
        },
    },
    components: {

    },
};
</script>
<style scoped>
.btn:focus{
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
}
.list-group-item:last-child{
  overflow-y: auto !important;
}
</style>

<template>
<!-- class="flex-column align-items-start" -->
    <b-list-group-item>
        <div class="row align-items-center" style="height: 100px;">
            <div class="col-auto">
                <!-- Avatar -->
                <router-link
                    :to="{
                        name: 'company',
                        params: {
                            companyId: company.Id,
                        },
                    }"
                    class="avatar avatar-lg"
                    ><img
                        :src="
                            company.SmallImageUrl ||
                            '/images/zesec-placeholder.png'
                        "
                        class="avatar-img rounded"
                /></router-link>
            </div>
            <div class="col ml-n2">
                <!-- Title -->
                <h4 class="mb-1 name">
                    <router-link
                        :to="{
                            name: 'company',
                            params: {
                                companyId: company.Id,
                            },
                        }"
                        >{{ company.Name }}</router-link
                    >
                </h4>
            </div>
           <div class="col-auto">
                <!-- Dropdown -->
                <b-dropdown
                    variant="outline"
                    toggle-class="dropdown-ellipses dropdown-toggle"
                    no-caret
                    right
                    toggle-tag="a"
                >
                    <template v-slot:button-content>
                        <!-- <i class="fe fe-more-vertical"></i> -->
                        <img :src="'/images/settings_grey_32x32dp.png'" style="width: 19px; height: 19px;"/>
                    </template>
                    <b-dropdown-item
                        class="text-left text-truncate"
                        @click="editCompany"
                        >{{ $t("buttons.edit") }}</b-dropdown-item
                    >
                    <b-dropdown-item
                        class="text-left text-truncate"
                        @click="deleteCompany(company.Id)"
                        >{{ $t("buttons.delete") }}</b-dropdown-item
                    >
                    <b-dropdown-item
                        class="text-left text-truncate"
                        @click="companyImage(company)"
                        >{{ $t("companies.image") }}</b-dropdown-item
                    >
                   <!--  {{ $t("companies.update") }} -->
                </b-dropdown>
            </div>
        </div>
        <!-- / .row -->
    </b-list-group-item>
</template>
<script>
import { M_COMPANY_ADMIN_FORM, M_COMPANY_ADMIN_DELETE, M_IMAGE_UPLOAD } from "@/components/modals/types";
import { IMAGE_TYPE_COMPANY } from "@/handlers/const";
import { SET_MODAL_DATA } from "@/store/types/global";
import modalMixin from "@/mixins/modalMixin";
import DeleteCompanyModal from "@/components/modals/DeleteCompany";
import ImageUploadModal from "@/components/modals/ImageUpload";
import {
    COMPANY_PATCH,
    COMPANY_GET,
} from "@/store/types/companies";
import {
    ADMIN_COMPANY_PATCH,
    ADMIN_COMPANIES_GET
} from "@/store/types/admin";
export default {
    name: "user-company-item-admin",
    mixins: [modalMixin],
    props: ["company"],
    methods: {
        editCompany() {
            this.$store.commit(SET_MODAL_DATA, {
                data: { company: this.company },
                type: M_COMPANY_ADMIN_FORM,
            });
            this.$bvModal.show(M_COMPANY_ADMIN_FORM);
        },
        deleteCompany(id) {
            if(id == this.company.Id){
                this.$store.commit(SET_MODAL_DATA, {
                    data: { company: this.company },
                    type: M_COMPANY_ADMIN_DELETE,
                });
                this.$bvModal.show(M_COMPANY_ADMIN_DELETE);
            }
        },
        companyImage(companySelected) {
            this.company = companySelected;
            this.$session.set("companyId", companySelected.Id);
            this.$session.set("company",this.company)
            if(this.company.Id == companySelected.Id){
                this.showModal(
                    {
                        image: {
                            url: this.company.MediumImageUrl ||
                            "/images/zesec-placeholder.png",
                            type: IMAGE_TYPE_COMPANY,
                        },
                    },
                    M_IMAGE_UPLOAD
                );
            }            
        },
        updateCompanyImage(response) { 
            let cid = this.$session.get("companyId") 
            /* this.company = this.$session.get("company");            
                this.$store
                    .dispatch(ADMIN_COMPANY_PATCH, {
                    ...this.company,
                    ...{ ImageId: response.Id },
                    })
                    .then(() => {
                       this.$store.dispatch(ADMIN_COMPANIES_GET);
                    })
                    .catch((error) => {});   */  
                this.company = this.$session.get("company");     
                if(this.company.Owner.FirstName == null || this.company.Owner.FirstName == "null" || this.company.Owner.FirstName == "" || this.company.Owner.FirstName == undefined){
                    this.company.Owner.FirstName = '';
                }
                if(this.company.Owner.LastName == null || this.company.Owner.LastName == "null" || this.company.Owner.LastName == "" || this.company.Owner.LastName == undefined){
                    this.company.Owner.LastName = '';
                }
                this.company.OwnerName = this.company.Owner.FirstName +" "+ this.company.Owner.LastName; 
                let payload = {
                    ...this.company,
                    ...{ OwnerPhoneNumber: this.company.Owner.PhoneNumber },
                    ...{ CompanyPhoneNumber: this.company.PhoneNumber },
                    ...{ ImageId: response.Id },
                };      
                this.$store
                    .dispatch(ADMIN_COMPANY_PATCH, payload)
                    .then(() => {
                       this.$store.dispatch(ADMIN_COMPANIES_GET);
                    })
                    .catch((error) => {});  
        }
    },
    components: {
    }
};
</script>
<style scoped>
.list-group-item:last-child{
  overflow-y: auto !important;
}
.btn:focus{
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
}
</style>
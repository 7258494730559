<template>
    <b-modal
        :id="type"
        :title="$t('staff.invite')"
        size="lg"
        hide-footer
        modal-class="fixed-right"
        dialog-class="modal-dialog-vertical"
        @show="setData"
        @hide="onClose"
    >
        <b-container fluid>
            <b-row>
                <b-col cols="12">
                    <b-form>
                        <b-form-group
                            :label="$t('staff.name')"
                            label-cols-sm="2"
                            label-align-sm="right"
                            label-size="sm"
                        >
                            <b-form-input
                                v-model="$v.invite.Name.$model"
                                :state="validateState('Name')"
                                id="employeeName"
                                disabled
                                :placeholder="$t('staff.name')"
                            />
                            <b-form-invalid-feedback id="employeeName-feedback">
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                {{
                                    $t("errors.validation_length_range", {
                                        range: "3-50",
                                    })
                                }}
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group
                            :label="$t('users.phone')"
                            label-cols-sm="2"
                            label-align-sm="right"
                            label-size="sm"
                        >
                            <vue-phone-number-input
                                v-model="$v.invite.PhoneNumber.$model"
                                id="employeePhone"
                                :error="!validPhone && submitted"
                                :translations="$t('phoneInput')"
                                @update="validatePhone"
                                required
                                disabled
                                valid-color="#28a745"
                                error-color="#dc3545"
                                fetch-country
                                type="text"
                            />
                            <span id="employeePhone-feedback" v-if="!validPhone && submitted"  style="color: #e63857;  font-size: 0.8125rem">
                               <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                {{ $t("errors.validation_required") }}
                            </span>
                        </b-form-group>

                        <b-form-group
                            :label="$t('staff.role')"
                            label-cols-sm="2"
                            label-align-sm="right"
                            label-size="sm"
                        >
                            <b-form-select
                                v-model="$v.invite.RoleType.$model"
                                :state="validateState('RoleType')"
                                id="Role"
                                value-field="Id"
                                :options="rolesOptions"
                            ></b-form-select>
                            <b-form-invalid-feedback id="inviteKey-feedback">
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                {{
                                    $t("staff.role_select")
                                }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col>
                    <b-button class="input-field" @click.prevent.stop="changeRole">{{
                        $t("staff.change_role")
                    }}</b-button>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>

<script>
import { mapGetters } from "vuex";

import { M_LOCATION_CHANGE_ROLE_ADMIN_FORM } from "@/components/modals/types";
import {
    ACL_CREATE_LOCATION_MANAGER_ROLE,
    ACL_INVITE_TO_COMPANY_ROLE,
    ACL_INVITE_TO_LOCATION_ROLE,
} from "@/store/types/acl";

import {
    LOCATION_MEMBERS_GET,
    LOCATION_INVITES_GET,
} from "@/store/types/members";

import { COMPANY_USER_DELETE } from "@/store/types/companies";
import { LOCATION_USER_DELETE } from "@/store/types/locations";
import { CLEAR_MODAL_DATA } from "@/store/types/global";
import { validationMixin } from "vuelidate";
import {
    definedRoles,
    definedRolesArray,
    ROLE_COMPANY_OWNER,
    ROLE_COMPANY_MANAGER,
    ROLE_LOCATION_MANAGER,
    ROLE_DEVICE_INSTALLER,
    ROLE_KEY_PERMANENT,
    ROLE_KEY_TEMPORARY,
    ROLE_KEY_MANAGER,
} from "@/acl/roles";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import VuePhoneNumberInput from "vue-phone-number-input";

import aclController from "@/api/acl";

export default {
    mixins: [validationMixin],
    data() {
        return {
            type: M_LOCATION_CHANGE_ROLE_ADMIN_FORM,
            inviteTemplate: {
                Name: "",
                PhoneNumber: "",
                RoleType: null,
                CompanyId: null,
                LocationId: null,
            },
            validPhone: false,
            fullPhone: null,
            submitted: false,
            invite: {},
        };
    },
    props: ["companyId", "locationId"],
    validations: {
        invite: {
            Name: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(50),
            },
            PhoneNumber: {
                required,
                phone: function () {
                    return this.validPhone;
                },
            },
            RoleType: { required },
        },
    },
    computed: {
        ...mapGetters(["getModalData", "getModalType", "getCompanyLocations",]),

        rolesOptions() {
            return [
                {
                    value: null,
                    text: this.$t("staff.role_select"),
                    disabled: true,
                },
                ...definedRolesArray().filter((item) => {
                    if (this.$route.params.locationId) {                        
                        return (
                                this.$can("invite", item) &&
                                [
                                    ROLE_LOCATION_MANAGER,
                                    ROLE_KEY_MANAGER,
                                    ROLE_DEVICE_INSTALLER,
                                ].includes(item.value)
                            );                        
                    }
                }),
            ];
        },
    },
    created() {
        this.setData();
    },
    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.invite[name];
            return $dirty && this.submitted ? !$error : null;
        },
        validatePhone(phone) {
            this.validPhone = phone.isValid;
            this.fullPhone = phone;
        },

        changeRole() {     
            this.submitted = true;
            this.$v.invite.$touch();
            if (this.$v.invite.$anyError) {
                return;
            }       
                this.$store
                 .dispatch(LOCATION_USER_DELETE, {
                    userId: this.getModalData.user.Id,
                    locationId: this.$route.params.locationId,
                    ...{ norefresh: true },
                })
                .then(() => {
                    this.inviteEmployee();
                });
        },

        inviteEmployee: function () {
            this.submitted = true;
            this.$v.invite.$touch();
            if (this.$v.invite.$anyError) {
                return;
            }

            this._createLocationManagerRole();
        },
        _createLocationManagerRole() {
            let payload = {
                ...this.invite,
                ...{
                    PhoneNumber: this.fullPhone.formattedNumber,
                },
            };
            this.$bvModal.hide(this.type);

            this.$store
                .dispatch(ACL_INVITE_TO_LOCATION_ROLE, payload)
                .then(() => {
                    this.$store.dispatch(
                        LOCATION_MEMBERS_GET,
                        this.$route.params.locationId
                    );
                    this.$store.dispatch(
                        LOCATION_INVITES_GET,
                        this.$route.params.locationId
                    );
                });
        },

        setData() {
            this.invite = {
                ...this.inviteTemplate,
                ...{
                    CompanyId: this.companyId,
                    LocationId: this.locationId,
                    Name: this.getModalData ? this.getModalData.user.Name : "",
                    PhoneNumber: this.getModalData
                        ? this.getModalData.user.PhoneNumber
                        : "",
                },
            };
        },
        onClose() {
            this.invite = { ...this.inviteTemplate };
            this.submitted = false;
            this.$v.invite.$reset();
            this.$store.commit(CLEAR_MODAL_DATA);
        },
    },
    components: {
        "vue-phone-number-input": VuePhoneNumberInput,
    },
};
</script>

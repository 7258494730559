<template>
    <b-modal
        :id="type"
        :title="$t('buttons.disconnect_door')"
        :ok-title="$t('buttons.disconnect_door_new')"
        :cancel-title="$t('buttons.cancel')"
        ok-variant="outline-danger"
        modal-class="fixed-right"
        dialog-class="modal-dialog-vertical"        
        @ok="disconnectDevices"
        @show="setData"
        @hide="onClose"   
        :ok-disabled="addList.length == 0"     
    >
     <!-- :hide-footer="this.devList.length > 0" -->
        <b-container fluid v-if="(type == getModalType) && (this.devList.length > 0)">
            <b-row>
                <b-col cols="12">
                    <strong>{{ $t("doors.doors_connected") }}</strong>                   
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">                   
                    <b-form-checkbox
                        v-model="addList"
                        v-for="dev in devList"
                        :key="dev.id"
                        :value="dev.id"
                        @change="disconnectDoor"
                    >
                        {{ dev.text }}
                    </b-form-checkbox>      
                    <!-- <div class="user" v-for="dev in devList" :key="dev.id">                        
                        <input type="checkbox" :value="dev.id" v-model="addList" />
                        {{ dev.text }}
                    </div> -->                   
                </b-col>
            </b-row>           
        </b-container>
    </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { M_DISCONNECT_DEVICE } from "@/components/modals/types";
import { CLEAR_MODAL_DATA } from "@/store/types/global";
import { ADMIN_DISCONNECT_MULTIPLE_DEVICES, ADMIN_KEYS_GET } from "@/store/types/admin";


export default {
    data() {
        return {
            type: M_DISCONNECT_DEVICE,
            devList: [],
            addList: [],
            deviceId: null,
            locationId: null,
            keyId: null,
            key: {},
            filter: "",
        };
    },
    computed: {
        ...mapGetters(["getModalData", "getModalType", "getActiveLocation", "getLocationDevices", "getAllDevices", "getConfiguredDevices", "getSaltoOnlineDevices", "getDevices"]),        
        getUnassignedDoorsList() {
            return[ 
            ...this.getLocationDevices
                .filter((item) =>{
                    item.Device.Name
                    .toLowerCase()
                    .includes(this.filter.toLowerCase())
                })
                .sort((i1, i2) => {
                    return i1.Device.Name.toUpperCase() > i2.Device.Name.toUpperCase() ? 1 : -1;
                })
                .map((item) => {  
                    // console.log("resp:"+item.Device.Name)  
                    return { id: item.Device.Id, text: item.Device.Name };               
                }) 
            ]
        }, 
    },
    created(){
        // this.$store.dispatch(DEVICES_GET, this.$session.get("locationId"));        
    },
    methods: {            
        setData() {    
            this.key = this.getModalData.key; 
            this.devList = [];
            this.addList = [];
            this.locationId = this.getModalData.key.Key.LocationId;
            this.keyId = this.getModalData.key.Key.Id;
            if(this.getModalData.key.Devices.length > 0){
                for(let i=0;i<this.getModalData.key.Devices.length;i++){
                    this.devList.push({
                        text: this.getModalData.key.Devices[i].Device.Name,
                        id: this.getModalData.key.Devices[i].Device.Id,
                    })                                   
                }
            }                                      
        },
        disconnectDoor() {
            return this.addList
        },
        disconnectDevices() {
            if(this.addList.length != 0){
                this.$store
                .dispatch(ADMIN_DISCONNECT_MULTIPLE_DEVICES, {
                    keyId: this.keyId,
                    patch: this.addList,
                })
                .then(() => {
                    this.$bvModal.hide(this.type);
                    this.$store.dispatch(ADMIN_KEYS_GET);
                })
                .catch((error) => {});          
            }
        },
        onClose() {
            this.salto = null;
            this.$store.commit(CLEAR_MODAL_DATA);
        },
    },
};
</script>
<style scoped>
.btn.disabled, .btn:disabled{
    color:  #6e84a3;
    border-color: #6e84a3;
} 
</style>

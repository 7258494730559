<template>
    <b-modal
        :id="type"
        :title="$t('modals.title_revoke_key')"
        :ok-title="$t('buttons.delete')"
        :cancel-title="$t('buttons.cancel')"
        ok-variant="outline-danger"
        modal-class="fixed-right"
        dialog-class="modal-dialog-vertical"
        @ok="deleteUser"
        @show="setData"
        @hide="onClose"
    >
    <!-- $t("modals.text_revoke_key", {
                            keyName: getModalData.user.Key.Name,
                            userName: getModalData.user.Receiver.Name,
                        }) -->
        <b-container fluid v-if="type == getModalType">
            <b-row>
                <b-col cols="12">
                    <!-- userName: getModalData.user.Receiver.Name, -->
                    <!-- {{
                        $t("modals.text_revoke_key", {
                            keyName: getModalData.user.Key.Name,
                            userName: getModalData.user.Receiver.FirstName + " " + getModalData.user.Receiver.LastName,
                        })
                    }}  -->
                    {{
                        $t("modals.text_revoke_key", {
                            keyName: getModalData.Key.Name,
                            userName: getModalData.User.FirstName + " " + getModalData.User.LastName,
                        })
                    }} 
                    <span class="bold-font-style">{{
                        $t("modals.are_you_sure")
                    }}</span>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { KEY_USER_REMOVE } from "@/store/types/keys";
import { M_DELETE_KEY_USER_ADMIN } from "@/components/modals/types";
import { CLEAR_MODAL_DATA } from "@/store/types/global";

export default {
    data() {
        return {
            type: M_DELETE_KEY_USER_ADMIN,
            user: {},
        };
    },
    computed: {
        ...mapGetters(["getModalData", "getModalType"]),
    },

    methods: {
        setData() {           
            this.user = {...this.getModalData};
            if (this.user.User.FirstName == null || this.user.User.FirstName == "null" || this.user.User.FirstName == "") {
                this.user.User.FirstName = '';
            }
            if (this.user.User.LastName == null || this.user.User.LastName == "null" || this.user.User.LastName == "") {
                this.user.User.LastName = '';
            }
        },
        deleteUser() {            
            this.$store.dispatch(KEY_USER_REMOVE, {
                keyId: this.getModalData.user.Key.Id,
                userId: this.getModalData.user.User.Id,
                locationId: this.getModalData.user.Key.LocationId,
            });
        },
        onClose() {
            this.$store.commit(CLEAR_MODAL_DATA);
            this.user = null;
        },
    },
};
</script>

<template>
    <b-modal
        :id="type"
        :title="$t('keys.key')"
        size="lg"
        hide-footer
        modal-class="fixed-right"
        dialog-class="modal-dialog-vertical"
        @show="onShow"
        @hide="onClose"
    >
        <b-container fluid>
            <b-row>
                <b-col cols="12" style="padding-left: 54px !important;">
                    <b-form>
                        <!-- label-cols-sm="2"
                        label-align-sm="right"
                        label-size="sm" -->
                        <b-form-group
                            :label="$t('keys.name')"
                        >
                            <b-form-input
                                v-model="$v.key.Name.$model"
                                :state="validateState('Name')"
                                id="keyName"
                                :placeholder="$t('keys.name')"
                            />
                            <b-form-invalid-feedback id="keyName-feedback">
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                {{
                                    $t("errors.validation_length_range", {
                                        range: "3-200",
                                    })
                                }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group
                            :label="$t('companies.company')"
                        >
                            <b-input-group>
                                <!-- <b-form-select
                                    v-model="$v.key.CompanyId.$model"
                                    :state="validateState('CompanyId')"
                                    id="companyType"
                                    v-if="getCompaniesOptions.length > 15"
                                    :options="getCompaniesOptions"
                                    onfocus='this.size=10, this.height=2'
                                    onchange='this.size=10, this.height=2, this.blur();'
                                    onblur='this.size=10, this.height=2'
                                    onfocusout='this.size=null;'
                                ></b-form-select> -->
                                <b-form-select
                                    v-model="$v.key.CompanyId.$model"
                                    :state="validateState('CompanyId')"
                                    id="companyType"
                                    :options="getCompaniesOptions"
                                ></b-form-select>
                                <b-form-invalid-feedback
                                    id="companyType-feedback"
                                >
                                    <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{ $t("locations.company_select") }}
                                </b-form-invalid-feedback>
                            </b-input-group>
                        </b-form-group>
                        <b-form-group
                            :label="$t('locations.location')"
                        >
                            <b-input-group>
                                <!--  <b-form-select
                                     v-model="$v.key.LocationId.$model"
                                     :state="validateState('LocationId')"
                                     id="locationType"
                                     v-if="getLocationsOptions.length > 15"
                                     :options="getLocationsOptions"
                                     onfocus='this.size=10, this.height=2'
                                     onchange='this.size=10, this.height=2, this.blur();'
                                     onblur='this.size=10, this.height=2'
                                     onfocusout='this.size=null;'
                                 ></b-form-select> -->
                                <b-form-select
                                    v-model="$v.key.LocationId.$model"
                                    :state="validateState('LocationId')"
                                    id="locationType"
                                    :options="getLocationsOptions"
                                ></b-form-select>
                                <b-form-invalid-feedback
                                    id="locationType-feedback"
                                >
                                    <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{ $t("menu.select_location") }}
                                </b-form-invalid-feedback>
                            </b-input-group>
                        </b-form-group>
                        <b-form-group
                            :label="$t('doors.doors')"
                            v-if="createMode"
                        >
                            <b-input-group>
                                <!-- <b-form-select
                                    v-model="$v.key.DeviceId.$model"
                                    :state="validateState('DeviceId')"
                                    id="deviceType"
                                    v-if="getUnassignedDoorsList.length > 15"
                                    :options="getUnassignedDoorsList"
                                    onfocus='this.size=10, this.height=2'
                                    onchange='this.size=10, this.height=2, this.blur();'
                                    onblur='this.size=10, this.height=2'
                                    onfocusout='this.size=null;'
                                ></b-form-select> -->
                                <b-form-select
                                    v-model="$v.key.DeviceId.$model"
                                    :state="validateState('DeviceId')"
                                    id="deviceType"
                                    :options="getUnassignedDoorsList"
                                ></b-form-select>
                                <b-form-invalid-feedback
                                    id="deviceType-feedback"
                                >
                                    <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{ $t("menu.select_door") }}
                                </b-form-invalid-feedback>
                            </b-input-group>
                        </b-form-group>
                        <b-form-group
                            v-if="!createMode && getModalData.key.Devices.length > 0"
                            :label="$t('doors.doors_connected')"
                        >
                            <div
                                v-for="dev in this.devList"
                                v-bind:key="dev.Id"
                                :device="dev"
                                style="display: list-item;"
                            >
                                {{ dev.text }}
                            </div>
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <!-- class="mt-3" -->
                <b-col style="padding-left: 54px !important;">
                    <b-button
                        class="input-field mt-10"
                        @click.prevent.stop="postKey"
                        v-if="createMode"
                    >{{ $t("keys.create") }}</b-button
                    >
                    <b-button
                        class="input-field mt-10"
                        @click.prevent.stop="editKey"
                        v-else
                    >{{ $t("keys.update") }}</b-button
                    >
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { keyAccessTypes, weekDays } from "@/handlers/enums";
import {
    KEY_DEVICES_ADD,
} from "@/store/types/keys";
import {
    ADMIN_KEY_CREATE,
    ADMIN_KEY_PATCH,
    ADMIN_KEYS_GET,
    ADMIN_KEY_DEVICES_ADD
} from "@/store/types/admin";

import { CLEAR_MODAL_DATA } from "@/store/types/global";
import { validationMixin } from "vuelidate";
import { M_KEY_FORM_ADMIN } from "@/components/modals/types";
import Select2 from "@/components/common/Select2";
import {
    required,
    minLength,
    maxLength,
    numeric,
} from "vuelidate/lib/validators";

export default {
    mixins: [validationMixin],
    data() {
        return {
            type: M_KEY_FORM_ADMIN,
            createMode: true,
            key: {},
            submitted: false,
            keyDevices: null,
            filter: "",
            filterLocation: "",
            devList: [],
        };
    },
    validations: {
        key: {
            Name: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(200),
            },
            CompanyId: {
                required,
                numeric,
            },
            LocationId: {
                required,
                numeric,
            },
            DeviceId: {
                numeric,
            },
        },
    },
    props: ["locationId"],
    computed: {
        ...mapGetters(["getModalData", "getModalType", "getLocationDevices", "getAllDevices", "getAllAdminCompanies", "getAllLocations"]),
        keyTemplate() {
            return {
                Name: "",
                CompanyId: null,
                LocationId: null,
                DeviceId: null,
            };
        },
        getUnassignedDoorsList() {
            return [
                {
                    value: null,
                    text: this.$t("keys.pick_doors"),
                    disabled: true,
                },
                ...this.getAllDevices
                    .filter((item) =>{
                        let filterDevice = true;
                        if(this.key.LocationId != null){
                            filterDevice = item.Device.LocationId == this.key.LocationId
                        }else{
                            filterDevice = item.Device.Name.toLowerCase().includes(this.filter.toLowerCase())
                        }
                        return filterDevice;
                    })
                    .sort((i1, i2) => {
                        return i1.Device.Name.toUpperCase() > i2.Device.Name.toUpperCase() ? 1 : -1;
                    })
                    .map((item) => {
                        return { value: item.Device.Id, text: item.Device.Name };
                    }),
            ];
        },
        getCompaniesOptions() {
            return [
                {
                    value: null,
                    text: this.$t("locations.company_select"),
                    disabled: true,
                },
                ...this.getAllAdminCompanies
                    .filter((item) =>
                        item.Name
                            .toLowerCase()
                            .includes(this.filter.toLowerCase())
                    )
                    .sort((i1, i2) => {
                        return i1.Name.toUpperCase() > i2.Name.toUpperCase() ? 1 : -1;
                    })
                    .map((item) => {
                        return {
                            value: item.Id,
                            text: item.Name,
                        };
                    }),
            ];
        },
        getLocationsOptions(){
            return [
                {
                    value: null,
                    text: this.$t("menu.select_location"),
                    disabled: true,
                },
                ...this.getAllLocations
                    .filter((item) =>{
                        let filterLoc = true;
                        if(this.key.CompanyId != null){
                            filterLoc = item.Location.CompanyId == this.key.CompanyId
                        }else{
                            filterLoc = item.Location.Name.toLowerCase().includes(this.filterLocation.toLowerCase()) ||
                                item.Location.Address.toLowerCase().includes(this.filterLocation.toLowerCase())
                        }
                        return filterLoc;
                    })
                    .sort((i1, i2) => {
                        return i1.Location.Name.toUpperCase() > i2.Location.Name.toUpperCase() ? 1 : -1;
                    })
                    .map((item) => {
                        return { value: item.Location.Id, text: item.Location.Name };
                    }),
            ];
        },
        checkDisabled() {
            return !this.createMode;
        },
    },
    created() {
    },
    methods: {
        deviceList(){
            let deviceName = [];
            this.devList = [];
            if(this.getModalData.key.Devices.length > 0){
                for(let i=0;i<this.getModalData.key.Devices.length;i++){
                    deviceName = this.getModalData.key.Devices[i].Device.Name
                    this.devList.push({
                        text: this.getModalData.key.Devices[i].Device.Name,
                        id: this.getModalData.key.Devices[i].Device.Id,
                    })
                }
            }
            return deviceName;
        },
        validateState(name) {
            const { $dirty, $error } = this.$v.key[name];
            return $dirty && this.submitted ? !$error : null;
        },
        initCompany(company){
            this.key["CompanyId"] = company.Id;
        },
        initLocation(location){
            this.key["LocationId"] = location.Id;
        },
        editKey() {
            this.submitted = true;
            this.$v.key.$touch();
            if (this.$v.key.$anyError) {
                return;
            }
            this.$store
                .dispatch(ADMIN_KEY_PATCH, this.key)
                .then(() => {
                    this.$store.dispatch(ADMIN_KEYS_GET);
                    this.$bvModal.hide(this.type);
                })
                .catch((error) => {});
        },

        postKey() {
            this.submitted = true;
            this.$v.key.$touch();
            if (this.$v.key.$anyError) {
                return;
            }

            let device_id = []
            device_id.push(this.key.DeviceId);
            let location_id = this.key.LocationId

// ADMIN_KEY_DEVICES_ADD
            this.$store
                .dispatch(ADMIN_KEY_CREATE, this.key)
                .then((response) => {
                    this.$bvModal.hide(this.type);
                    this.$store.dispatch(KEY_DEVICES_ADD, {
                        keyId: response.data.Id,
                        devicesIds: device_id,
                        locationId: location_id,
                    });
                })
                .catch((error) => {});
        },
        setData() {
            if (this.getModalData !== null) {
                if(this.getModalData.key.Key.Name.includes("Key of: ")){
                    this.getModalData.key.Key.Name = this.getModalData.key.Key.Name.replace("Key of: ","");
                }
                this.key = { ...this.getModalData.key.Key };
                this.deviceList();
                // this.key.DeviceId = this.devList.id;

                if(this.getModalData.key.Devices.length > 0){
                    this.key.DeviceId = this.getModalData.key.Devices[this.getModalData.key.Devices.length-1].Device.Id
                }
                // this.key.DeviceId = null;
                this.createMode = false;
            } else {
                this.key = { ...this.keyTemplate };
                this.keyDevices = null;
            }

            this.$v.key.$reset();
        },
        onShow() {
            this.setData();
        },
        onClose() {
            this.$v.key.$reset();
            this.submitted = false;
            this.createMode = true;
            this.key = { ...this.keyTemplate };
            this.$store.commit(CLEAR_MODAL_DATA);
        },
    },
    components: {
    },
};
</script>

<style>
.select2-search__field {
    width: 100% !important;
    padding: 0.125rem 2.5rem !important;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35' height='16' opacity='0.5' class='bi bi-search' style='margin-left: 2px' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat left;
}
li.select2-search--inline {
    width: 100% !important;
}
input:disabled {
    color: #95aac9;
    background-color: #edf2f9;
}
</style>

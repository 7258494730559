<template>
    <b-modal
        :id="type"
        :ok-title="$t('buttons.delete')"
        :cancel-title="$t('buttons.cancel')"
        :title="$t('modals.title_remove_user')"
        dialog-class="modal-dialog-vertical"
        modal-class="fixed-right"
        ok-variant="outline-danger"
        @hide="onClose"
        @ok="deleteUser"
        @show="setData"
    >
        <b-container v-if="type == getModalType" fluid>
            <b-row>
                <b-col cols="12">
                    {{
                        $t("modals.text_remove_user", {
                            userName:
                                getModalData.user.User.FirstName +
                                " " +
                                getModalData.user.User.LastName,
                        })
                    }}
                    <span class="bold-font-style">{{
                            $t("modals.are_you_sure")
                        }}</span>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>

<script>
import {mapGetters} from "vuex";
import {ADMIN_COMPANY_USER_DELETE} from "@/store/types/admin";
import {M_ADMIN_COMPANY_USER_DELETE} from "@/components/modals/types";
import {CLEAR_MODAL_DATA} from "@/store/types/global";

export default {
    data() {
        return {
            type: M_ADMIN_COMPANY_USER_DELETE,
            user: {},
        };
    },
    computed: {
        ...mapGetters(["getModalData", "getModalType"]),
    },

    methods: {
        setData() {
            this.user = {...this.getModalData.user};
            if (this.getModalData.user.User.FirstName == null || this.getModalData.user.User.FirstName == "null" || this.getModalData.user.User.FirstName == "") {
                this.getModalData.user.User.FirstName = '';
            }
            if (this.getModalData.user.User.LastName == null || this.getModalData.user.User.LastName == "null" || this.getModalData.user.User.LastName == "") {
                this.getModalData.user.User.LastName = '';
            }
        },
        deleteUser() {
            this.$store
                .dispatch(ADMIN_COMPANY_USER_DELETE, {
                    ...this.getModalData.user,
                })
                .then(() => {
                });           
        },
        onClose() {
            this.user = null;
            this.$store.commit(CLEAR_MODAL_DATA);
        },
    },
};
</script>

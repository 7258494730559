<template>
    <b-modal
        :id="type"
        :title="$t('modals.title_remove_user')"
        :ok-title="$t('buttons.delete')"
        :cancel-title="$t('buttons.cancel')"
        ok-variant="outline-danger"
        modal-class="fixed-right"
        dialog-class="modal-dialog-vertical"
        @ok="deleteUser"
        @show="setData"
        @hide="onClose"
    >
        <b-container fluid v-if="type == getModalType">
            <b-row>
                <b-col cols="12">
                    {{
                        $t("modals.text_remove_location_user", {
                            locationName: user.Location.Name,
                            userName: user.User.FirstName + " " + user.User.LastName,
                        })
                    }}
                    <span class="bold-font-style">{{
                            $t("modals.are_you_sure")
                        }}</span>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>

<script>
import {mapGetters} from "vuex";
import {ADMIN_LOCATION_USER_DELETE} from "@/store/types/admin";
import {M_ADMIN_LOCATION_USER_DELETE} from "@/components/modals/types";
import {CLEAR_MODAL_DATA} from "@/store/types/global";
import {
    COMPANY_GET_INVITES,
    COMPANY_GET_EMPLOYEES,
} from "@/store/types/companies";

export default {
    data() {
        return {
            type: M_ADMIN_LOCATION_USER_DELETE,
            user: {},
        };
    },
    computed: {
        ...mapGetters(["getModalData", "getModalType"]),
    },
    methods: {
        setData() {
            // console.log("this.getModalData:"+this.getModalData.length)
            this.user = {...this.getModalData.user};
            if (this.user.User.FirstName == null || this.user.User.FirstName == "null" || this.user.User.FirstName == "") {
                this.user.User.FirstName = '';
            }
            if (this.user.User.LastName == null || this.user.User.LastName == "null" || this.user.User.LastName == "") {
                this.user.User.LastName = '';
            }
        },
        deleteUser() {
            this.$store
                .dispatch(ADMIN_LOCATION_USER_DELETE, {
                    userId: this.user.User.Id,
                    locationId: this.user.Location.Id,
                })
                .then(() => {                    
                });
        },
        onClose() {
            this.user = null;
            this.$store.commit(CLEAR_MODAL_DATA);
        },
    },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-list-group-item',[_c('b-row',{staticClass:"align-items-center",staticStyle:{"height":"100px"}},[_c('b-col',{staticClass:"col-auto"},[_c('router-link',{staticClass:"avatar avatar-lg",attrs:{"to":{
                    name: 'location',
                    params: {
                        locationId: _vm.location.Location.Id,
                        companyId: _vm.location.Location.CompanyId,
                    },
                }}},[_c('img',{staticClass:"avatar-img rounded",attrs:{"src":_vm.location.Location.SmallImageUrl ||
                        '/images/zesec-placeholder.png',"alt":_vm.location.Location.Name}})])],1),_c('div',{staticClass:"col ml-n2"},[_c('h4',{staticClass:"mb-1 name"},[_c('router-link',{attrs:{"to":{
                        name: 'location',
                        params: {
                            locationId: _vm.location.Location.Id,
                            companyId: _vm.location.Location.CompanyId,
                        },
                    }}},[_vm._v(_vm._s(_vm.location.Location.Name))])],1),_c('p',{staticClass:"card-text small text-muted mb-1"},[_vm._v(" "+_vm._s(_vm.$t("common.count_doors", { count: _vm.location.Location.DevicesCount, }))+" ")]),_c('p',{staticClass:"card-text small text-muted mb-1",staticStyle:{"line-height":"0.5"}},[_vm._v(" "+_vm._s(_vm.$t("common.count_users", { count: _vm.location.Location.UsersCount, }))+" ")]),_c('p',{staticClass:"card-text small text-muted"},[_vm._v(" "+_vm._s(_vm.location.Location.Address)+" ")])]),_c('div',{staticClass:"col-auto"},[_c('b-dropdown',{attrs:{"variant":"outline","toggle-class":"dropdown-ellipses dropdown-toggle","no-caret":"","right":"","toggle-tag":"a"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('img',{staticStyle:{"width":"19px","height":"19px"},attrs:{"src":'/images/settings_grey_32x32dp.png'}})]},proxy:true}])},[_c('b-dropdown-item',{staticClass:"text-left text-truncate",on:{"click":function($event){return _vm.editLocation(_vm.location)}}},[_vm._v(" "+_vm._s(_vm.$t("buttons.edit"))+" ")]),_c('b-dropdown-item',{staticClass:"text-left text-truncate",on:{"click":function($event){return _vm.deleteLocation(_vm.location)}}},[_vm._v(" "+_vm._s(_vm.$t("buttons.delete"))+" ")]),_c('b-dropdown-item',{staticClass:"text-left text-truncate",on:{"click":function($event){return _vm.locationImage(_vm.location)}}},[_vm._v(" "+_vm._s(_vm.$t("locations.image"))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
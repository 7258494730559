<template>
    <b-modal
        :id="type"
        :title="$t('staff.invite')"
        size="lg"
        hide-footer
        modal-class="fixed-right"
        dialog-class="modal-dialog-vertical"
        @show="setData"
        @hide="onClose"
    >
        <b-container fluid>
            <div class="accordion" role="tablist">
                <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" role="tab" class="p-1">
                        <b-button
                            v-b-toggle.accordion-c
                            variant="primary"
                            disabled
                            ><b-row class="px-2"
                                ><i class="fi flaticon-business-1 mr-2"></i
                                >{{ $t("companies.company") }}:</b-row
                            >
                            <b-row class="px-2">
                                <strong>{{ selectedCompany ? selectedCompany.Name : "" }}</strong></b-row
                            >
                        </b-button>
                    </b-card-header>
                    <b-collapse
                        id="accordion-c"
                        accordion="my-accordion"
                        role="tabpanel"
                        disabled
                    >
                    </b-collapse>
                </b-card>
                <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" role="tab" class="p-1">
                        <b-button
                            block
                            v-b-toggle.accordion-p
                            variant="primary"
                            disabled
                            ><b-row class="px-2"
                                >
                                <!--  <img :src="'/images/person-big_MyZesec_140dp.png'" class="mr-2"/> -->
                                <i class="fi flaticon-user mr-2"></i>{{ $t("staff.name") }}:</b-row>
                            <b-row class="px-2">
                                <strong>{{ this.invite.Name }}</strong></b-row
                            ></b-button
                        >
                    </b-card-header>
                    <b-collapse
                        id="accordion-p"
                        accordion="my-accordion"
                        role="tabpanel"
                        disabled
                    >
                        <b-card-body>
                            <b-form-group>
                                <b-form-input
                                    v-model="$v.invite.Name.$model"
                                    :state="validateState('Name')"
                                    id="employeeName"
                                    :placeholder="$t('staff.name')"
                                />
                                <b-form-invalid-feedback
                                    id="employeeName-feedback"
                                >
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{
                                        $t("errors.validation_length_range", {
                                            range: "3-50",
                                        })
                                    }}
                                </b-form-invalid-feedback>
                            </b-form-group>

                            <b-form-group>
                                <vue-phone-number-input
                                    v-model="$v.invite.PhoneNumber.$model"
                                    id="employeePhone"
                                    :error="!validPhone && submitted"
                                    :translations="$t('phoneInput')"
                                    @update="validatePhone"
                                    required
                                    valid-color="#28a745"
                                    error-color="#e63857"
                                    fetch-country
                                    type="text"
                                />
                                <span v-if="!validPhone && submitted"
                                    id="employeePhone-feedback" style="color: #e63857; font-size: 0.8125rem"
                                >
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{ $t("errors.validation_required") }}
                                </span>
                            </b-form-group>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" role="tab" class="p-1">
                        <b-button
                            block
                            v-b-toggle.accordion-r
                            variant="primary"
                            visible
                            ><b-row class="px-2"
                                ><i class="fe fe-settings mr-2"></i
                                >{{ $t("staff.role") }}:</b-row
                            >
                            <b-row class="px-2">
                                <strong>{{ roleName }}</strong></b-row
                            ></b-button
                        >
                    </b-card-header>
                    <b-collapse
                        id="accordion-r"
                        accordion="my-accordion"
                        role="tabpanel"
                        visible
                    >
                        <b-card-body>
                            <b-form-group>
                                <b-form-select
                                    v-model="$v.invite.RoleType.$model"
                                    :state="validateState('RoleType')"
                                    @change="roleSelected"
                                    id="Role"
                                    value-field="Id"
                                    :options="rolesOptions"
                                ></b-form-select>
                                <b-form-invalid-feedback
                                    id="inviteKey-feedback"
                                    >
                                    <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{
                                        $t("staff.role_select")
                                    }}</b-form-invalid-feedback
                                >
                            </b-form-group>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" role="tab" class="p-1">
                        <b-button
                            block
                            v-b-toggle.accordion-l
                            variant="primary"
                            :disabled="!locationManagerSelected || !keyManagerSelected"
                            ><b-row class="px-2"
                                ><i class="fi flaticon-business mr-2"></i
                                >{{ $t("locations.location") }}:</b-row
                            >
                            <b-row class="px-2">
                                <strong>{{ locationName }}</strong></b-row
                            ></b-button
                        >
                    </b-card-header>
                    <b-collapse
                        id="accordion-l"
                        accordion="my-accordion"
                        role="tabpanel"
                    >
                        <b-card-body>
                            <b-form-group>
                                <!-- :disabled="!this.invite.LocationId" -->
                                <!-- <b-form-select
                                    v-model="$v.invite.LocationId.$model"
                                    :state="validateState('LocationId')"
                                    id="LocationId"
                                    v-if="locationsListOptions.length > 15"
                                    :options="locationsListOptions"
                                    onfocus='this.size=10, this.height=2'
                                    onchange='this.size=10, this.height=2, this.blur();'
                                    onblur='this.size=10, this.height=2' 
                                    onfocusout='this.size=null;'
                                ></b-form-select> -->
                                <b-form-select
                                    v-model="$v.invite.LocationId.$model"
                                    :state="validateState('LocationId')"
                                    id="LocationId"
                                    :options="locationsListOptions"
                                ></b-form-select>
                                <b-form-invalid-feedback
                                    id="LocationId-feedback"
                                    >
                                    <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{
                                        $t("staff.location_select")
                                    }}</b-form-invalid-feedback
                                >
                            </b-form-group>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>

            <b-row>
                <b-col>
                    <b-button class="input-field" @click.prevent.stop="changeRole">{{
                        $t("staff.change_role")
                    }}</b-button>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>

<script>
import { mapGetters } from "vuex";

import { M_COMPANY_CHANGE_ROLE_ADMIN_FORM } from "@/components/modals/types";
import {
    ACL_CREATE_LOCATION_MANAGER_ROLE,
    ACL_INVITE_TO_COMPANY_ROLE,
    ACL_INVITE_TO_LOCATION_ROLE,
} from "@/store/types/acl";
import {
    COMPANY_GET_INVITES,
    COMPANY_GET_EMPLOYEES,
} from "@/store/types/companies";
import { LOCATIONS_GET_COMPANY_LOCATIONS } from "@/store/types/locations";
import { COMPANY_USER_DELETE } from "@/store/types/companies";
import { LOCATION_USER_DELETE } from "@/store/types/locations";
import { CLEAR_MODAL_DATA } from "@/store/types/global";
import {
  ADMIN_LOCATIONS_GET,
  ADMIN_COMPANY_USER_DELETE,
  ADMIN_LOCATION_USER_DELETE,
  ACL_INVITE_TO_COMPANY_ROLE_ADMIN,
  ACL_INVITE_TO_LOCATION_ROLE_ADMIN
} from "@/store/types/admin";

import { validationMixin } from "vuelidate";
import {
    definedRoles,
    definedRolesArray,
    definedKeyRolesIdsArray,
    ROLE_COMPANY_OWNER,
    ROLE_COMPANY_MANAGER,
    ROLE_LOCATION_MANAGER,
    ROLE_DEVICE_INSTALLER,
    ROLE_KEY_MANAGER,
} from "@/acl/roles";
import { required, minLength, maxLength, numeric } from "vuelidate/lib/validators";
import VuePhoneNumberInput from "vue-phone-number-input";

import aclController from "@/api/acl";

export default {
    mixins: [validationMixin],
    data() {
        return {
            type: M_COMPANY_CHANGE_ROLE_ADMIN_FORM,
            inviteTemplate: {
                Name: "",
                PhoneNumber: "",
                RoleType: null,
                CompanyId: null,
                LocationId: null,
            },
            validPhone: false,
            fullPhone: null,
            submitted: false,
            invite: {},
        };
    },
    props: ["company"],
    validations: {
        invite: {
            Name: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(50),
            },
            PhoneNumber: {
                required,
                phone: function () {
                    return this.validPhone;
                },
            },
            RoleType: { required },
            LocationId: {
                location: function () {
                    return this.invite.RoleType !==
                        definedRoles[ROLE_COMPANY_MANAGER].Id
                        ? this.invite.LocationId != null
                        : true;
                },
            },
            CompanyId: {
                required,
                numeric,
            },
        },
    },
    computed: {
        ...mapGetters(["getModalData", "getModalType", "getAllLocations", "getAllAdminCompanies", "getAllStaff"]),
        getCompaniesOptions() {
            return [
                {
                    value: null,
                    text: this.$t("locations.company_select"),
                    disabled: true,
                },
                ...this.getAllAdminCompanies                
                .filter((item) => item.Id == this.user.Company.Id)
                .sort((i1, i2) => {
                    return i1.Name.toUpperCase() > i2.Name.toUpperCase() ? 1 : -1;
                })
                .map((item) => {
                    return {
                        value: item.Id,
                        text: item.Name,
                    };
                }),
            ];
        },
        locationsListOptions() {
            return [
                {
                    value: null,
                    text: this.$t("staff.location_select"),
                    disabled: true,
                },
                ...this.getAllLocations
                    .filter((item) => item.Location.CompanyId == this.invite.CompanyId)
                    .map((item) => {
                        return { value: item.Location.Id, text: item.Location.Name };
                    }),
            ];
        },
        rolesOptions() {
            return [
                {
                    value: null,
                    text: this.$t("staff.role_select"),
                    disabled: true,
                },                
                ...definedRolesArray().filter(
                    (item) =>{
                        if(item.value == ROLE_COMPANY_OWNER){
                            item.text = this.$t("permissions.role_owner");
                        }else if(item.value == ROLE_COMPANY_MANAGER){
                            item.text = this.$t("permissions.role_cm");
                        }else if(item.value == ROLE_LOCATION_MANAGER){
                            item.text = this.$t("permissions.role_lm");
                        }else if(item.value == ROLE_KEY_MANAGER){
                            item.text = this.$t("permissions.role_km");
                        }else if(item.value == ROLE_DEVICE_INSTALLER){
                            item.text = this.$t("permissions.role_installer");
                        }

                        // this.$can("invite", item) &&
                        return (                            
                            [
                                ROLE_COMPANY_MANAGER,
                                ROLE_KEY_MANAGER,
                                ROLE_DEVICE_INSTALLER,
                                ROLE_LOCATION_MANAGER,
                            ].includes(item.value)
                        );
                    }),
            ];
        },
        locationManagerSelected() {            
            return [
                definedRoles[ROLE_LOCATION_MANAGER].Id,
                definedRoles[ROLE_DEVICE_INSTALLER].Id,
                definedRoles[ROLE_KEY_MANAGER].Id,
            ].includes(this.invite.RoleType);
        },
        keyManagerSelected() {
            return [
                definedRoles[ROLE_DEVICE_INSTALLER].Id,
                definedRoles[ROLE_KEY_MANAGER].Id,
            ].includes(this.invite.RoleType);
        },
        companyManagerSelected() {
            return (
                this.invite.RoleType == definedRoles[ROLE_COMPANY_MANAGER].Id
            );
        },
        roleName() {
            return this.invite.RoleType
                ? definedRolesArray().find(
                      (item) => item.Id == this.invite.RoleType
                  ).text
                : this.$t("staff.role_select");
        },
        selectedCompany() {
            return this.getAllAdminCompanies.find(
                (item) => item.Id == this.invite.CompanyId
            );
        },
        locationName() {
            return this.invite.LocationId
                ? this.getAllLocations.find(
                      (item) => item.Location.Id == this.invite.LocationId
                  ).Location.Name
                : this.$t("staff.location_select");
        },
        userName() {
            let name = this.getAllStaff.find(
                (item) => item.Name == this.invite.Name
            );
            return name            
        },
    },
    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.invite[name];
            return $dirty && this.submitted ? !$error : null;
        },
        validatePhone(phone) {
            this.validPhone = phone.isValid;
            this.fullPhone = phone;
        },
        roleSelected(roleId) {
            if (
                [
                    definedRoles[ROLE_LOCATION_MANAGER].Id,
                    definedRoles[ROLE_DEVICE_INSTALLER].Id,
                    definedRoles[ROLE_KEY_MANAGER].Id,
                ].includes(roleId)
            ) {
                this.$root.$emit("bv::toggle::collapse", "accordion-l");
            }
        },
        changeRole() {
            this.submitted = true;
            this.$v.invite.$touch();
                        
            if (this.invite.RoleType == definedRoles[ROLE_COMPANY_MANAGER].Id) {
                this.$store
                .dispatch(ADMIN_COMPANY_USER_DELETE, {
                    ...this.getModalData.user,
                    ...{ norefresh: true },
                })
                .then(() => {
                    this.inviteEmployee();
                });
            }else{
                this.$store
                 .dispatch(ADMIN_LOCATION_USER_DELETE, {
                    userId: this.getModalData.user.User.Id,
                    locationId: this.invite.LocationId,
                    ...{ norefresh: true },
                })
                .then(() => {
                     this.inviteEmployee();
                });
            }                
        },

        inviteEmployee() {
            if (this.companyManagerSelected) {
                this._createCompanyManagerRole();
            } else if (this.locationManagerSelected) {
                this._createLocationManagerRole();
            } else if (this.keyManagerSelected) {
                this._createLocationManagerRole();
            }
        },

        _createCompanyManagerRole() {
            let payload = {
                ...this.invite,
                ...{
                    PhoneNumber: this.fullPhone.formattedNumber,
                },
            };
            this.$bvModal.hide(this.type);
            this.$store
                .dispatch(ACL_INVITE_TO_COMPANY_ROLE_ADMIN, payload)
                .then(() => {                   
                });
        },

        _createLocationManagerRole() {
            let payload = {
                ...this.invite,
                ...{
                    PhoneNumber: this.fullPhone.formattedNumber,
                },
            };
            this.$bvModal.hide(this.type);

            this.$store
                .dispatch(ACL_INVITE_TO_COMPANY_ROLE_ADMIN, payload)
                .then(() => {                    
                });
        },

        setData() { 
            this.invite = {
                ...this.inviteTemplate,
                ...{
                    CompanyId: this.getModalData.user.Company.Id,
                    Name: this.getModalData.user.User.Name,
                    PhoneNumber: this.getModalData.user.User.PhoneNumber,
                },
            };            
            this.$store.dispatch(ADMIN_LOCATIONS_GET);
        },
        onClose() {
            this.invite = { ...this.inviteTemplate };
            this.submitted = false;
            this.$v.invite.$reset();
            this.$store.commit(CLEAR_MODAL_DATA);
        },
    },
    components: {
        "vue-phone-number-input": VuePhoneNumberInput,
    },
};
</script>

<template>
    <b-list-group-item>
        <!-- display: -webkit-box !important; -->
        <div class="row" id="mainRow" style="height: 67px;">
            <div class="col-auto">
                <!-- Avatar -->
                <div class="avatar avatar-sm">
                    <div
                        class="
                            avatar-title
                            font-size-lg
                            bg-primary-soft
                            rounded-circle
                            text-primary
                        "
                    >
                    <router-link
                        v-if="keyRouteParams"
                        :to="{
                            name: 'key',
                            params: keyRouteParams,
                        }"
                    >
                    <img :src="'/images/Key_MyZesec_64dp.png'" style="width: 32px; height: 32px;"/>
                    </router-link>
                        <span v-else>
                        <img :src="'/images/Key_MyZesec_64dp.png'" style="width: 32px; height: 32px;"/>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col ml-n2" v-if="!isMobile">
                <!-- Heading -->
                <p class="small mb-0">
                    <router-link
                        v-if="keyRouteParams"
                        :to="{
                            name: 'key',
                            params: keyRouteParams,
                        }"
                        >{{ keyName(doorKey.Key.Name) }}</router-link
                    >
                    <span v-else>{{ keyName(doorKey.Key.Name) }}</span>
                </p>
                <small class="text-muted mr-2" v-if="showCounts">
                    {{
                        $t("common.count_doors", {
                            count: doorKey.Key.DevicesCount || 0,
                        })
                    }}
                </small>
                <small class="text-muted" v-if="showCounts">
                    {{
                        $t("common.count_users", {
                            count: doorKey.Key.UsersCount || 0,
                        })
                    }}
                </small>
            </div>
            <div class="col ml-n2" v-else>
                <!-- Heading -->
                <p class="small mb-0">
                    <router-link
                        v-if="keyRouteParams"
                        :to="{
                            name: 'key',
                            params: keyRouteParams,
                        }"
                        >{{ keyName(doorKey.Key.Name) }}</router-link
                    >
                    <span v-else>{{ keyName(doorKey.Key.Name) }}</span>
                </p>
                <!-- mr-2  -->
                <p class="card-text small text-muted mb-1" v-if="showCounts">
                    {{
                        $t("common.count_doors", {
                            count: doorKey.Key.DevicesCount || 0,
                        })
                    }}
                </p>
                <p class="card-text small text-muted" v-if="showCounts"  style="line-height: 0.5;">
                    {{
                        $t("common.count_users", {
                            count: doorKey.Key.UsersCount || 0,
                        })
                    }}
                </p>
            </div>
            <!-- <div class="col ml-n2">
                <b-badge :variant="keyTypeColor" class="text-uppercase mx-2">{{
                    keyType
                }}</b-badge>
                <b-badge
                    v-if="showShare"
                    :variant="mayShareColor"
                    class="text-uppercase mx-2"
                    >{{ mayShare }}</b-badge
                >
            </div> -->
            <div class="col-auto">
                <!-- Dropdown -->
                <b-dropdown
                    variant="outline"
                    toggle-class="dropdown-ellipses dropdown-toggle"
                    no-caret
                    right
                    toggle-tag="a"
                >
                    <template v-slot:button-content>
                        <!-- <i class="fe fe-more-vertical"></i> -->
                        <img :src="'/images/settings_grey_32x32dp.png'" style="width: 19px; height: 19px;"/>
                    </template>
                    <b-dropdown-item
                        class="text-left text-truncate"
                        @click="editKey(doorKey)"
                        >{{ $t("buttons.edit") }}</b-dropdown-item
                    >
                    <b-dropdown-item
                        class="text-left text-truncate"
                        @click="deleteKey(doorKey)"
                        >{{ $t("keys.remove_key") }}</b-dropdown-item
                    >
                    <b-dropdown-item
                        class="text-left text-truncate"
                        @click="addDoor(doorKey)"
                        >{{ $t("doors.add") }}</b-dropdown-item
                    >
                    <b-dropdown-item
                        class="text-left text-truncate"
                        v-if="doorKey.Key.DevicesCount > 0"
                        @click="deleteDoor(doorKey)"
                        >{{ $t("buttons.disconnect_door") }}</b-dropdown-item
                    >
                </b-dropdown>
            </div>
        </div>

        <div class="d-flex justify-content-between row align-items-center" v-if="!isMobile">
            <b-col lg="6" md="12" v-if="showDates && doorKey.Calendar.AccessIntervals[0].DailyAccesses.length">
                <b-list-group horizontal>
                    <b-list-group-item class="py-0 px-1 w-50" variant="primary">
                        <small>{{ $t("keys.access_from") }}</small>
                        <br />
                        <!-- .local() -->
                        <small>{{
                            $moment
                                .utc(doorKey.Calendar.AccessIntervals[0].StartDate)
                                .local()
                                .format("YYYY-MM-DD HH:mm")
                        }}</small>
                    </b-list-group-item>
                    <b-list-group-item
                        class="py-0 px-1 w-50"
                        variant="outline-primary"
                    >
                        <small>{{ $t("keys.access_to") }}</small>
                        <br />
                        <small>{{
                            $moment
                                .utc(doorKey.Calendar.AccessIntervals[0].EndDate)
                                .local()
                                .format("YYYY-MM-DD HH:mm")
                        }}</small>
                    </b-list-group-item>
                </b-list-group>
            </b-col>
            <b-col
                lg="6"
                md="12"
                v-if="
                    showDates &&
                    doorKey.Calendar.AccessIntervals[0].DailyAccesses.length
                "
            >
                <b-button-group size="sm" class="w-100">
                    <b-button
                        variant="light"
                        v-for="day in weekDays"
                        v-bind:key="day.DayNumber"
                        class="btn-md btn-day p-0"
                    >
                        <b-list-group class="px-0 w-100" flush>
                            <b-list-group-item
                                class="p-0"
                                :variant="
                                    day.active ? 'primary' : 'outline-primary'
                                "
                            >
                                <small>
                                    <!-- <strong>{{ day.DayName }}</strong> -->
                                    <strong>{{ dayName(day.DayNumber) }}</strong>
                                </small>
                            </b-list-group-item>
                            <b-list-group-item class="p-0" variant="light">
                                <small>{{
                                    day.active ? day.timeFrom : "-"
                                }}</small>
                            </b-list-group-item>
                            <b-list-group-item class="p-0" variant="light">
                                <small>{{
                                    day.active ? day.timeTo : "-"
                                }}</small>
                            </b-list-group-item>
                        </b-list-group>
                    </b-button>
                </b-button-group>
            </b-col>
        </div>
        <div class="d-flex justify-content-between row align-items-center mt-3" v-else>
            <b-col lg="6" md="12" v-if="showDates && doorKey.Calendar.AccessIntervals[0].DailyAccesses.length">
                <b-list-group horizontal>
                    <b-list-group-item class="py-0 px-1 w-50" variant="primary">
                        <small>{{ $t("keys.access_from") }}</small>
                        <br />
                        <small>{{
                            $moment
                                .utc(doorKey.Calendar.AccessIntervals[0].StartDate)
                                .local()
                                .format("YYYY-MM-DD HH:mm")
                        }}</small>
                    </b-list-group-item>
                    <b-list-group-item
                        class="py-0 px-1 w-50"
                        variant="outline-primary"
                    >
                        <small>{{ $t("keys.access_to") }}</small>
                        <br />
                        <small>{{
                            $moment
                                .utc(doorKey.Calendar.AccessIntervals[0].EndDate)
                                .local()
                                .format("YYYY-MM-DD HH:mm")
                        }}</small>
                    </b-list-group-item>
                </b-list-group>
            </b-col>
            <b-col
                lg="6"
                md="12"
                v-if="
                    showDates &&
                    doorKey.Calendar.AccessIntervals[0].DailyAccesses.length
                "
            >
                <b-button-group size="sm" class="w-100">
                    <b-button
                        variant="light"
                        v-for="day in weekDays"
                        v-bind:key="day.DayNumber"
                        class="btn-md btn-day p-0"
                    >
                        <b-list-group class="px-0 w-100" flush>
                            <b-list-group-item
                                class="p-0"
                                :variant="
                                    day.active ? 'primary' : 'outline-primary'
                                "
                            >
                                <small>
                                    <!-- <strong>{{ day.DayName }}</strong> -->
                                    <strong>{{ dayName(day.DayNumber) }}</strong>
                                </small>
                            </b-list-group-item>
                            <b-list-group-item class="p-0" variant="light">
                                <small>{{
                                    day.active ? day.timeFrom : "-"
                                }}</small>
                            </b-list-group-item>
                            <b-list-group-item class="p-0" variant="light">
                                <small>{{
                                    day.active ? day.timeTo : "-"
                                }}</small>
                            </b-list-group-item>
                        </b-list-group>
                    </b-button>
                </b-button-group>
                <zs-key-admin-modal :locationId="doorKey.LocationId" />
                <zs-key-delete-admin-modal :locationId="doorKey.LocationId" />
            </b-col>
        </div>
    </b-list-group-item>
</template>
<script>
import { mapGetters } from "vuex";
import { ROLE_KEY_PERMANENT, ROLE_KEY_TEMPORARY } from "@/acl/roles";
import {
    M_DELETE_KEY_USER,
    M_KEY_FORM_ADMIN,
    M_KEY_ADMIN_DELETE,
    M_ADD_DEVICE,
    M_DISCONNECT_DEVICE,
} from "@/components/modals/types";
import { KEY_USER_REMOVE } from "@/store/types/keys";
import { SET_MODAL_DATA } from "@/store/types/global";
import { getWeekDays } from "@/handlers/enums";
import modalMixin from "@/mixins/modalMixin";
import DeleteKeyAdminModal from "@/components/modals/DeleteKeyAdmin";
import CreateKeyAdminModal from "@/components/modals/CreateKeyAdmin";
import { EventBus } from "@/main.js";

export default {
    mixins: [modalMixin],
    name: "key-list-item-admin",
    data() {
        return {
            mayShare: false,
            mayShareColor: "warning",
            isOwner: false,
            keyType: this.$t("keys.type_temporary"),
            keyTypeColor: "light",
            keyRouteParams: false,
            showCounts: false,
            showDates: false,
            weekDays: [],
            showShare: false,
            isMobile: false,
            width: window.innerWidth,
            height: window.innerHeight
        };
    },
    components: {
        "zs-key-delete-admin-modal": DeleteKeyAdminModal,
        "zs-key-admin-modal": CreateKeyAdminModal,
    },
    computed: {
        ...mapGetters(["getLocationCompanyId"]),
    },
    props: ["doorKey", "showDeleting"],
    created() {
        this.mayShare = this._mayShare();
        this.isOwner = this._isOwner();
        this.keyType = this._keyType();
        this.showShare = this._showShare();
        this.keyRouteParams = this._keyRouteParams();
        if (this.doorKey.Calendar) {
            this._days();
        }
        this.showCounts = "DevicesCount" in this.doorKey.Key;
        this.showDates = this.doorKey.Role && this.doorKey.Role.Name == ROLE_KEY_TEMPORARY;
        window.addEventListener("resize", this.onResize);
        window.addEventListener("onload", this.onLoad);
    },
    destroyed() {
        window.removeEventListener("resize", this.onResize);
        window.removeEventListener("onload", this.onLoad);
        this.isMobile = false;
    },
    methods: {
        keyName(name){
            if(name.includes("Key of: ")){
                name = name.replace("Key of: ","");
            }
            name = name.trim();

            let wordCounterwithSpace = 0;
            let wordCounterwithoutSpace = 0;
            for (let i = 0; i < name.length; i++) {
                if (name[i] == ' ') {
                    wordCounterwithSpace++;
                    continue;
                } else {
                    wordCounterwithoutSpace++;
                    wordCounterwithSpace++;
                }
            }

            if(this.isMobile && name.length > 11){
                let wordCounterwithSpace1 = 0;
                let wordCounterwithoutSpace1 = 0;
                for (let i = 0; i < 11; i++) {
                    if (name[i] == ' ') {
                        wordCounterwithSpace1++;
                        continue;
                    } else {
                        wordCounterwithoutSpace1++;
                        wordCounterwithSpace1++;
                    }
                }
                if(this.isMobile && (wordCounterwithSpace1 > 11 || wordCounterwithoutSpace1 > 11)){
                    name = name.slice(0, 7).concat('...');
                }
            }
            if(this.isMobile && (wordCounterwithSpace > 11 || wordCounterwithoutSpace > 11)){
                name = name.slice(0, 12).concat('...');
            }
            return name;
        },
        dayName(number){
            let dayName;
            if(number == 0){
                dayName = this.$t("days.M");
            }else if(number == 1){
                dayName = this.$t("days.T");
            }else if(number == 2){
                dayName = this.$t("days.W");
            }else if(number == 3){
                dayName = this.$t("days.Th");
            }else if(number == 4){
                dayName = this.$t("days.F");
            }else if(number == 5){
                dayName = this.$t("days.S");
            }else if(number == 6){
                dayName = this.$t("days.Su");
            }
            return dayName;
        },
        deleteKey(doorKey){
            this.showModal({ key: this.doorKey.Key }, M_KEY_ADMIN_DELETE);
        },
        deleteDoor(doorKey){
            this.showModal({ key: doorKey }, M_DISCONNECT_DEVICE);
        },
        addDoor(doorKey){
            this.$session.set("locationId", doorKey.Key.LocationId);
            // EventBus.$emit("locationId", doorKey.Key.LocationId);
            this.showModal({ key: doorKey }, M_ADD_DEVICE);
        },
        deleteUser() {
            this.$store.commit(SET_MODAL_DATA, {
                data: { access: this.doorKey },
                type: M_DELETE_KEY_USER,
            });
            this.showModal(
                {
                    user: {
                        keyId: this.doorKey.Key.Id,
                        userId: this.doorKey.User.Id,
                        locationId: this.doorKey.Key.LocationId,
                    },
                },
                M_DELETE_KEY_USER
            );
        },
        editKey(doorKey) {
            this.showModal({ key: doorKey }, M_KEY_FORM_ADMIN);
        },
        _days() {
            this.weekDays = getWeekDays().map((day) => {
                    const access = this.doorKey.Calendar.AccessIntervals[0].DailyAccesses.find(
                        (item) => item.DayNumber == day.DayNumber
                    );

                    if (access) {
                        day.active = true;
                        day.timeTo = this.$moment()
                            .startOf("day")
                            .add(access.End, "minutes")
                            .format("HH:mm");
                        day.timeFrom = this.$moment()
                            .startOf("day")
                            .add(access.Start, "minutes")
                            .format("HH:mm");
                    } else {
                        day.active = false;
                    }
                    return day;
                });
        },
        _mayShare() {
            if (this.doorKey.MayShare || this._isOwner()) {
                this.mayShareColor = "success";
                return this.$t("keys.share_allowed");
            }
            return this.$t("keys.share_disallowed");
        },
        _showShare() {
            if (this._isOwner()) {
                return true;
            } else if (this.doorKey.Calendar === null) {
                return true;
            }
            return false;
        },
        _isOwner() {
            return this.doorKey.Key.OwnerId;
        },
        _keyType() {
            if (this._isOwner()) {
                this.keyTypeColor = "zsprimary";
                return this.$t("keys.type_owner");
            } else if (this.doorKey.Role.Name == ROLE_KEY_PERMANENT) {
                return this.$t("keys.type_permanent");
            } else if (this.doorKey.Calendar == null || this.doorKey.Calendar.AccessIntervals[0].DailyAccesses.length == 0) {
                return this.$t("keys.type_temporary");
            }
            return this.$t("keys.type_recurring");
        },
        _keyRouteParams() {
            return {
                locationId: this.doorKey.Key.LocationId,
                keyId: this.doorKey.Key.Id,
                companyId: this.doorKey.Key.CompanyId,
            };
        },
        onResize(e) {
            this.width = window.innerWidth;
            this.height = window.innerHeight;
            if(this.width < 1200){
                this.isMobile = true;
            }else{
                this.isMobile = false
            }
        },
        onLoad(evt){
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                this.isMobile = true;
            } else if(typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1){
                this.isMobile = true;
            } else {
                this.isMobile = false
            }
        },
    },
};
</script>
<style scoped>
.btn:focus{
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
}
.list-group-item:last-child{
  overflow-y: auto !important;
}

</style>

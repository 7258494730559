<template>
    <b-modal
        :id="type"
        :title="$t('locations.location')"
        size="lg"
        modal-class="fixed-right"
        dialog-class="modal-dialog-vertical"
        hide-footer
        @show="onShow"
        @hide="onClose"
    >
        <b-container fluid>
            <b-row>
                <b-col cols="12" style="padding-left: 54px !important;">
                    <b-form>
                            <!-- label-cols-sm="2"
                            label-align-sm="right"
                            label-size="sm" -->
                        <b-form-group
                            :label="$t('locations.name')"
                        >
                            <b-form-input
                                v-model="$v.location.Name.$model"
                                :state="validateState('Name')"
                                id="locationNameAdmin"
                                :placeholder="$t('locations.name')"
                            />
                            <b-form-invalid-feedback id="locationNameAdmin-feedback">
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                {{
                                    $t("errors.validation_length_range", {
                                        range: "3-30",
                                    })
                                }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group
                            :label="$t('locations.address')"
                        >
                            <b-form-input
                                v-model="$v.location.Address.$model"
                                id="locationAddressAdmin"
                                ref="acInput"
                                :state="validateState('Address')"
                                :placeholder="$t('locations.address')"
                            />
                            <b-form-invalid-feedback id="locationAddressAdmin-feedback">
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                {{
                                    $t("errors.validation_length_range", {
                                        range: "3-70",
                                    })
                                }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group
                            :label="$t('companies.company')"
                        >
                            <b-input-group>
                            <!-- <b-form-select
                                v-model="$v.location.CompanyId.$model"
                                id="locationCompany"
                                :state="validateState('CompanyId')"
                                v-if="getUserCompaniesOptions.length > 15"
                                :options="getUserCompaniesOptions"
                                :placeholder="$t('locations.company_select')"                                
                                onfocus='this.size=10, this.height=2'
                                onchange='this.size=10, this.height=2, this.blur();'
                                onblur='this.size=10, this.height=2' 
                                onfocusout='this.size=null;'
                            ></b-form-select> -->
                            <b-form-select
                                v-model="$v.location.CompanyId.$model"
                                id="locationCompany"
                                :state="validateState('CompanyId')"
                                :options="getUserCompaniesOptions"
                                :placeholder="$t('locations.company_select')"
                            ></b-form-select>
                            <b-form-invalid-feedback
                                id="locationCompany-feedback"
                            >
                            <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                {{ $t("locations.company_select") }}
                            </b-form-invalid-feedback></b-input-group>
                            <!-- <b-input-group>
                            <Select2
                                v-model="$v.location.CompanyId.$model"
                                class="select-dropdown"
                                id="locationCompanyAdmin"
                                :state="validateState('CompanyId')"
                                :options="getUserCompaniesOptions"
                                :settings="{
                                    placeholder: $t('locations.company_search'),
                                    multiple: true,
                                    allowClear: true,
                                    minimumResultsForSearch: 1,
                                    closeOnSelect: false,
                                    containerCssClass: 'custom-select',
                                    dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                                }"
                            />
                            <b-form-invalid-feedback
                                id="locationCompanyAdmin-feedback"
                            >
                            <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                {{ $t("locations.company_select") }}
                            </b-form-invalid-feedback></b-input-group> -->
                        </b-form-group>
                        <!-- <b-form-group
                            :label="$t('locations.map')"
                            v-if="coordinates"
                        >
                            <Gmap-Map
                                ref="locationMap"
                                style="width: 100%; height: 300px;"
                                :options="{
                                    zoomControl: false,
                                    mapTypeControl: false,
                                    scaleControl: false,
                                    streetViewControl: false,
                                    rotateControl: false,
                                    fullscreenControl: false,
                                    disableDefaultUi: true,
                                }"
                                :zoom="mapZoom"
                                :center="coordinates"
                            >
                                <Gmap-Marker
                                    :position="coordinates"
                                    :label="markerLabel"
                                    :draggable="true"
                                    @dragend="dragMarker"
                                ></Gmap-Marker>
                            </Gmap-Map>
                        </b-form-group> -->
                       </b-form>
                </b-col>
            </b-row>
            <b-row>
                            <b-col cols="12" style="padding-left: 54px !important;">
                            <b-button
                                class="input-field"
                                @click.prevent.stop="postAdminLocation"
                                v-if="createMode"
                                >{{ $t("locations.create") }}</b-button
                            >
                            <b-button
                                class="input-field"
                                @click.prevent.stop="editAdminLocation"
                                v-else
                                >{{ $t("locations.update") }}</b-button
                            >
                            </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { LOCATION_CREATE, LOCATION_PATCH } from "@/store/types/locations";
import { M_LOCATION_FORM_ADMIN } from "@/components/modals/types";
import { CLEAR_MODAL_DATA } from "@/store/types/global";
import { validationMixin } from "vuelidate";
import Select2 from "@/components/common/Select2";
import {
    ADMIN_LOCATION_PATCH,
    ADMIN_LOCATIONS_GET,
    ADMIN_LOCATION_CREATE
} from "@/store/types/admin";
import {
    required,
    minLength,
    numeric,
    maxLength,
} from "vuelidate/lib/validators";

export default {
    mixins: [validationMixin],
    data() {
        return {
            markerLabel: {
                fontFamily: "Flaticon",
                fontSize: "16px",
                text: "\uf100",
            },
            geocoder: null,
            autocomplete: null,
            addresses: [],
            type: M_LOCATION_FORM_ADMIN,
            coordinates: null,
            mapZoom: 17,
            errorStr: null,
            createMode: true,
            location: {},
            submitted: false,
            filter: "",
        };
    },
    validations: {
        location: {
            Name: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(30),
            },
            Address: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(70),
            },
            CompanyId: {
                required,
                numeric,
            },
        },
    },
    // props: ["companyId"],
    computed: {
        ...mapGetters([
            "getModalData",
            "getModalType",
            "getAllAdminCompanies",
            "getActiveCompany",
        ]),
        locationTemplate() {
            return {
                Name: "",
                Address: "",
                Lat: 56.361477,
                Lng: 18.488011,
                CompanyId: null,                
            };
        },
        getUserCompaniesOptions() {
            return [
                {
                    value: null,
                    text: this.$t("locations.company_select"),
                    disabled: true,
                },
                ...this.getAllAdminCompanies
                .filter((item) =>
                    item.Name
                    .toLowerCase()
                    .includes(this.filter.toLowerCase())
                )
                .sort((i1, i2) => {
                    return i1.Name.toUpperCase() > i2.Name.toUpperCase() ? 1 : -1;
                })
                .map((item) => {
                    return {
                        value: item.Id,
                        text: item.Name,
                    };
                }),
            ];
        },
        checkDisabled() {
            return !!this.getModalData.location.Location.CompanyId || !this.createMode;
        },
    },
    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.location[name];
            return $dirty && this.submitted ? !$error : null;
        },
        initCompany(company){
            this.location["CompanyId"] = company.Id;
        },
        postAdminLocation() {
            this.submitted = true;
            this.$v.location.$touch();
            if (this.$v.location.$anyError) {
                return;
            }

            this.$store
                .dispatch(ADMIN_LOCATION_CREATE, this.location)
                .then(() => {
                    this.$bvModal.hide(this.type);
                })
                .catch((error) => {});
        },
        editAdminLocation() {
            this.submitted = true;
            this.$v.location.$touch();
            if (this.$v.location.$anyError) {
                return;
            }

            this.$store
                .dispatch(ADMIN_LOCATION_PATCH, this.location)
                .then(() => {
                    this.$bvModal.hide(this.type);
                })
                .catch((error) => {});
        },
        setData() {
            if (this.getModalData !== null) {
                this.location = { ...this.getModalData.location };
                if(this.location.Name == "" && this.location.Address == "" && this.location.CompanyId !== null){
                    this.createMode = true;
                }else{
                    this.createMode = false;
                }
            } else {
                this.location = { ...this.locationTemplate };
            }
            // this.locateMe();

            this.$v.location.$reset();
        },
        onShow() {
            this.setData();
        },
        onClose() {
            this.$v.location.$reset();
            this.createMode = true;
            this.location = { ...this.locationTemplate };
            this.$store.commit(CLEAR_MODAL_DATA);
            this.coordinates = null;
        },
        async getLocation() {
            return new Promise((resolve, reject) => {
                if (!this.createMode && this.location.Lat) {
                    resolve({
                        lat: parseFloat(this.location.Lat),
                        lng: parseFloat(this.location.Lng),
                    });
                }

                if (!("geolocation" in navigator)) {
                    this.mapZoom = 3;
                    resolve({
                        lat: 56.361477,
                        lng: 18.488011,
                    });
                }

                navigator.geolocation.getCurrentPosition(
                    (pos) => {
                        resolve({
                            lat: pos.coords.latitude,
                            lng: pos.coords.longitude,
                        });
                    },
                    (err) => {
                        if (err.code == err.PERMISSION_DENIED) {
                            this.mapZoom = 3;
                            resolve({
                                lat: 56.361477,
                                lng: 18.488011,
                            });
                        }
                        reject(err);
                    }
                );
            });
        },
        async locateMe() {
            try {
                let coordinates = await this.getLocation();
                this.coordinates = coordinates;
                this.location.Lat = coordinates.lat;
                this.location.Lng = coordinates.lng;
                this.$gmapApiPromiseLazy().then(() => {
                    this.geocodeReverse(coordinates);
                    this.initAutocomplete();
                });
            } catch (e) {
                this.errorStr = e.message;
            }
        },
        geocodeReverse(latLng) {
            /*eslint-disable */
            this.geocoder = new google.maps.Geocoder();
            /*eslint-enable */
            this.geocoder.geocode(
                {
                    latLng: latLng,
                },
                (responses) => {
                    if (responses && responses.length > 0) {
                        this.location.Address = responses[0].formatted_address;
                    }
                }
            );
        },
        dragMarker(pos) {
            this.location.Lat = pos.latLng.lat();
            this.location.Lng = pos.latLng.lng();
            this.geocodeReverse(pos.latLng);
        },
        initAutocomplete() {
            /*eslint-disable */
            this.autocomplete = new google.maps.places.Autocomplete(
                this.$refs.acInput.$refs.input
            );
            this.autocomplete.setFields([
                "formatted_address",
                "geometry",
                "name",
            ]);
            let circle = new google.maps.Circle({
                center: this.coordinates,
                radius: 20000,
            });
            this.autocomplete.setBounds(circle.getBounds());
            this.autocomplete.addListener("place_changed", () => {
                this.mapZoom = 17;
                var place = this.autocomplete.getPlace();
                this.location.Address = place.formatted_address;
                this.location.Lat = place.geometry.location.lat();
                this.location.Lng = place.geometry.location.lng();
                this.coordinates = place.geometry.location;
            });
            /*eslint-enable */
        },
    },
    components: {
    },
};
</script>

<style>
.pac-container {
    z-index: 1100;
    border-top: 0px;
}
.select2-search__field {
    width: 100% !important;
    padding: 0.125rem 2.5rem !important;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35' height='16' opacity='0.5' class='bi bi-search' style='margin-left: 2px' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat left;
}
li.select2-search--inline {
    width: 100% !important;
}
</style>
